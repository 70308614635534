import type { Task } from "~skillBuilder/utils/types";
import { image, title } from "../../contentBlocks";

export const RSupport: Task = {
  name: "Support",
  uniqueName: "r-support",
  icon: "Lesson",
  durationTimeText: "1 min",
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      questions: [
        // Question 1.1
        {
          attribute: "reliability.support/intro",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
${title({ text: "Support", durationTimeText: "1 min" })}
<p>Know where you can go to get support from schools and Swing.</p>
<div style="text-align:center;max-width:350px;margin-top:24px;margin-left:auto;margin-right:auto;"/>
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1732047558/skillbuilderAssets/optimized/support-intro.jpg", alt: "A man and women both reaching out of laptops towards each other." })}
</div>
</div>
`,
          },
        },
      ],
    },
    // Step 2
    {
      nextButtonText: "Next Lesson",
      questions: [
        // Question 2.1
        {
          attribute: "reliability.support/contact-info",
          label: "If something comes up, make sure you communicate both with the school and Swing",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
**How to contact the school** <br>
You should be able to find the school's contact information on the request details page or the school profile.

**How to contact Swing** 
- [Online Help Center](https://swingeducation.com/help-center/)
- Live Chat is available from 7 am to 4 pm PST
- Email: <a href="mailto:support@swingeducation.com" rel="noreferrer" target="_blank">support@swingeducation.com</a>
- Text or Call Swing Support: (650) 413-9268
`,
          },
        },
      ],
    },
  ],
};

import { a as o, j as e } from "../../../../../emotion-react-jsx-runtime.browser.esm-CDWB6Ors.js";
import { IonButton as v } from "@ionic/react";
import { s as f, I as l } from "../../../../../Icon-C7c5yX_u.js";
import { Colors as g } from "../../../../foundations/Colors/Colors.js";
import { p as b, q as w, A as B } from "../../../../../AuthProvider-DparLqAm.js";
import a from "../../../../string-table.js";
import "react";
import "../../../../../react-paginate-CpOsCkIq.js";
import "@capacitor/core";
import { Section as W } from "../Section/Section.js";
import '../../../../../css/ContactInfo.css';const x = "_contactInfo_1hjbu_1", C = "_swingHeroWrapper_1hjbu_7", E = "_swingHeroBanner_1hjbu_17", j = "_profileImgWrapper_1hjbu_30", T = "_profileImg_1hjbu_30", P = "_editButtonWrapper_1hjbu_44", H = "_editButton_1hjbu_44", A = "_editIconWrapper_1hjbu_59", y = "_contactInfoContent_1hjbu_64", O = "_contactName_1hjbu_72", S = "_email_1hjbu_84", r = {
  contactInfo: x,
  swingHeroWrapper: C,
  swingHeroBanner: E,
  profileImgWrapper: j,
  profileImg: T,
  editButtonWrapper: P,
  editButton: H,
  editIconWrapper: A,
  contactInfoContent: y,
  contactName: O,
  "flex-row-center": "_flex-row-center_1hjbu_78",
  email: S
};
function V(I) {
  const {
    email: p,
    editable: n,
    name: i,
    phoneNumber: t,
    profileImageUrl: s,
    isPhoneNumberVerified: u = !1,
    badge: m,
    isSwingHero: c = !1
  } = I, d = t ? b({
    phoneNumber: t
  }) : "", h = t ? d.length > 10 ? t : w({
    phoneNumber: d
  }).value : void 0, _ = () => /* @__PURE__ */ o("div", {
    className: r.swingHeroBanner,
    children: [/* @__PURE__ */ e("span", {
      children: a("SUB_PROFILE_CONTACT_SWING")
    }), /* @__PURE__ */ e(l, {
      name: "Swing Hero"
    }), /* @__PURE__ */ e("span", {
      children: a("SUB_PROFILE_CONTACT_HERO")
    })]
  }), N = () => /* @__PURE__ */ o("div", {
    className: r.contactInfoContent,
    children: [i && /* @__PURE__ */ e("div", {
      className: r.contactName,
      children: /* @__PURE__ */ e("span", {
        children: i
      })
    }), t && /* @__PURE__ */ o("div", {
      className: r["flex-row-center"],
      children: [/* @__PURE__ */ e("div", {
        style: m ? {
          alignSelf: "flex-start"
        } : {},
        children: /* @__PURE__ */ e(l, {
          name: "Phone"
        })
      }), /* @__PURE__ */ o("div", {
        children: [/* @__PURE__ */ e("span", {
          style: {
            color: g.black
          },
          children: h
        }), !u && h && m && /* @__PURE__ */ e("div", {
          style: {
            marginTop: "4px"
          },
          children: m
        })]
      })]
    }), p && /* @__PURE__ */ o("div", {
      className: r["flex-row-center"],
      children: [/* @__PURE__ */ e(l, {
        name: "Mail"
      }), /* @__PURE__ */ e("span", {
        className: r.email,
        children: p
      })]
    })]
  });
  return /* @__PURE__ */ o(W, {
    title: "",
    onEditClick: n != null && n.isEditable ? n.onEditPhoneNumber : void 0,
    children: [/* @__PURE__ */ o("div", {
      className: r.contactInfo,
      style: {
        marginTop: n != null && n.isEditable ? "-24px" : 0
      },
      children: [/* @__PURE__ */ o("div", {
        style: {
          width: "var(--spacing-20xl)",
          position: "relative"
        },
        children: [s ? c ? /* @__PURE__ */ o("div", {
          className: r.swingHeroWrapper,
          children: [/* @__PURE__ */ e("div", {
            className: r.profileImgWrapper,
            children: /* @__PURE__ */ e("img", {
              src: s,
              alt: i,
              className: r.profileImg
            })
          }), /* @__PURE__ */ e(_, {})]
        }) : /* @__PURE__ */ e("div", {
          className: r.profileImgWrapper,
          children: /* @__PURE__ */ e("img", {
            src: s,
            alt: i,
            className: r.profileImg
          })
        }) : c ? /* @__PURE__ */ o("div", {
          className: r.swingHeroWrapper,
          children: [/* @__PURE__ */ e("div", {
            className: r.profileImgWrapper,
            children: /* @__PURE__ */ e("img", {
              src: f,
              alt: a("SUB_PROFILE_CONTACT_IMAGE_ALT"),
              style: {
                width: "var(--spacing-20xl)"
              }
            })
          }), /* @__PURE__ */ e(_, {})]
        }) : /* @__PURE__ */ e("img", {
          src: f,
          alt: a("SUB_PROFILE_CONTACT_IMAGE_ALT"),
          style: {
            width: "var(--spacing-20xl)"
          }
        }), (n == null ? void 0 : n.isEditable) && /* @__PURE__ */ e("div", {
          className: r.editButtonWrapper,
          style: c ? {
            bottom: "10px",
            right: "-14px"
          } : {},
          children: /* @__PURE__ */ e(v, {
            className: r.editButton,
            size: "small",
            shape: "round",
            onClick: n.onEditAvatar,
            children: /* @__PURE__ */ e("div", {
              className: r.editIconWrapper,
              children: /* @__PURE__ */ e(l, {
                name: "Edit",
                color: g.white200
              })
            })
          })
        })]
      }), (i || p || t) && /* @__PURE__ */ e(N, {})]
    }), !s && /* @__PURE__ */ e("div", {
      style: {
        marginTop: c ? "20px" : "8px"
      },
      children: /* @__PURE__ */ e(B, {
        color: "warning",
        children: a("SUB_PROFILE_NO_IMAGE_TEXT")
      })
    })]
  });
}
export {
  V as ContactInfo
};

// This file centralizes feature flags that can be toggled based on various conditions.
// import { BUILD_PROFILE } from "~utils";
import type { User } from "~components";

// Example: Toggle a feature off only in production
// NEW_FEATURE: BUILD_PROFILE !== "prod",

// Example: Toggle a feature based on user properties
// NEW_FEATURE: user?.isAlias,

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getFeatureFlags(user?: User) {
  return {
    ISOLATING_LEGACY_WEB_PAGES: true,
    FLEXIBLE_MD_FILL: false,
  };
}

import type { Task } from "~skillBuilder/utils/types";
import { image, title } from "../../contentBlocks";

export const CEClosing: Task = {
  name: "That’s a Wrap!",
  uniqueName: "ce-closing",
  icon: "Lesson",
  durationTimeText: "10 min",
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      questions: [
        // Question 1.1
        {
          attribute: "classroom-engagement.closing/overview",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "That's a Wrap!", durationTimeText: "10 min" })}
<p>Schools appreciate it when you take a moment at the end of the day to jot down your thoughts and reflections on the class. Noting successes, challenges, and any important observations will provide valuable insight for the teacher, ensuring a smooth transition when they return.<br><br>
This is a great way to wrap up the day while students are working independently, helping you leave a positive, organized impression.
</p>
<div align="center">
<div style="max-width:200px;width:100%;">
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1743476771/skillbuilderAssets/optimized/CE-closing.jpg", alt: "Illustration of woman sitting at a desk writing a note." })}
</div>
`,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "classroom-engagement.closing/class-overview",
          label: "Class overview",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Take a moment to share how the day went with the teacher. This is a great place to highlight:
- Parts of the lesson were particularly engaging for students
- Any challenges you faced, and how you managed them
Your insights will help the teacher see what worked and what might need adjusting.
`,
          },
        },
      ],
    },
    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "classroom-engagement.closing/behaviors",
          label: "Student behavior and engagement",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Provide feedback on student participation and behavior. 
- Which students were actively engaged, contributing positively, or excelling? 
- Note any students who needed redirection or reminders throughout the day. 
- If certain students had trouble with specific parts of the lesson, it’s helpful to mention this as well.
`,
          },
        },
      ],
    },
    // Step 4
    {
      questions: [
        // Question 4.1
        {
          attribute: "classroom-engagement.closing/lesson",
          label: "Lesson checklist",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Every day is unique! Teachers appreciate when you document the parts of the lesson you covered and which parts you didn’t get to. Sometimes teachers overplan for their absence, or you might have needed to use supplemental resources like subplan.ai to fill in. Make a note of any adjustments made to the original plan, so the teacher can pick up where you left off.
`,
          },
        },
      ],
    },
    // Step 5
    {
      nextButtonText: "Next Lesson",
      questions: [
        // Question 5.1
        {
          attribute: "classroom-engagement.closing/positive",
          label: "End on a positive note",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Take a moment to share a win from your day in the classroom. Whether it’s a breakthrough moment with a student, a successful activity, or even just a peaceful transition, acknowledging the positive moments will leave a lasting, encouraging impression!
`,
          },
        },
      ],
    },
  ],
};

import { j as t } from "../../../../../emotion-react-jsx-runtime.browser.esm-CDWB6Ors.js";
import "@ionic/react";
import "../../../../../AuthProvider-DparLqAm.js";
import n from "../../../../string-table.js";
import "react";
import "../../../../../react-paginate-CpOsCkIq.js";
import "@capacitor/core";
import { Section as m } from "../Section/Section.js";
import '../../../../../css/Education.css';const c = "_list_1k8vm_1", s = {
  "list-wrapper": "_list-wrapper_1k8vm_1",
  list: c
};
function N(l) {
  const {
    editable: i,
    education: r
  } = l;
  return /* @__PURE__ */ t(m, {
    onEditClick: i != null && i.isEditable ? i.onEditEducation : void 0,
    title: n("SUB_PROFILE_EDUCATION_HEADER"),
    children: /* @__PURE__ */ t("div", {
      children: r && r.length > 0 ? /* @__PURE__ */ t("ul", {
        className: s["list-wrapper"],
        children: r.map((o) => /* @__PURE__ */ t("li", {
          className: s.list,
          children: o.name
        }, o.id))
      }) : /* @__PURE__ */ t("span", {
        style: {
          fontStyle: "italic"
        },
        children: i != null && i.isEditable ? n("SUB_PROFILE_EDUCATION_EDIT_EMPTY") : n("SUB_PROFILE_NO_INFORMATION")
      })
    })
  });
}
export {
  N as Education
};

import { Icon } from "swing-components";

import { Card } from "~components";
import { msg, SchoolProfileData } from "~utils";
import styles from "../../SchoolProfile.module.css";
import { SchoolProfileTextField } from "../Helpers";
import { SubMarkdown } from "../SchoolProfileSummary";

type SchoolSitePoliciesProp = {
  subPolicies?: SchoolProfileData["subPolicies"];
  studentPolicies?: SchoolProfileData["studentPolicies"];
};

export function SchoolSitePolicies(props: SchoolSitePoliciesProp) {
  const { subPolicies, studentPolicies } = props;
  return (
    <Card hideBoxShadow>
      <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
        <h2 style={{ margin: "0" }}>{msg("SITE_POLICIES_HEADER")}</h2>
      </div>
      <SchoolProfileTextField
        label={msg("TAKING_ATTENDANCE_LABEL")}
        value={subPolicies?.takingAttendance}
      />
      <SchoolProfileTextField label={msg("ESCALATION_LABEL")} value={subPolicies?.escalation} />
      <div className={styles["divider-mobile"]}></div>
      <div>
        <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
          <Icon name="Teacher" />
          <span style={{ fontWeight: "var(--swing-font-semibold)" }}>
            {msg("SUBSTITUTES_HEADER")}
          </span>
        </div>
      </div>
      <SchoolProfileTextField
        label={msg("LEAVING_CLASSROOM_LABEL")}
        value={subPolicies?.leavingClassroom}
      />
      <SchoolProfileTextField
        label={msg("LUNCH_PROTOCOL_LABEL")}
        value={subPolicies?.lunchProtocol}
      />
      <SchoolProfileTextField label={msg("DRESS_CODE_LABEL")} value={subPolicies?.dressCode} />
      <SchoolProfileTextField label={msg("ELECTRONICS_LABEL")} value={subPolicies?.electronics} />
      <div className={styles["divider-mobile"]}></div>

      <div>
        <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
          <Icon name="Group" />
          <span style={{ fontWeight: "var(--swing-font-semibold)" }}>{msg("STUDENTS_HEADER")}</span>
        </div>
      </div>

      <SchoolProfileTextField
        label={msg("LEAVING_CLASSROOM_LABEL")}
        value={studentPolicies?.leavingClassroom}
      />
      <SchoolProfileTextField
        label={msg("LUNCH_PROTOCOL_LABEL")}
        value={studentPolicies?.lunchProtocol}
      />
      <SchoolProfileTextField label={msg("DRESS_CODE_LABEL")} value={studentPolicies?.dressCode} />
      <SchoolProfileTextField
        label={msg("ELECTRONICS_LABEL")}
        value={studentPolicies?.electronics}
      />
      <div className={styles["divider-mobile"]}></div>
      <div>
        <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
          <Icon name="Shield" />
          <span style={{ fontWeight: "var(--swing-font-semibold)" }}>
            {msg("EMERGENCY_PROCEDURES_HEADER")}
          </span>
        </div>
      </div>

      <SchoolProfileTextField
        label={msg("PROTOCOL_HEADER")}
        value={subPolicies?.emergencyProtocol}
      />
      <SchoolProfileTextField label={msg("CODE_WORDS_HEADER")} value={subPolicies?.codeWords} />
      <SubMarkdown label={msg("LOCKDOWN_HEADER")} value={subPolicies?.lockdownProtocol} />
    </Card>
  );
}

import styles from "./title.module.css";

type TitleParams = {
  text: string;
  durationTimeText?: string;
};

export function title(params: TitleParams) {
  const { text, durationTimeText } = params;
  return `<div class="${styles.container}">
    <p class="${styles.text}">${text}</p>
  ${
    durationTimeText
      ? `
  <div class="${styles.duration}">
    <span class="material-symbols-rounded ${styles.icon}">schedule</span>
    <span>${durationTimeText}</span>
  </div>`
      : ""
  }
  </div>`;
}

import type { Task } from "~skillBuilder/utils/types";
import { image, title } from "../../contentBlocks";

export const LWCSkillCheck: Task = {
  name: "Skill Check",
  uniqueName: "lwc-skill-check",
  icon: "BookmarkCheck",
  durationTimeText: "5 min",
  isQuiz: true,
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      prevButtonText: "Review",
      showPreviousButton: true,
      questions: [
        // Question 1.1
        {
          attribute: "lwc.skill-check/intro",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "Skill Check", durationTimeText: "5 min" })}
<p>Show what you've learned! Complete this quiz to earn a qualification for your profile.</p>
<div style="text-align:center;max-width:250px;width:100%;margin-top:24px;margin-left:auto;margin-right:auto;"/>
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/v1731113489/skillbuilderAssets/optimized/woman-and-clipboard.png", alt: "Illustrated woman standing in front of a checked clipboard." })}
</div>            
`,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "lwc.skill-check/accomodation",
          label:
            "Why is it important to ask if there are any specific student accommodations in class?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label: "All students learn the same way, so accommodations aren’t important.",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label:
                  "It helps meet individual students’ needs, often based on learning disabilities, medical conditions, or other challenges.",
                value: "valid",
                isCorrect: true,
              },
              {
                label: "Schools aren't allowed to share accommodation information.",
                value: "invalid-2",
                isCorrect: false,
              },
            ],
          },
        },
      ],
    },
    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "lwc.skill-check/activity",
          label: "What should you consider when selecting an introduction activity?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label: "Is the activity age appropriate?",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label: "Is the activity appropriate and professional?",
                value: "invalid-2",
                isCorrect: false,
              },
              {
                label: "Is there enough time to complete the activity?",
                value: "invalid-3",
                isCorrect: false,
              },
              {
                label: "All of the above",
                value: "valid",
                isCorrect: true,
              },
            ],
          },
        },
      ],
    },
    // Step 4
    {
      questions: [
        // Question 4.1
        {
          attribute: "lwc.skill-check/engage",
          label: "What is NOT one of the benefits of engaging with the class?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label: "Encourages student focus",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label: "Encourages students to cheat on assignments",
                value: "valid",
                isCorrect: true,
              },
              {
                label: "Prevents behavioral issues",
                value: "invalid-2",
                isCorrect: false,
              },
              {
                label: "Promotes a positive learning environment",
                value: "invalid-3",
                isCorrect: false,
              },
            ],
          },
        },
      ],
    },
    // Step 5
    {
      questions: [
        // Question 5.1
        {
          attribute: "lwc.skill-check/no-lesson-plan",
          label: "What can you do if a lesson plan is not provided?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label: "Use Swing’s last minute lesson plan generator to create one",
                value: "valid",
                isCorrect: true,
              },
              {
                label: "Give students free time",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label: "Show students your favorite movie",
                value: "invalid-2",
                isCorrect: false,
              },
            ],
          },
        },
      ],
    },
    // Step 6
    {
      questions: [
        // Question 6.1
        {
          attribute: "lwc.skill-check/last-impression",
          label: "What can you do at the end of the day to leave a good impression?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label: "Leave a detailed note for the teacher",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label: "Tidy up the classroom",
                value: "invalid-2",
                isCorrect: false,
              },
              {
                label: "Follow the school’s specific check-out procedure",
                value: "invalid-3",
                isCorrect: false,
              },
              {
                label: "All of the above",
                value: "valid",
                isCorrect: true,
              },
            ],
          },
        },
      ],
    },
    // Step 7
    {
      nextButtonText: "Great!",
      questions: [
        // Question 7.1
        {
          attribute: "lwc.skill-check/outro",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div style="text-align: center;">
  ${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1731608485/skillbuilderAssets/optimized/skill_builder_complete.jpg", alt: "illustrated man holding trophy standing next to a woman" })}
  <p><strong>You've completed this skill!</strong></p>
  <p>Thank you for your commitment to developing your teaching skills. You'll now see <strong>Lead with Confidence</strong> as a qualification on your profile.</p>
</div>
`,
          },
        },
      ],
    },
  ],
};

import type { Task } from "~skillBuilder/utils/types";
import { image, tip, title } from "../../contentBlocks";

export const RReliableTask: Task = {
  name: "Being Reliable",
  uniqueName: "r-reliable-task",
  icon: "Lesson",
  durationTimeText: "5 min",
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      questions: [
        // Question 1.1
        {
          attribute: "reliability.reliable/intro",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
${title({ text: "Being Reliable", durationTimeText: "5 min" })}
<p>Understand the basics of what it means to be a reliable substitute teacher.</p>
<div style="text-align:center;max-width:300px;margin-top:24px;margin-left:auto;margin-right:auto;"/>
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1732045507/skillbuilderAssets/optimized/reliable-intro.jpg", alt: "female teacher sitting on a rug reading a book to three children" })}
</div>
</div>
`,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "reliability.reliable/key",
          label: "The key to reliability",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Being reliable is simple: Once you commit to a school’s request, do everything you can to ensure you show up to that request, on time. Here we’ll go into more detail on all the traits of a reliable substitute teacher.</p>
</div>
`,
          },
        },
      ],
    },
    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "reliability.reliable/show-up",
          label: "Show up",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Signing up for a request and then not showing up leaves the school in a huge bind. Not only do they have a teacher out, but the substitute teacher  they were counting on to save the day didn’t show up either.</p>
  <ul style="display: flex; flex-direction: column; gap: 24px; margin: 0 0 0 16px; padding: 0;">
      ${tip({ text: "Add your commitments to your calendar or set a reminder in your phone so you never miss a request." })}
  </ul>
</div>
`,
          },
        },
      ],
    },
    // Step 4
    {
      questions: [
        // Question 4.1
        {
          attribute: "reliability.reliable/timeliness",
          label: "Arrive on time",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>The start time listed on the request should be treated as the time your job starts.</p>
  <ul style="display: flex; flex-direction: column; gap: 24px; margin: 0 0 0 16px; padding: 0;">
      ${tip({ text: "Show up about 10 minutes before the listed time to find time to park and sign in." })}
  </ul>
</div>
`,
          },
        },
      ],
    },
    // Step 5
    {
      questions: [
        // Question 5.1
        {
          attribute: "reliability.reliable/avoid-lmc",
          label: "Avoid last-minute cancellations",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Canceling less than 24 hours before the start of the request puts schools in a really challenging situation. It’s much more difficult for schools to find other coverage on such short notice. 

Last-minute cancellations will negatively impact your score and affect your Swing Hero status as well.</p>
  <ul style="display: flex; flex-direction: column; gap: 24px; margin: 0 0 0 16px; padding: 0;">
      ${tip({ text: "If you must cancel, do so at least 24 hours in advance." })}
  </ul>
</div>
`,
          },
        },
      ],
    },
    // Step 6
    {
      questions: [
        // Question 6.1
        {
          attribute: "reliability.reliable/md-requests",
          label: "Multi-day requests",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>If you accept a multi-day request, make sure that you can be there for every day of the request.</p>
  <ul style="display: flex; flex-direction: column; gap: 24px; margin: 0 0 0 16px; padding: 0;">
      ${tip({ text: "If you are interested in a request but there are a few days you cannot make, reach out to our Support Team to see if the school would be interested in adjusting the request for you. Make sure that you do this prior to accepting the request." })}
  </ul>
</div>
`,
          },
        },
      ],
    },
    // Step 7
    {
      questions: [
        // Question 7.1
        {
          attribute: "reliability.reliable/dont-leave-early",
          label: "Don’t leave early",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Unless it's been prearranged with the school, or you've been released from your request early, don't leave your request until the scheduled day is over. Leaving a request early puts the school in a tough position to find coverage for the remainder of the day.</p>
</div>
`,
          },
        },
      ],
    },
    // Step 8
    {
      nextButtonText: "Next Lesson",
      questions: [
        // Question 8.1
        {
          attribute: "reliability.reliable/communicate",
          label: "When in doubt, communicate with schools and Swing",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>When all else fails, plan to over-communicate with both the school and Swing. If you are running late or have a complication, reach out to the contact listed on the request page to let them know what's happening. It's better to deliver unfavorable news than no news at all.</p>
</div>
`,
          },
        },
      ],
    },
  ],
};

import { App as AppInfo } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { LocalNotifications } from "@capacitor/local-notifications";
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  RegistrationError,
  Token,
} from "@capacitor/push-notifications";
import { FullStory, isInitialized as isFullstoryInitialized } from "@fullstory/browser";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useRef } from "react";
import { useHistory } from "react-router";

import { useAuth } from "~components";
import { createRequestsUrl, createRequestUrl } from "~pages";
import { DeviceInfoPayload, NotificationsPayload, PUT } from "~utils";

type SwingNotificationData = {
  appVersion: string;
  deviceToken: string;
  deviceType: string;
  deviceId: string;
};

export function useRegisterPushNotifications() {
  const {
    userInfo: { isAlias },
    refreshUser,
  } = useAuth();
  const queryClient = useQueryClient();
  const swingPushNotificationData = useRef<SwingNotificationData>({
    appVersion: "",
    deviceId: "",
    deviceToken: "",
    deviceType: "",
  });

  const history = useHistory();

  const { mutate: updateNotificationsSettings } = useMutation<
    { data: NotificationsPayload },
    AxiosError,
    NotificationsPayload
  >({
    mutationFn: (payload: NotificationsPayload) => {
      return PUT("/api/sub/notifications", payload);
    },
    onSuccess: (updatedSettingsData) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      queryClient.setQueryData(["userData"], (oldUserData: any) => {
        return {
          ...oldUserData,
          notificationsSettings: updatedSettingsData.data.notificationsSettings,
        };
      });
      // Using refreshUser to trigger a refetch of user data to
      // keep the `userInfo` in AuthProvider's context updated.
      refreshUser();
    },
  });

  const { mutate: updateDeviceInfo } = useMutation<
    { data: DeviceInfoPayload },
    AxiosError,
    DeviceInfoPayload
  >({
    mutationFn: (payload: DeviceInfoPayload) => PUT("/api/sub/device-info", payload),
  });

  // Log device information
  useEffect(() => {
    /**
     * https://capacitorjs.com/docs/v4/apis/device
     */
    if (isAlias || Capacitor.getPlatform() === "web") return;

    const logDeviceInfo = async () => {
      const deviceInfo = await Device.getInfo();
      const deviceId = await Device.getId();
      if (swingPushNotificationData.current) {
        swingPushNotificationData.current.deviceId = deviceId.identifier;
        swingPushNotificationData.current.deviceType = `${deviceInfo.platform}: ${deviceInfo.manufacturer} ${deviceInfo.name} ${deviceInfo.model}`;
      }
    };

    logDeviceInfo();
  }, [isAlias]);

  // Log app information
  useEffect(() => {
    /**
     * https://capacitorjs.com/docs/v4/apis/app
     */
    if (isAlias || Capacitor.getPlatform() === "web") return;

    const logAppInfo = async () => {
      const appInfo = await AppInfo.getInfo();
      if (swingPushNotificationData.current) {
        swingPushNotificationData.current.appVersion = appInfo.version;
      }
    };

    logAppInfo();
  }, [isAlias]);

  // Push Notification EventListeners
  useEffect(() => {
    /**
     * https://capacitorjs.com/docs/v4/apis/push-notifications#example
     */
    if (isAlias || Capacitor.getPlatform() === "web") return;

    const addPushNotificationListeners = async () => {
      // on successful registration, the device will be given a token
      await PushNotifications.addListener("registration", (token: Token) => {
        if (swingPushNotificationData.current) {
          swingPushNotificationData.current.deviceToken = token.value;
          // Once the token is received, sync with swing
          updateDeviceInfo({
            model: swingPushNotificationData.current.deviceType,
            appVersion: swingPushNotificationData.current.appVersion,
            idf: swingPushNotificationData.current.deviceId,
            token: swingPushNotificationData.current.deviceToken,
          });
        }
      });

      // log error should registration fail (push will not work)
      await PushNotifications.addListener("registrationError", (error: RegistrationError) => {
        // eslint-disable-next-line no-console
        console.error(`Error on notification registration: ${error.error}`);
      });

      // notification payload will be available when the app is open on the device
      // i.e., app is in the foreground (user is using the app)
      await PushNotifications.addListener(
        "pushNotificationReceived",
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (notification: PushNotificationSchema) => {
          // console.log("Notification received", notification);
        },
      );

      // handle action when a user taps on a notification
      // i.e., app is in the background
      await PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification: ActionPerformed) => {
          // TODO: send event to API to signify interaction?
          // console.log("Notification interacted with", notification);

          if (notification.notification.data["sub-req"]) {
            const requestUrl = createRequestUrl(notification.notification.data["sub-req"]);
            history.push(requestUrl);
            if (isFullstoryInitialized()) {
              FullStory("trackEvent", {
                name: "Swing Subs Mobile Notification Opened",
                properties: {
                  subReqID: notification.notification.data["sub-req"],
                },
              });
            }
          } else {
            history.push(createRequestsUrl());
          }
        },
      );
    };

    addPushNotificationListeners();

    return () => {
      (async () => {
        await PushNotifications.removeAllListeners();
      })();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAlias]);

  useEffect(() => {
    if (isAlias || Capacitor.getPlatform() === "web") return;

    AppInfo.addListener("appStateChange", ({ isActive }) => {
      if (isActive) {
        PushNotifications.removeAllDeliveredNotifications();
      }
    });

    return () => {
      AppInfo.removeAllListeners();
    };
  }, [isAlias]);

  useEffect(() => {
    if (isAlias || Capacitor.getPlatform() === "web") return;

    const handlePushNotifications = async () => {
      try {
        /**
         * Note:
         * On Android 12 and below, the permission status is always granted because you can always receive push notifications.
         * Therefore, we need to use local notifications plugin, which correctly reflects the user's push notification
         * permission in their device settings for both Android and iOS
         * https://capacitorjs.com/docs/v4/apis/push-notifications#checkpermissions
         * https://capacitorjs.com/docs/v4/apis/local-notifications#checkpermissions
         *
         * check permissions -> request permissions -> register device
         */

        const permissionStatusLocal = await LocalNotifications.checkPermissions();

        if (
          // prompt: The end user should be prompted for permission, because it has neither been granted nor denied.
          permissionStatusLocal.display === "prompt" ||
          // prompt-with-rationale: The end user has denied permission before, but has not blocked the prompt yet.
          permissionStatusLocal.display === "prompt-with-rationale"
        ) {
          /**
           * On Android 12 and below, it doesn't prompt for permission because you can always receive push notifications.
           * https://capacitorjs.com/docs/v4/apis/push-notifications#requestpermissions
           */
          const permissionStatusRequest = await PushNotifications.requestPermissions();
          if (permissionStatusRequest.receive === "denied") return;
          // register the device with Apple / Google to receive push via APNS/FCM
          await PushNotifications.register();
          updateNotificationsSettings({ notificationsSettings: { push: true } });
        }
        return;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error registering for push notifications:", error);
      }
    };

    handlePushNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAlias]);
}

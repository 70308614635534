import { j as o, a as l } from "../../../emotion-react-jsx-runtime.browser.esm-CDWB6Ors.js";
import { IonItem as _ } from "@ionic/react";
import { I as m } from "../../../Icon-C7c5yX_u.js";
import "../../../AuthProvider-DparLqAm.js";
import "react";
import "../../../react-paginate-CpOsCkIq.js";
import "@capacitor/core";
import '../../../css/DataItemView.css';const g = "_icon_1oty5_55", y = "_label_1oty5_59", p = "_children_1oty5_71", a = {
  "data-item": "_data-item_1oty5_1",
  "data-grid-icon": "_data-grid-icon_1oty5_6",
  "data-grid-icon-stacked": "_data-grid-icon-stacked_1oty5_22",
  "data-grid-no-icon": "_data-grid-no-icon_1oty5_30",
  "data-grid-no-icon-stacked": "_data-grid-no-icon-stacked_1oty5_47",
  icon: g,
  label: y,
  children: p
};
function v(t) {
  const {
    icon: i,
    iconColor: d,
    label: c,
    lines: e,
    stackLayout: n,
    children: r,
    style: s
  } = t;
  return /* @__PURE__ */ o(_, {
    lines: e || "none",
    className: `${a["data-item"]} ion-no-padding`,
    style: {
      ...s
    },
    children: /* @__PURE__ */ l("div", {
      className: `${i ? a["data-grid-icon"] : a["data-grid-no-icon"]} ${n && i ? a["data-grid-icon-stacked"] : n && !i ? a["data-grid-no-icon-stacked"] : ""}`,
      children: [i && /* @__PURE__ */ o("div", {
        className: `${a.icon}`,
        children: /* @__PURE__ */ o(m, {
          name: i,
          color: d
        })
      }), /* @__PURE__ */ o("div", {
        className: a.label,
        style: {
          gridColumnStart: i ? 2 : void 0,
          gridColumn: n ? "span 2" : void 0
        },
        children: c
      }), /* @__PURE__ */ o("div", {
        className: a.children,
        children: r
      })]
    })
  });
}
export {
  v as DataItemView
};

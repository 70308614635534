import type { Task } from "~skillBuilder/utils/types";
import { image, link, tip, title } from "../../contentBlocks";

export const CmStartingStrong: Task = {
  name: "Starting Strong",
  uniqueName: "cm-starting-strong",
  icon: "Lesson",
  durationTimeText: "2 min",
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      questions: [
        // Question 1.1
        {
          attribute: "classroom-management.starting-strong/intro",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "Starting Strong", durationTimeText: "2 min" })}
<p>Learn how to set the tone for your day in the classroom.</p>
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/v1731113491/skillbuilderAssets/optimized/teacher-and-students-outside.png", alt: "children stood with teacher in front of school" })}
            `,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "classroom-management.starting-strong/start-day",
          label: "How to start the day",
          description:
            "To start the day, set your expectations, introduce yourself, and get students on task.",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <ul style="display: flex; flex-direction: column; gap: 24px; margin: 0 0 0 16px; padding: 0;">
    <li><strong>Get to know the classroom.</strong> Before students arrive, learn where key classroom materials are located.</li>
    <li><strong>Start the class on time and follow all tardy procedures.</strong></li>
    <li><strong>Introduce yourself.</strong> Let students know what you prefer to be called.</li>
      ${tip({ text: "Write your name someplace visible and share an interesting fact about yourself." })}
  </ul>
</div>
            `,
          },
        },
      ],
    },

    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "classroom-management.starting-strong/do-now",
          label: "Have students complete a Do Now",
          description: "",
          answerType: {
            value: "valid",
            type: "CONTENT",
            markdown: `
<div>
  <p>A Do Now is an activity students start working on as soon as they enter the room. Do Now directions should be easily visible to students and should involve accessing previous knowledge or experiences so all students can participate.</p>
  ${link({ text: "More about Do Nows", url: "http://teachlikeachampion.com/blog/now-primer/" })}
</div>
            `,
          },
        },
      ],
    },
    // Step 4
    {
      questions: [
        // Question 4.1
        {
          attribute: "classroom-management.starting-strong/expectations",
          label: "Set expectations",
          // description: "",
          answerType: {
            value: "valid",
            type: "CONTENT",
            markdown: `
<div>
  <p>Let students know what the day will look like and how you expect them to behave.</p>
  <ul style="display: flex; flex-direction: column; gap: 24px; list-style-type: none; margin: 0 0 0 16px; padding: 0;">
    <li>${tip({ text: "If the school or teacher uses any sort of reward system, use it! If you're unsure, ask the students if they are aware of one." })}</li>
    <li>${tip({ text: "Review class rules set by the teacher. These should be displayed in the classroom for students to see and review often. Add your own to the board if new ones apply to your teaching style." })}</li>
  </ul>
</div>
            `,
          },
        },
      ],
    },
    // Step 5
    {
      questions: [
        // Question 5.1
        {
          attribute: "classroom-management.starting-strong/classroom-jobs",
          label: "Utilize classroom jobs",
          // description: "",
          answerType: {
            value: "valid",
            type: "CONTENT",
            markdown: `
<div>
  <p>Use jobs created by the teacher, such as helpers to pass out papers and supplies, so you can observe and give directions.</p>
  <ul style="list-style-type: none; margin: 0 0 0 16px; padding: 0;">
    <li>${tip({ text: `If this job is not already implemented then feel free to create a "teacher helper"` })}</li>
  </ul>
  <p>${link({ text: "More about Classroom Jobs", url: "https://www.weareteachers.com/classroom-jobs/" })}</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 6
    {
      nextButtonText: "Next Lesson",
      questions: [
        // Question 6.1
        {
          attribute: "classroom-management.starting-strong/lead-by-example",
          label: "Lead by example",
          // description: "",
          answerType: {
            value: "valid",
            type: "CONTENT",
            markdown: `
<div>
  <p>If you expect students to be on task and to treat each other with respect, you should also uphold those expectations.</p>
  <p>This means you are not on your phone during class, even when students are completing independent work.</p>
</div>
            `,
          },
        },
      ],
    },
  ],
};

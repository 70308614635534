import type { Task } from "~skillBuilder/utils/types";
import { doDont, image, title } from "../../contentBlocks";

export const CmCommonPitfalls: Task = {
  name: "Common Pitfalls",
  uniqueName: "cm-common-pitfalls",
  icon: "Lesson",
  durationTimeText: "1 min",
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      questions: [
        // Question 1.1
        {
          attribute: "classroom-management.common-pitfalls/intro",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "Common Pitfalls", durationTimeText: "1 min" })}
<p>Understand common pitfalls and what to do instead.</p>
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/v1731113490/skillbuilderAssets/optimized/teacher-diversity.png", alt: "illustrated teacher and children" })}
            `,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "classroom-management.common-pitfalls/tone",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  ${doDont({ doText: "Do speak in a firm and kind tone.", dontText: "Don't yell or raise your voice at students." })}
  <p style="margin-top: 24px;">Use non-verbal cues and response techniques with students (think: quiet coyote, if you can hear me clap twice).</p>
</div>
            `,
          },
        },
      ],
    },

    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "classroom-management.common-pitfalls/remain-calm",
          label: "",
          // description: "",
          answerType: {
            value: "valid",
            type: "CONTENT",
            markdown: `
<div>
  ${doDont({ doText: "Do remain calm and level headed.", dontText: "Don't show frustration or anger." })}
  <p style="margin-top: 24px;">We know that sometimes this can be hard, but students tend to mirror a teacher's energy. Take a deep breath and repeat expectations.</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 4
    {
      questions: [
        // Question 4.1
        {
          attribute: "classroom-management.common-pitfalls/keep-word",
          label: "",
          // description: "",
          answerType: {
            value: "valid",
            type: "CONTENT",
            markdown: `
<div>
  ${doDont({ doText: "Do keep your word.", dontText: "Don't make empty promises." })}
  <p style="margin-top: 24px;">If a student has already been warned about a negative behavior, follow through with the appropriate consequence.</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 5
    {
      questions: [
        // Question 5.1
        {
          attribute: "classroom-management.common-pitfalls/respect-space",
          label: "",
          // description: "",
          answerType: {
            value: "valid",
            type: "CONTENT",
            markdown: `
<div>
  ${doDont({ doText: "Do respect students' personal space.", dontText: "Don't make physical contact with a student or their belongings." })}
  <p style="margin-top: 24px;">Ask for help from a school leader if necessary.</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 6
    {
      nextButtonText: "Next Lesson",
      questions: [
        // Question 6.1
        {
          attribute: "classroom-management.common-pitfalls/class-punishment",
          label: "",
          // description: "",
          answerType: {
            value: "valid",
            type: "CONTENT",
            markdown: `
<div>
  ${doDont({ doText: "Do reward the whole class for individual or group improvements.", dontText: "Don't punish the entire class for the actions of one or a few students." })}
  <p style="margin-top: 24px;">Focusing on the positive and using peer influence can go a long way.</p>
</div>
            `,
          },
        },
      ],
    },
  ],
};

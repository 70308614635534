import type { Task } from "~skillBuilder/utils/types";
import { image, tip, title } from "../../contentBlocks";

export const LWCFirstImpression: Task = {
  name: "First Impression",
  uniqueName: "lwc-first-impression",
  icon: "Lesson",
  durationTimeText: "2 min",
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      questions: [
        // Question 1.1
        {
          attribute: "lwc.first-impression/intro",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "First Impression", durationTimeText: "2 min" })}
<p>Learn how to make a good first impression and why it matters.</p>
<div align="center">
<div style="max-width:250px;width:100%;">
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1734020526/skillbuilderAssets/optimized/first-impression_intro.jpg", alt: "A substitute teacher and school administrator shaking hands." })}
</div>
</div>
            `,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "lwc.first-impression/before-school",
          label: "Before entering the school",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>To start your day off successfully, there are a few key things to prepare for. Traveling to campus and professional attire are great ways to make a good first impression. Check the route and traffic conditions to ensure you plan enough time for travel and parking.</p>
        ${tip({ text: "For your attire, select clothing that is comfortable and professional. That way you'll be ready for anything the day may bring!" })}
</div>
            `,
          },
        },
      ],
    },
    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "lwc.first-impression/front-office",
          label: "At the front office",
          //description:"",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Upon arrival, check in at the front office and introduce yourself. Maintain a positive and professional demeanor, ask any clarifying questions about the role you’re filling, and be patient.</p>
        ${tip({ text: "Each school may have different check-in procedures, so being friendly and adaptable will go a long way in building strong partnerships with the front office staff." })}
</div>
            `,
          },
        },
      ],
    },
    // Step 4
    {
      nextButtonText: "Next Lesson",
      questions: [
        // Question 4.1
        {
          attribute: "lwc.first-impression/classroom",
          label: "At the front office",
          //description:"",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Introduce yourself to the neighboring teacher, review the lesson plans, and prepare yourself before students arrive. Following all directions and school policies is essential.<br><br>
Introducing yourself and reviewing the lesson plan helps you understand the day's expectations and flow while building connections with team members who can provide immediate support if needed.</p>
</div>
            `,
          },
        },
      ],
    },
  ],
};

import { Colors } from "swing-components";
import { generateSwingSupportURL } from "swing-utils";

import { ContentNavigationItem } from "~components";
import { msg, openLinkInApp } from "~utils";

type MorePageBaseProps = {
  userName: string;
  userProfileImageUrl?: string;
  onSignOutClick: () => void;
};

export function MorePageBase(props: MorePageBaseProps) {
  const { userName, onSignOutClick } = props;

  return (
    <div style={{ flexGrow: 1 }}>
      <header
        style={{
          padding: "24px 16px",
          alignSelf: "stretch",
          borderRadius: "8px 8px 0px 0px",
          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.15)",
          marginBottom: "2px",
          background: Colors.white200,
        }}
      >
        <p style={{ fontSize: "20px", fontWeight: "600" }}>More</p>
      </header>
      <ContentNavigationItem icon={{ name: "Person" }} label={userName} />
      <ContentNavigationItem
        icon={{ name: "Help" }}
        label="Help Center"
        navigationIcon="External Link"
        onClick={() => {
          openLinkInApp(
            `${msg("SWING_SUPPORT_ZENDESK_PROXY_URL")}${encodeURIComponent(generateSwingSupportURL("/hc/en-us/categories/36997247319955-Substitute-FAQs"))}`,
          );
        }}
      />
      <ContentNavigationItem
        icon={{ name: "Logout", color: Colors.red500 }}
        label="Sign Out"
        onClick={onSignOutClick}
      />
    </div>
  );
}

import { lightBulbSVG } from "swing-assets";

import styles from "./tip.module.css";

type TipParams = {
  text: string;
};

export function tip(params: TipParams) {
  const { text } = params;
  return `<div class="${styles.container}">
    <img class="${styles.icon}" src="${lightBulbSVG}" alt="light bulb" />
    <p class="${styles.text}"><span class="${styles.textTip}">Tip: </span>${text}</p>
  </div>`;
}

import styles from "./image.module.css";

type ImageParams = {
  alt: string;
  url: string;
};

export function image(params: ImageParams) {
  const { alt, url } = params;
  return `<img class="${styles.img}" src="${url}" alt="${alt}" />`;
}

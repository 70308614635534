import { useHistory } from "react-router";
import { Badge, Colors, IconBlockGroup } from "swing-components";

import { ContentNavigationItem, ContentSingleColumn, useAuth } from "~components";
import {
  createHelpUrl,
  createLocationSettingsUrl,
  createMyDocumentsUrl,
  createNotificationsUrl,
  createPaymentUrl,
  createReferralProgramUrl,
  createSkillBuilderTasksURL,
} from "~pages";
import { BUILD_SHA, BUILD_VER, msg, openLinkInApp } from "~utils";
import { ScorePage } from "../../ScoreTemplates/ScorePage";
import styles from "./More.module.css";

export function More() {
  const { signOut, userInfo } = useAuth();
  const history = useHistory();

  return (
    <ScorePage title={msg("PAGE_TITLE_MORE")}>
      <ContentSingleColumn>
        <div className={styles["content-wrapper"]}>
          <div style={{ marginBottom: "24px" }}>
            <IconBlockGroup title="Settings">
              {/* Notifications */}
              <ContentNavigationItem
                navigationUrl={createNotificationsUrl()}
                icon={{ name: "Notifications" }}
                label={msg("MORE_NOTIFICATIONS")}
                navigationIcon="Chevron Right"
                hasWhiteBackground={false}
              />
              {!userInfo.isHourly &&
                !userInfo.restrictions?.includes("RESTRICTION_CANNOT_VIEW_PAY") && (
                  <ContentNavigationItem
                    navigationUrl={createPaymentUrl()}
                    icon={{ name: "Monetization" }}
                    label={msg("PAGE_TITLE_PAYMENT")}
                    navigationIcon="Chevron Right"
                    hasWhiteBackground={false}
                  />
                )}
              {/* My Documents */}
              {!userInfo.restrictions?.includes("RESTRICTION_CANNOT_VIEW_DOCUMENTS") && (
                <ContentNavigationItem
                  icon={{ name: "Note" }}
                  label={msg("SETTINGS_MY_DOCUMENTS")}
                  navigationIcon="Chevron Right"
                  navigationUrl={createMyDocumentsUrl()}
                  hasWhiteBackground={false}
                />
              )}
              {/* Locations Settings */}
              {!userInfo.restrictions?.includes("RESTRICTION_CANNOT_VIEW_LOCATIONS") && (
                <ContentNavigationItem
                  navigationUrl={createLocationSettingsUrl()}
                  icon={{ name: "Location" }}
                  label={msg("MORE_LOCATION_SETTINGS")}
                  hasDivider={false}
                  navigationIcon="Chevron Right"
                  hasWhiteBackground={false}
                />
              )}
            </IconBlockGroup>
          </div>
          <div style={{ marginBottom: "24px" }}>
            <IconBlockGroup title="Resources">
              {userInfo.isHourly &&
                !userInfo.restrictions?.includes("RESTRICTION_CANNOT_VIEW_PAY") && (
                  <ContentNavigationItem
                    onClick={() => {
                      openLinkInApp("https://swingeduc.ukg.net/");
                    }}
                    icon={{ name: "UKG" }}
                    label={msg("UKG_LINK_LABEL")}
                    navigationIcon="External Link"
                    hasWhiteBackground={false}
                  />
                )}

              {/* Skill Builder Program */}
              <ContentNavigationItem
                navigationUrl={createSkillBuilderTasksURL("swing")}
                icon={{ name: "Lesson" }}
                label="Skill Builder"
                navigationIcon="Chevron Right"
                hasWhiteBackground={false}
                badge={<Badge status="warning" text={`${msg("LABEL_NEW")}!`} />}
              />

              {/* SubPlan.ai*/}
              <ContentNavigationItem
                icon={{ name: "Assignment" }}
                label={msg("MORE_SUB_PLAN_AI")}
                navigationIcon="External Link"
                hasWhiteBackground={false}
                onClick={() => openLinkInApp(msg("MORE_SUB_PLAN_AI_LINK_OUT_URL"))}
                badge={<Badge status="warning" text={`${msg("LABEL_NEW")}!`} />}
              />

              {/* Referral Program */}
              {!userInfo.restrictions?.includes("RESTRICTION_CANNOT_VIEW_REFERRAL") && (
                <div className={styles["hide-on-desktop"]}>
                  <ContentNavigationItem
                    navigationUrl={createReferralProgramUrl()}
                    icon={{ name: "Group" }}
                    label={msg("MORE_REFERRAL_PROGRAM")}
                    navigationIcon="Chevron Right"
                    hasWhiteBackground={false}
                  />
                </div>
              )}

              {/* Help */}
              <div className={styles["hide-on-desktop"]}>
                <ContentNavigationItem
                  navigationUrl={createHelpUrl()}
                  icon={{ name: "Help" }}
                  label={msg("MORE_HELP")}
                  navigationIcon="Chevron Right"
                  hasWhiteBackground={false}
                />
              </div>
            </IconBlockGroup>
          </div>
          {/* Conditionally hide Help and Log Out on desktop */}
          <div className={styles["hide-on-desktop"]}>
            <div style={{ marginBottom: "24px" }}>
              <IconBlockGroup>
                <ContentNavigationItem
                  icon={{ name: "Logout", color: Colors.red500 }}
                  label={msg("MORE_LOGOUT")}
                  onClick={() => signOut({ history })}
                  hasWhiteBackground={false}
                />
              </IconBlockGroup>
            </div>
          </div>
          <div style={{ fontSize: 12, color: Colors.slate400 }}>
            <span>Version {BUILD_VER}</span>
            <span> • </span>
            <span>Build {BUILD_SHA}</span>
          </div>
        </div>
      </ContentSingleColumn>
    </ScorePage>
  );
}

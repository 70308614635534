import type { Task } from "~skillBuilder/utils/types";

export const ROverview: Task = {
  name: "Overview",
  uniqueName: "r-overview",
  icon: "Overview",
  durationTimeText: "1 min",
  isIntro: true,
  steps: [
    // Step 1
    {
      nextButtonText: "Start Lesson",
      questions: [
        // Question 1.1
        {
          attribute: "reliability/overview",
          label: "Together, we are working to ensure no classroom goes a day without a teacher",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Schools come to us, you and Swing, in their time of need and rely on us to save the day when a teacher is absent. When we commit to a school that we’ll be there to help, it's of the utmost importance that we follow through on that commitment. 

We know life happens, so this lesson will help you improve your planning skills and become one of the most reliable substitute teachers.
`,
          },
        },
      ],
    },
  ],
};

import type { Task } from "~skillBuilder/utils/types";
import { image, tip, title } from "../../contentBlocks";

export const LWCLastImpression: Task = {
  name: "Last Impression",
  uniqueName: "lwc-last-impression",
  icon: "Lesson",
  durationTimeText: "10 min",
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      questions: [
        // Question 1.1
        {
          attribute: "lwc.last-impression/intro",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "Last Impression", durationTimeText: "10 min" })}
<p>Review some key actions that will help you leave a lasting positive impression.</p>
<div align="center">
<div style="max-width:300px;width:100%;">
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1734093808/skillbuilderAssets/optimized/last-impression_intro.jpg", alt: "A teacher standing in front of a chalkboard and computer." })}
</div>
</div>
            `,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "lwc.last-impression/three-things",
          label: "Leave a positive lasting impression by doing these three things",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>1. Leave a detailed note for the teacher<br>
  2. Tidy up the classroom<br>
  3. Follow the school’s specific check-out procedure</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "lwc.last-impression/divider-note",
          answerPlacement: "TOP",
          label:
            "First, let’s review the benefits of leaving a detailed classroom note at the end of the day",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div align="center">
<div style="max-width:250px;width:100%;">
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1734093807/skillbuilderAssets/optimized/last-impression_divider-note.jpg", alt: "A teacher sitting at a desk writing a detailed note." })}
</div>
</div>
            `,
          },
        },
      ],
    },
    // Step 4
    {
      questions: [
        // Question 4.1
        {
          attribute: "lwc.last-impression/note-1",
          label: "Provides continuity",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>A detailed note ensures that the regular teacher is informed of what was accomplished during the day and any issues that may have arisen. This helps maintain continuity in the learning process, so the teacher can pick up where you left off without confusion.<br><br>
<strong>Supports student progress</strong><br><br>By documenting how students performed on assignments or activities, you provide valuable insight into which students may need additional support or which concepts might require further review.</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 5
    {
      questions: [
        // Question 5.1
        {
          attribute: "lwc.last-impression/note-2",
          label: "Builds trust and professionalism",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Leaving a thorough note demonstrates professionalism and a commitment to the class. It shows that you took the job seriously and left helpful information for the teacher to use when they return.<br><br>
<strong>Assists in planning</strong><br><br>If the regular teacher has specific instructions or follow-up tasks, leaving a detailed note ensures that these are followed, helping with smoother transitions and making the teacher's job easier when they return.</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 6
    {
      questions: [
        // Question 6.1
        {
          attribute: "lwc.last-impression/note-3",
          label: "Improves substitute experience",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Teachers will be more likely to request you again if they know you provide thorough, helpful feedback. It shows you care about the students and the classroom, making you a more reliable and effective substitute.<br><br>
<strong>Helps with classroom management</strong><br><br>If there were any behavior issues or disruptions, a detailed note provides the regular teacher with the context needed to address those situations appropriately. It can also highlight students who may need special attention or praise.</p>
          ${tip({ text: "You can let students know you are taking of positive things you see happening in the class throughout the day to share with their teacher." })}
</div>
            `,
          },
        },
      ],
    },
    // Step 7
    {
      questions: [
        // Question 7.1
        {
          attribute: "lwc.last-impression/divider-clean",
          answerPlacement: "TOP",
          label:
            "Next, let’s cover why tidying up keeps the classroom functional and leads to a smooth transition",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div align="center">
<div style="max-width:300px;width:100%;">
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1734107999/skillbuilderAssets/optimized/last-impression_divider-clean.jpg", alt: "A teacher cleaning up a desk." })}
</div>
</div>
            `,
          },
        },
      ],
    },
    // Step 8
    {
      questions: [
        // Question 8.1
        {
          attribute: "lwc.last-impression/clean-1",
          label: "Respects the teacher’s space",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Cleaning up shows respect for the regular teacher’s classroom and materials. It demonstrates professionalism and consideration, as you are helping maintain a positive and organized environment for the teacher’s return.<br><br>
<strong>Creates a positive environment</strong><br><br>A tidy classroom contributes to a positive and welcoming atmosphere. When students see the classroom in order, it reinforces a sense of responsibility and helps set the tone for future lessons.</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 9
    {
      questions: [
        // Question 9.1
        {
          attribute: "lwc.last-impression/clean-2",
          label: "Prepares for the next class",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>A clean and organized space allows the next teacher or class to start with a fresh, orderly environment, making transitions smoother and more efficient.<br><br>
<strong>Promotes classroom management</strong><br><br>A tidy room can help with classroom management by reducing distractions. It also provides a clear structure for students to focus on their work without clutter or confusion.</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 10
    {
      questions: [
        // Question 10.1
        {
          attribute: "lwc.last-impression/clean-3",
          label: "Leaves a good impression",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Tidying up after the day shows that you are thoughtful, responsible, and committed to your role. It leaves a positive impression with the students, the teacher, and the school staff, enhancing your reputation as a substitute.<br><br>
<strong>Encourages student responsibility</strong><br><br>When you leave the room in order, you model good organizational habits for students. It can inspire them to keep their space tidy and take responsibility for their own belongings.</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 11
    {
      questions: [
        // Question 11.1
        {
          attribute: "lwc.last-impression/divider-check-out",
          answerPlacement: "TOP",
          label:
            "Finally, let’s review the benefits of following the school’s specific check-out procedures at the end of the day",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div align="center">
<div style="max-width:300px;width:100%;">
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1734108000/skillbuilderAssets/optimized/last-impression_divider-check-out.jpg", alt: "A teacher checking out with a school administrator in a school front office." })}
</div>
</div>
            `,
          },
        },
      ],
    },
    // Step 12
    {
      questions: [
        // Question 12.1
        {
          attribute: "lwc.last-impression/check-out-1",
          label: "Proper documentation",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Checking out ensures the school accurately records the completion of your request and confirms your official departure. This helps maintain accurate attendance and ensures proper compensation for your work.<br><br>
<strong>Safety and accountability</strong><br><br>The front office tracks who is on campus for safety purposes. By checking out, you confirm your departure, contributing to a secure environment for both students and staff.</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 13
    {
      questions: [
        // Question 13.1
        {
          attribute: "lwc.last-impression/check-out-2",
          label: "Opportunity for feedback",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Checking out gives the school a chance to provide feedback or ask any follow-up questions. It also allows you to share any observations or issues that could assist the regular teacher.<br><br>
<strong>Professionalism</strong><br><br>Checking out with the front office is a sign of professionalism and shows that you are respectful of the school’s procedures and committed to your role.</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 14
    {
      nextButtonText: "Next Lesson",
      questions: [
        // Question 14.1
        {
          attribute: "lwc.last-impression/check-out-3",
          label: "Future requests",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Leaving a positive impression when you check out increases your chances of being requested for future substitute teaching opportunities. Schools are more likely to choose substitutes who follow protocols and exhibit professionalism.</p>
</div>
            `,
          },
        },
      ],
    },
  ],
};

import type { Task } from "~skillBuilder/utils/types";
import { image, tip, title } from "../../contentBlocks";

export const SECFlexibility: Task = {
  name: "Be Flexible and Ready to Adapt",
  uniqueName: "sec-flexibility",
  icon: "Lesson",
  durationTimeText: "5 min",
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      questions: [
        // Question 1.1
        {
          attribute: "special-education-classrooms.flexibility/overview",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "Be Flexible and Ready to Adapt", durationTimeText: "5 min" })}
<p>Learn how to create positive classroom environment to benefit both you and students.</p>
<div align="center">
<div style="max-width:250px;width:100%;">
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1740759767/skillbuilderAssets/optimized/flexible.jpg", alt: "Illustration of teacher standing with six students." })}
</div>
`,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "special-education-classrooms.flexibility/adjust",
          label: "Adjusting on the fly",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Each student has unique needs and behaviors. Flexibility is key—go with the flow while maintaining as much structure as possible.
`,
          },
        },
      ],
    },
    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "special-education-classrooms.flexibility/routine",
          label: "The power of routine",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Students in special education settings thrive on structure and routine. Stick closely to the provided schedule and follow the lesson plan and direction as closely as possible to how the classroom is typically run. While interruptions may happen, maintaining consistency helps both you and the students succeed.
`,
          },
        },
      ],
    },
    // Step 4
    {
      nextButtonText: "Next Lesson",
      questions: [
        // Question 4.1
        {
          attribute: "special-education-classrooms.flexibility/ask",
          label: "Unsure about the schedule? The task?",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
If you're unsure about a procedure or task, don’t hesitate to ask for support! Reach out to a school administrator or your day-of contact with questions.  ${tip({ text: "Don’t wait until the last minute—if something in the schedule or a student’s routine leaves you scratching your head, reach out for guidance early!" })}

  `,
          },
        },
      ],
    },
  ],
};

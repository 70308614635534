import type { Task } from "~skillBuilder/utils/types";
import { doDont, image, tip, title } from "../../contentBlocks";

export const SECChallenges: Task = {
  name: "Navigating Challenges with Confidence",
  uniqueName: "sec-challenges",
  icon: "Lesson",
  durationTimeText: "15 min",
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      questions: [
        // Question 1.1
        {
          attribute: "special-education-classrooms.challenges/overview",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "Navigating Challenges with Confidence ", durationTimeText: "15 min" })}
<p>Special education roles are an impactful and rewarding experience filled with exciting and unique challenges for you and the students.</p>
<div align="center">
<div style="max-width:250px;width:100%;">
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1740759766/skillbuilderAssets/optimized/challenges.jpg", alt: "Illustration of teacher standing confidently in front of a chalkboard." })}
</div>
`,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "special-education-classrooms.challenges/safety",
          label: "Safety first",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
While in a special education classroom, you’ll encounter big emotions daily. Your first priority is to create a safe and supportive environment where students can regulate and de-escalate as needed. Ensuring the well-being of all students is your key responsibility.
${tip({ text: "Providing a student with space while maintaining their safety can help them calm down more quickly and effectively." })}
`,
          },
        },
      ],
    },
    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "special-education-classrooms.challenges/do-dont",
          label: "Giving students space while keeping them safe",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
When big emotions arise, giving a student space doesn’t mean ignoring the behavior—it means adjusting the environment to support de-escalation. Your response can make all the difference in helping them regain control.

- Remove other students from the immediate area if needed. Redirect them to another part of the room where they can engage in activities like reading or using sensory tools.
- Utilize soft pillows or mats if a student is throwing themselves to the ground. Placing them underneath the student can help prevent injury. Make sure you do this in a professional and safe manner without touching the student.
- Remove any objects from the area that could be thrown or used in a way that may cause harm.

By giving students space while ensuring safety for everyone in the classroom, you create an environment that supports emotional regulation and well-being.
`,
          },
        },
      ],
    },
    // Step 4
    {
      questions: [
        // Question 4.1
        {
          attribute: "special-education-classrooms.challenges/accommodations",
          label: "What to do when accommodations or modifications are not disclosed?",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
In some cases, you may not have detailed information about a student’s accommodations or modifications. However, you can still implement effective teaching practices by staying observant and engaged.

- **Observe** how the student interacts with their environment and peers.
- **Stay engaged** to recognize behaviors that might indicate specific needs.- Remove any objects from the area that could be thrown or used in a way that may cause harm.
- **Be flexible** and adjust your approach as you learn more about what works for the student.

Your attentiveness and adaptability will help ensure each student receives the support they need, even without specific details upfront.
`,
          },
        },
      ],
    },
    // Step 5
    {
      questions: [
        // Question 5.1
        {
          attribute: "special-education-classrooms.challenges/elopement",
          label: "What do I do if a student leaves the classroom without permission?",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Positioning is key to preventing elopement. Keep yourself between the student and the door while they are working. If they get up to retrieve an item or move stations, adjust your position so you can reach the door first if necessary.

Your top priority is **student safety**. Follow all transition guidelines and communicate with the appropriate school team to ensure proper support and minimize opportunities for elopement.
${tip({ text: "If a student runs, avoid drawing attention by yelling. Instead, calmly follow them, guide them back, and help them transition smoothly back into the activity. This approach reduces escalation and maintains a positive atmosphere." })}

`,
          },
        },
      ],
    },
    // Step 6
    {
      nextButtonText: "Next Lesson",
      questions: [
        // Question 6.1
        {
          attribute: "special-education-classrooms.challenges/toileting",
          label: "Toileting, diapers, and restroom guidelines",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Some special education requests may require assistance with restroom duties. If this is new to you, preparation is key.
  ${doDont({ doText: "Do ensure you understand the school’s diapering and restroom policies. If you're unsure, ask questions and follow school procedures closely.", dontText: "Don’t allow students to stand on changing tables, as this poses a safety risk." })}<br>

  ${doDont({ doText: "Do ask questions from school staff if you are unsure.", dontText: "Don’t enter a restroom with a student unless it is explicitly part of their required care." })}<br>
Following these guidelines ensures student dignity, safety, and compliance with school policies.
`,
          },
        },
      ],
    },
  ],
};

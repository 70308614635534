import type { Task } from "~skillBuilder/utils/types";
import { doDont, image, tip, title } from "../../contentBlocks";

export const CEAttention: Task = {
  name: "Why Your Attention Matters",
  uniqueName: "ce-attention",
  icon: "Lesson",
  durationTimeText: "10 min",
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      questions: [
        // Question 1.1
        {
          attribute: "classroom-engagement.attention/overview",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "Why Your Attention Matters", durationTimeText: "10 min" })}
<p>Engagement isn’t just for the students; it’s for you as well. When you actively participate in the class and lesson, you’ll notice many students will follow your lead and become more eager to get involved.</p>
<div align="center">
<div style="max-width:250px;width:100%;">
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1743476770/skillbuilderAssets/optimized/CE-attention.jpg", alt: "Illustration of three teachers standing in front of a chalkboard smiling." })}
</div>
`,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "classroom-engagement.attention/participation",
          label: "Encouraging student participation",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Your attention and support are key to encouraging active student participation. By taking this approach, you’ll find that students need less redirection, helping the day run more smoothly.

The goal of active involvement is to foster meaningful interactions with students, creating an environment where you’re more than just a supervisor; you’re an active participant in their learning journey. 

Here’s a few tips that our school partners and other educators recommend for how to keep the class engaged. 
`,
          },
        },
      ],
    },
    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "classroom-engagement.attention/attentiveness",
          label: "Your attentiveness matters",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${doDont({ doText: "Do: Stay fully engaged with the class and the lesson. Walk through the material with the students and learn alongside them. Your success will reflect the effort you put into the day.", dontText: "Don’t: Be on your phone, reading a book, or listening to music with headphones. Students will model your behavior, so stay present and attentive." })}
`,
          },
        },
      ],
    },
    // Step 4
    {
      questions: [
        // Question 4.1
        {
          attribute: "classroom-engagement.attention/engaged",
          label: "Stay engaged",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${doDont({ doText: "Do: Circulate the classroom while students work independently or continuously scan the room to show you are engaged with everything that’s happening. When teaching the whole class, move around or scan the class to facilitate engagement and learning.", dontText: "Don’t: Stay in one place for too long, or only focus your attention on one part of the room. It’s harder to encourage engagement if students see you as disengaged." })}
`,
          },
        },
      ],
    },
    // Step 5
    {
      questions: [
        // Question 5.1
        {
          attribute: "classroom-engagement.attention/focus",
          label: "Monitoring focus",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${doDont({ doText: "Do: Keep track of students who are on-task and off-task. Acknowledge and positively reinforce those who stay engaged.", dontText: "Don’t: Single out students who are off-task. Focus on reinforcing positive behavior." })}
`,
          },
        },
      ],
    },
    // Step 6
    {
      questions: [
        // Question 6.1
        {
          attribute: "classroom-engagement.attention/facilitate",
          label: "Facilitate engagement",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${doDont({ doText: "Do: Encourage students to ask questions, and always offer positive reinforcement when they do.", dontText: "Don't: Dismiss or ridicule students for asking questions. Every question is an opportunity for learning." })}
`,
          },
        },
      ],
    },
    // Step 7
    {
      questions: [
        // Question 7.1
        {
          attribute: "classroom-engagement.attention/attention-getters",
          label: "Attention-getters",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
When you need to get the class’s attention, it’s essential to do so in a way that promotes mutual respect.

${doDont({ doText: "Do: Use the call-and-response technique (more on this later) or lower your voice to a calm whisper to encourage students to listen closely.", dontText: "Don’t: Yell, or try to get students' attention by touching, grabbing, pushing, or throwing things. Always maintain respect and composure." })}
`,
          },
        },
      ],
    },
    // Step 8
    {
      questions: [
        // Question 8.1
        {
          attribute: "classroom-engagement.attention/conversations",
          label: "Keep conversations relevant",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Students may be curious when there’s a new face in the room, but it’s important to stay focused on the lesson. Keep the attention on the students and the material, rather than on personal matters, to maintain professionalism.

${tip({ text: "If a student asks you a personal question, gently redirect them back to the lesson with phrases like:" })}
<div>
      <ul style="display: flex; flex-direction: column; gap: 4px; margin: 0 0 0 32px; padding: 0 0 0 32px;">
        <li> 💬 “That’s an interesting question! Let’s save that for later and focus on the lesson for now.”</li>
        <li> 💬 “I’d love to chat more about that, but let’s make sure we get through today’s lesson first!”</li>
        <li> 💬 “Great question! Right now, let’s stay on track with what we’re learning!”</li>
      </ul></div>
`,
          },
        },
      ],
    },
    // Step 9
    {
      nextButtonText: "Next Lesson",
      questions: [
        // Question 9.1
        {
          attribute: "classroom-engagement.attention/presence",
          label: "Your classroom presence",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Students will see the value in what they’re learning when you model the same energy, enthusiasm, and effort that you expect from them.

${tip({ text: "Set yourself up for success by thoroughly reviewing the lesson plan. Approach the teacher's notes with enthusiasm and bring that excitement to the students, helping them see the joy in the day’s learning." })}

If you’re ever subbing for a class without a lesson plan, check out [subplan.ai](http://subplan.ai). It provides free, AI-generated lesson plans to ensure you're always prepared, no matter what challenges the classroom presents.
`,
          },
        },
      ],
    },
  ],
};

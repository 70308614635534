import type { Task } from "~skillBuilder/utils/types";
import { image, link, title } from "../../contentBlocks";

export const LWCWhatIf: Task = {
  name: "What If?",
  uniqueName: "lwc-what-if",
  icon: "Lesson",
  durationTimeText: "2 min",
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      questions: [
        // Question 1.1
        {
          attribute: "lwc.what-if/intro",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "What If?", durationTimeText: "2 min" })}
<p>Discover a resource that will help you be prepared if a lesson plan isn’t provided.</p>
<div align="center">
<div style="max-width:300px;width:100%;">
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1734093811/skillbuilderAssets/optimized/what-if_intro.jpg", alt: "A teacher standing in front of a chalkboard that says Bonjour." })}
</div>
</div>
            `,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "lwc.what-if/swing-ai",
          label: "No lesson plan? No problem!",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<p>Don’t worry if a lesson plan isn’t provided. You can use Swing’s last minute lesson plan generator to create one in minutes. Input some simple information about the class you are covering, and you’ll get a ready-to-use lesson plan.</p><br>
<div align="center">
<div style="max-width:350px;width:100%;">
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1734101883/skillbuilderAssets/optimized/what-if_swing-ai.jpg", alt: "A computer and phone showing the Swing ai lesson plan genertor." })}<br>
</div>
</div>
  <p>${link({ text: "Lesson Plan Generator", url: "https://start.swingeducation.com/lesson-plan-generator" })}</p>
            `,
          },
        },
      ],
    },
    // Step 3
    {
      nextButtonText: "Next Lesson",
      questions: [
        // Question 3.1
        {
          attribute: "lwc.what-if/end-early",
          label: "What if a lesson ends early?",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<p>If the lesson plans provided run short, you can use your Swing resources to identify quick activities to use in the classroom to keep students actively engaged in learning during your time with them. It’s important to keep your class active and on task during the full class time. This helps maintain structure, and leads to a calmer environment.</p><br>
  <p>${link({ text: "Swing Resource Center", url: "https://swingeducation.com/sub-success-resource-center/" })}</p>
            `,
          },
        },
      ],
    },
  ],
};

import { FeedbackQuestion } from "~skillBuilder/components";

export const RFeedback: FeedbackQuestion[] = [
  // Feedback Question 1
  {
    attribute: "reliability/feedback",
    label: "Was the Reliability content helpful?",
    answerType: {
      type: "CHOICE",
      options: [
        { label: "Yes", value: "yes", icon: { name: "Thumb Up", color: "black" } },
        { label: "No", value: "no", icon: { name: "Thumb Down", color: "black" } },
      ],
    },
    isRequired: true,
  },
  // Feedback Question 2
  {
    attribute: "reliability/comments",
    label: "Tell us more of what you'd like to see (optional)",
    answerType: {
      type: "TEXT",
      placeholder: "Response",
    },
  },
];

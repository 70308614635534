import type { Task } from "~skillBuilder/utils/types";
import { image, title } from "../../contentBlocks";

export const RCountOnTask: Task = {
  name: "Committing to Schools",
  uniqueName: "r-count-on-task",
  icon: "Lesson",
  durationTimeText: "2 min",
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      questions: [
        // Question 1.1
        {
          attribute: "reliability.count-on/intro",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
${title({ text: "Committing to Schools", durationTimeText: "2 min" })}
<p>Learn the importance of following through with your commitment.</p>
<div style="text-align:center;max-width:300px;margin-top:24px;margin-left:auto;margin-right:auto;"/>
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1732044836/skillbuilderAssets/optimized/count-on-intro.jpg", alt: "female teacher standing with three children in front of a large yellow heart" })}
</div>
</div>
`,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "reliability.count-on/school-impact",
          label: "Schools are counting on you",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>When you sign up for a request, the school is notified and begins planning around your commitment. This is often a relief for a school that is worried about classroom coverage. Reliability means always following through on that commitment to the school.</p>
</div>
`,
          },
        },
      ],
    },
    // Step 3
    {
      nextButtonText: "Next Lesson",
      questions: [
        // Question 3.1
        {
          attribute: "reliability.count-on/swing-hero",
          label: "Reliability is key to becoming a Swing Hero",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Being consistently reliable boosts your score, bringing you closer to earning or maintaining Swing Hero status.
  <br><br>
  As a Swing Hero, you'll get access to: </p>
  <ul style="display: flex; flex-direction: column; gap: 0px; margin: 0 0 0 0; padding: 0 0 0 24px;">
    <li>Increased earning potential</li>
    <li>Priority access to certain ‌requests</li>
    <li>Profile badge</li>
    <li>And more!</li>
  </ul>
  <p>Once a school realizes that you are a reliable sub, they’re more likely to leave you positive feedback and invite you back to work at their school again.</p>
</div>
`,
          },
        },
      ],
    },
  ],
};

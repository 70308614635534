import { a as c, j as n } from "../../../emotion-react-jsx-runtime.browser.esm-CDWB6Ors.js";
import "@ionic/react";
import "../../../AuthProvider-DparLqAm.js";
import "react";
import "../../../react-paginate-CpOsCkIq.js";
import "@capacitor/core";
import { ButtonEdit as d } from "../../organisms/SubProfile/components/ButtonEdit/ButtonEdit.js";
import '../../../css/IconBlock.css';const a = "_row_19rrp_1", p = "_divider_19rrp_29", r = {
  row: a,
  "icon-none": "_icon-none_19rrp_7",
  "icon-left": "_icon-left_19rrp_11",
  "icon-right": "_icon-right_19rrp_15",
  "icon-both": "_icon-both_19rrp_19",
  "button-edit": "_button-edit_19rrp_24",
  divider: p
};
function g(t) {
  const {
    content: o,
    hasDivider: s,
    icon: e,
    onClick: i
  } = t, _ = l(e, i);
  return /* @__PURE__ */ c("div", {
    children: [/* @__PURE__ */ c("div", {
      className: `${r.row} ${_}`,
      children: [e, o, i ? /* @__PURE__ */ n("span", {
        className: r["button-edit"],
        children: /* @__PURE__ */ n(d, {
          onClick: i
        })
      }) : null]
    }), s && /* @__PURE__ */ n("div", {
      className: r.divider
    })]
  });
}
function l(t, o) {
  return !t && !o ? r["icon-none"] : t && !o ? r["icon-left"] : !t && o ? r["icon-right"] : r["icon-both"];
}
export {
  g as IconBlock,
  l as generateLayoutClassName
};

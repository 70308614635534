import type { Task } from "~skillBuilder/utils/types";
import { image, title } from "../../contentBlocks";

export const CESkillCheck: Task = {
  name: "Skill Check",
  uniqueName: "ce-skill-check",
  icon: "BookmarkCheck",
  durationTimeText: "15 min",
  isQuiz: true,
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      prevButtonText: "Review",
      showPreviousButton: true,
      questions: [
        // Question 1.1
        {
          attribute: "classroom-engagement.skill-check/intro",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "Skill Check", durationTimeText: "15 min" })}
<p>Show what you've learned! Complete this quiz to earn a qualification for your profile.</p>
<div style="text-align:center;max-width:250px;width:100%;margin-top:24px;margin-left:auto;margin-right:auto;"/>
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/v1731113489/skillbuilderAssets/optimized/woman-and-clipboard.png", alt: "illustrated woman standing in front of a checked clipboard" })}
</div>            
`,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "classroom-engagement.skill-check/negative",
          label: "What can negatively impact your attention in the classroom?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label: "Being on your phone",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label: "Reading a book",
                value: "invalid-2",
                isCorrect: false,
              },
              {
                label: "Listening to music",
                value: "invalid-3",
                isCorrect: false,
              },
              {
                label: "Not circulating the classroom",
                value: "invalid-4",
                isCorrect: false,
              },
              {
                label: "All of the above",
                value: "valid",
                isCorrect: true,
              },
            ],
          },
        },
      ],
    },
    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "classroom-engagement.skill-check/value",
          label: "What is the best way to show students that their learning is valuable?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label: "Sit quietly and let students work on their own.",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label: "Read through the lesson plan carefully and teach with enthusiasm.",
                value: "valid",
                isCorrect: true,
              },
              {
                label: "Let students know the lesson isn’t important by showing little energy.",
                value: "invalid-2",
                isCorrect: false,
              },
              {
                label: "Allow students to decide if they want to engage or not.",
                value: "invalid-3",
                isCorrect: false,
              },
            ],
          },
        },
      ],
    },
    // Step 4
    {
      questions: [
        // Question 4.1
        {
          attribute: "classroom-engagement.skill-check/names",
          label:
            "What is an effective strategy for a substitute teacher to quickly learn student names?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label: "Refer to students as “hey you” to keep things simple.",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label: "Avoid using names since you’ll only be there for a short time.",
                value: "invalid-2",
                isCorrect: false,
              },
              {
                label: "Use an attendance sheet or name tents to learn names quickly.",
                value: "valid",
                isCorrect: true,
              },
              {
                label: "Wait for students to introduce themselves if they feel like it.",
                value: "invalid-3",
                isCorrect: false,
              },
            ],
          },
        },
      ],
    },
    // Step 5
    {
      questions: [
        // Question 5.1
        {
          attribute: "classroom-engagement.skill-check/attitude",
          label: "How does a substitute teacher’s attitude impact the classroom environment?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label:
                  " If a substitute seems disengaged, students may pick up on it and lose interest.",
                value: "valid",
                isCorrect: true,
              },
              {
                label: "Students rarely notice your emotions, so attitude doesn’t matter.",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label: "“Fake it till you make it” has no effect on classroom engagement.",
                value: "invalid-2",
                isCorrect: false,
              },
              {
                label: "A substitute should always share their personal struggles with students.",
                value: "invalid-3",
                isCorrect: false,
              },
            ],
          },
        },
      ],
    },
    // Step 6
    {
      questions: [
        // Question 6.1
        {
          attribute: "classroom-engagement.skill-check/warm-up",
          label: "How are bell ringers and warm-ups important for student engagement?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label: "They allow students to socialize instead of focusing on the lesson.",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label: "They are only useful if the regular teacher is present.",
                value: "invalid-2",
                isCorrect: false,
              },
              {
                label:
                  "They help students transition into learning by sparking curiosity and activating prior knowledge.",
                value: "valid",
                isCorrect: true,
              },
              {
                label: "They serve as a good distraction before starting the actual lesson.",
                value: "invalid-3",
                isCorrect: false,
              },
            ],
          },
        },
      ],
    },
    // Step 7
    {
      questions: [
        // Question 7.1
        {
          attribute: "classroom-engagement.skill-check/grades",
          label:
            "Why is it important to tailor your engagement strategies to different grade levels?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label: "Students of all ages behave the same way in a classroom setting.",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label: "High school students prefer elementary-style activities for fun.",
                value: "invalid-2",
                isCorrect: false,
              },
              {
                label: "The same classroom management techniques work for all grade levels.",
                value: "invalid-3",
                isCorrect: false,
              },
              {
                label:
                  "Different age groups respond to different strategies, and adapting helps improve engagement and minimize disruptions.",
                value: "valid",
                isCorrect: true,
              },
            ],
          },
        },
      ],
    },
    // Step 8
    {
      questions: [
        // Question 8.1
        {
          attribute: "classroom-engagement.skill-check/brain-break",
          label: "When is the best time to use a brain break in the classroom?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label:
                  "Anytime students seem distracted, without considering the classroom dynamics.",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label:
                  "Only in elementary classrooms, as older students don’t benefit from brain breaks.",
                value: "invalid-2",
                isCorrect: false,
              },
              {
                label:
                  "After roughly 30 minutes of focused work or during transitions, if the class is well-managed.",
                value: "valid",
                isCorrect: true,
              },
              {
                label: "Whenever students request one, regardless of the classroom environment.",
                value: "invalid-3",
                isCorrect: false,
              },
            ],
          },
        },
      ],
    },
    // Step 9
    {
      questions: [
        // Question 9.1
        {
          attribute: "classroom-engagement.skill-check/exit-ticket",
          label:
            "What is the main benefit of collecting and leaving exit tickets for the teacher to review upon their return?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label: "The tickets provide insight into the class’s overall behavior.",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label:
                  "The tickets give the teacher a sense of how well students understood the lesson and participated in their absence.",
                value: "valid",
                isCorrect: true,
              },
              {
                label: "The tickets give guidance on who was absent for the lesson. ",
                value: "invalid-2",
                isCorrect: false,
              },
              {
                label: "They serve as a reward for students who finish the lesson early.",
                value: "invalid-3",
                isCorrect: false,
              },
            ],
          },
        },
      ],
    },
    // Step 10
    {
      questions: [
        // Question 10.1
        {
          attribute: "classroom-engagement.skill-check/note",
          label: "Why is it important that you leave notes for the teacher at the end of the day?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label: "Leaving notes is a great way to wrap up your time in the classroom.",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label: "These notes will provide insights into the day's successes and challenges.",
                value: "invalid-2",
                isCorrect: false,
              },
              {
                label:
                  "The notes will promote a smooth transition for the teacher when they return.",
                value: "invalid-3",
                isCorrect: false,
              },
              {
                label: "All of the above",
                value: "valid",
                isCorrect: true,
              },
            ],
          },
        },
      ],
    },
    // Step 11
    {
      questions: [
        // Question 11.1
        {
          attribute: "classroom-engagement.skill-check/engagement",
          label: "How can your engagement keep students motivated during class?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label:
                  "By strictly following the teacher's lesson plan without adding any personal input.",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label:
                  "By staying energetic, creative, and connected with the students, while sticking to the lesson plan.",
                value: "valid",
                isCorrect: true,
              },
              {
                label: "By allowing students to lead the class and create their own activities.",
                value: "invalid-2",
                isCorrect: false,
              },
              {
                label:
                  "By focusing mainly on classroom management and not interacting with students.",
                value: "invalid-3",
                isCorrect: false,
              },
            ],
          },
        },
      ],
    },
    // Step 12
    {
      nextButtonText: "Great!",
      questions: [
        // Question 12.1
        {
          attribute: "classroom-engagement.skill-check/outro",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div style="text-align: center;">
  ${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1731608485/skillbuilderAssets/optimized/skill_builder_complete.jpg", alt: "illustrated man holding trophy standing next to a woman" })}
  <p><strong>You've completed this skill!</strong></p>
  <p>Thank you for your commitment to developing your teaching skills. You'll now see <strong>Classroon Engagement</strong> as a qualification on your profile.</p>
</div>
`,
          },
        },
      ],
    },
  ],
};

import { Icon } from "swing-components";

import { Card } from "~components";
import { msg } from "~utils";
import styles from "../../SchoolProfile.module.css";
import { SchoolProfileTextField } from "../Helpers";

type DayOfFieldProps = {
  dayOfContact?: string;
  dayOfPhone?: string;
  dayOfEmail?: string;
};

type ParkingFieldProps = {
  parkingCost?: string;
  parkingLocation?: string;
};

type SchoolArrivingOnsiteProps = DayOfFieldProps &
  ParkingFieldProps & {
    checkinLocation?: string;
    checkinProcedure?: string;
    checkoutLocation?: string;
    checkoutProcedure?: string;
  };

export function SchoolArrivingOnsite(props: SchoolArrivingOnsiteProps) {
  const {
    dayOfContact,
    dayOfPhone,
    dayOfEmail,
    parkingCost,
    parkingLocation,
    checkinLocation,
    checkinProcedure,
    checkoutLocation,
    checkoutProcedure,
  } = props;
  return (
    <Card hideBoxShadow>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <h2 style={{ margin: "0" }}>{msg("ARRIVING_ONSITE_LABEL")}</h2>
      </div>

      <div>
        <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
          <Icon name="Phone" />
          <span style={{ fontWeight: "var(--swing-font-semibold)" }}>
            {msg("DAY_OF_CONTACT_HEADER")}
          </span>
        </div>
      </div>
      <SchoolProfileTextField value={dayOfContact} label={msg("DAY_OF_CONTACT_NAME_LABEL")} />
      <SchoolProfileTextField value={dayOfPhone} label={msg("DAY_OF_CONTACT_PHONE_LABEL")} />
      <SchoolProfileTextField value={dayOfEmail} label={msg("DAY_OF_CONTACT_EMAIL_LABEL")} />
      <div className={styles["divider-mobile"]}></div>
      <div>
        <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
          <Icon name="Car" />
          <span style={{ fontWeight: "var(--swing-font-semibold)" }}>{msg("PARKING_HEADER")}</span>
        </div>
      </div>

      <SchoolProfileTextField value={parkingCost} label={msg("PARKING_COST_LABEL")} />
      <SchoolProfileTextField value={parkingLocation} label={msg("PARKING_LOCATION_LABEL")} />
      <div className={styles["divider-mobile"]}></div>
      <div>
        <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
          <Icon name="Enter" />
          <span style={{ fontWeight: "var(--swing-font-semibold)" }}>
            {msg("CHECK_IN_PROCEDURE_HEADER")}
          </span>
        </div>
      </div>
      <SchoolProfileTextField value={checkinLocation} label={msg("CHECK_IN_LOCATION_LABEL")} />
      <SchoolProfileTextField value={checkinProcedure} label={msg("CHECK_IN_PROCEDURE_HEADER")} />
      <div className={styles["divider-mobile"]}></div>
      <div>
        <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
          <Icon name="Logout" />
          <span style={{ fontWeight: "var(--swing-font-semibold)" }}>
            {msg("CHECK_OUT_PROCEDURE_HEADER")}
          </span>
        </div>
      </div>
      <SchoolProfileTextField value={checkoutLocation} label={msg("CHECK_OUT_LOCATION_LABEL")} />
      <SchoolProfileTextField value={checkoutProcedure} label={msg("CHECK_OUT_PROCEDURE_HEADER")} />
    </Card>
  );
}

import "./ModalPayBreakdown.css";

import { IonItem, IonLabel, IonList, IonText } from "@ionic/react";
import { DateTime } from "luxon";
import { Colors, Icon } from "swing-components";
import { generateSwingSupportURL } from "swing-utils";

import { centsToDollars, msg, RequestData } from "~utils";

export type ModalPayBreakdownProps = {
  payment: RequestData["payment"];
  isMultiDay: boolean;
  isMultiWeek: boolean;
  onDismiss: () => void;
};

export function ModalPayBreakdown(props: ModalPayBreakdownProps) {
  const { payment, isMultiWeek, isMultiDay, onDismiss } = props;

  return (
    <div className="modal-wrapper" style={{ paddingTop: 0, paddingBottom: 0 }}>
      <div className="modal-header">
        <h2 className="modal-header-label">{msg("PAY_BREAKDOWN_LABEL")}</h2>
        {/* FIXME: when design system button is available */}
        <button onClick={onDismiss} style={{ backgroundColor: "transparent" }}>
          <Icon name="Close" color={Colors.blue500} />
        </button>
      </div>
      <IonList lines="full" style={{ paddingTop: 0, paddingBottom: 0 }}>
        {PayBreakdownBody({ payment, isMultiDay, isMultiWeek })}
      </IonList>
      <a
        target="_blank"
        rel="noreferrer"
        href={`${msg("SWING_SUPPORT_ZENDESK_PROXY_URL")}${encodeURIComponent(
          generateSwingSupportURL(
            payment?.isHourly
              ? msg("SWING_SUPPORT_LINK_PAY_W2")
              : msg("SWING_SUPPORT_LINK_PAY_1099"),
          ),
        )}`}
        className="modal-icon-link"
      >
        <span style={{ paddingRight: "8px" }} aria-hidden="true">
          <Icon name="External Link" color={Colors.blue500} />
        </span>
        <IonLabel className="modal-footer-label">{msg("PAY_BREAKDOWN_FOOTER")}</IonLabel>
      </a>
    </div>
  );
}

type PayBreakdownBodyProps = Omit<ModalPayBreakdownProps, "onDismiss">;

export function PayBreakdownBody(props: PayBreakdownBodyProps) {
  const { payment, isMultiDay, isMultiWeek } = props;
  const payLabel = payment?.isHourly ? `${msg("LABEL_HOURLY")}:` : `${msg("LABEL_TOTAL")}:`;
  const payDateLabel = isMultiWeek ? `${msg("LABEL_NEXT_PAY_DATE")}:` : `${msg("LABEL_PAY_DATE")}:`;
  const payDate = DateTime.fromISO(payment?.paymentDate ?? "");
  const payValue = payment?.payValue ?? 0;
  const isNonHourlyMultiDay = !payment?.isHourly && isMultiDay;

  return (
    <div>
      {isNonHourlyMultiDay && payment?.fullDayPayValue && (
        <RowItem
          label={`${msg("LABEL_FULL_DAY")}:`}
          content={centsToDollars(payment?.fullDayPayValue)}
        />
      )}
      {isNonHourlyMultiDay && payment?.halfDayPayValue && (
        <RowItem
          label={`${msg("LABEL_HALF_DAY")}:`}
          content={centsToDollars(payment?.halfDayPayValue)}
        />
      )}
      <RowItem label={payLabel} content={centsToDollars(payValue)} />
      <RowItem label={payDateLabel} content={payDate.toFormat("ccc',' LLL dd")} />
      {isMultiWeek && (
        <IonItem style={{ fontSize: "var(--swing-font-sm)" }}>{msg("PAY_DATE_DISCLAIMER")}</IonItem>
      )}
    </div>
  );
}

function RowItem({ label, content }: { label: string; content: string | number }) {
  return (
    <IonItem style={{ "--min-height": "40px", "--padding-end": "var(--spacing-lg)" }}>
      <div
        style={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "1fr 2fr",
          columnGap: "var(--spacing-xl)",
          fontSize: "var(--swing-font-sm)",
        }}
      >
        <IonText>{label}</IonText>
        <IonText>{content}</IonText>
      </div>
    </IonItem>
  );
}

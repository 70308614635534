import type { Task } from "~skillBuilder/utils/types";
import { image, tip, title } from "../../contentBlocks";

export const CEExitTickets: Task = {
  name: "Exit Tickets",
  uniqueName: "ce-exit-tickets",
  icon: "Lesson",
  durationTimeText: "10 min",
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      questions: [
        // Question 1.1
        {
          attribute: "classroom-engagement.exit-ticket/overview",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "Exit Tickets", durationTimeText: "10 min" })}
<p>As the class period comes to a close, exit tickets are an invaluable tool for measuring student engagement and providing the absent teacher with a clear snapshot of the day’s learning. By collecting these exit tickets, you gain insight into student understanding and participation, helping to assess their grasp of the material and inform future lessons.</p>
<div align="center">
<div style="max-width:300px;width:100%;">
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1743476768/skillbuilderAssets/optimized/CE-exitticket.jpg", alt: "Illustration of teacher standing and giving directions to four students." })}
</div>
`,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "classroom-engagement.exit-ticket/intro",
          label: "Introducing exit tickets",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Exit tickets are an effective way to assess student engagement and understanding of the lesson. In the last 5 minutes of class, ask students to grab a small piece of paper (it doesn’t need to be a full sheet). Inform them that you will be collecting it before they leave. This strategy provides valuable feedback and insight into their learning.
`,
          },
        },
      ],
    },
    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "classroom-engagement.exit-ticket/strategies",
          label: "Exit ticket strategies",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
- **3-2-1 Reflection**: 
  - 3 takeaways from the lesson
  - 2 questions they still have 
  - 1 item they found interesting <br><br>

- **Rate your understanding**: On a scale of 1-5 (1 being not at all, and 5 being I understand), how well did you understand today’s lesson? Explain why.

- **Summary**: Write a summary of today’s lesson in one sentence. 

- **Lingering Questions**: Write down what you may still be confused about or would like addressed in the next lesson. 

- **Brain Dump**: Set a timer or allow the bell to ring, and tell students to dump on paper everything they can about the lesson they learned today. *This is a great strategy when you do not have a lot of time left before the bell rings or for the next transition.*
`,
          },
        },
      ],
    },
    // Step 4
    {
      nextButtonText: "Next Lesson",
      questions: [
        // Question 4.1
        {
          attribute: "classroom-engagement.exit-ticket/collect",
          label: "Collecting exit tickets",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
When students are finished with the exit ticket or the bell rings, collect everyone’s exit ticket and neatly stack them on the teachers desk. 

${tip({ text: "Label with the class period (or if in elementary school the subject) and paper clip them all together so the teacher knows exactly what they are looking at when they return!" })}
`,
          },
        },
      ],
    },
  ],
};

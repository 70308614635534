import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useRef, useState } from "react";
import { useHistory } from "react-router";
import {
  AboutMe,
  AboutMeProps,
  Badge,
  ClassroomPreferences,
  ClassroomPreferencesProps,
  ContactInfo,
  ContactInfoProps,
  Education,
  EducationProps,
  Feedback,
  FeedbackProps,
  GradeLevelValueProps,
  LanguageValueProps,
  Qualifications,
  QualificationsProps,
  SubjectValueProps,
  SwingExperience,
  SwingExperienceProps,
  SwingHero,
  SwingHeroProps,
  WorkExperience,
  WorkExperienceProps,
} from "swing-components";

import {
  AlertBoxError,
  ContentDualColumn,
  ContentSingleColumn,
  ModalContentAboutMeEdit,
  ModalContentClassroomPreferencesGradeLevelEdit,
  ModalContentClassroomPreferencesLanguagesEdit,
  ModalContentClassroomPreferencesSubjectsEdit,
  ModalContentPhoneNumberEdit,
  ModalProfileImageEdit,
  useAuth,
  useModal,
} from "~components";
import {
  createEducationLandingUrl,
  createRequestsUrl,
  createSkillBuilderTasksURL,
  createWorkExperienceLandingUrl,
} from "~pages";
import { GET, msg, ProfileData, ProfilePUTPayload, PUT, useIsDesktop } from "~utils";
import { ScorePage } from "../ScoreTemplates";
import styles from "./Profile.module.css";

export function Profile() {
  const { userInfo, refreshUser } = useAuth();
  const shouldHideQualifications = userInfo.restrictions?.includes(
    "RESTRICTION_CANNOT_VIEW_QUALIFICATIONS",
  );
  const { data, error, isLoading } = useQuery<
    { data: ProfileData },
    AxiosError,
    { data: ProfileUiData }
  >({
    queryKey: ["fetchProfile"],
    queryFn: () => GET("/api/sub/profile"),
    // transform data into expected shape to display in UI
    select: (data) => ({
      data: transformProfileData({ profileData: data.data, userData: userInfo }),
    }),
  });

  return (
    <ProfileView
      data={data?.data}
      error={error}
      isLoading={isLoading}
      refreshUser={refreshUser}
      shouldHideQualifications={shouldHideQualifications}
    />
  );
}

type ProfileViewProps = {
  data?: ProfileUiData;
  error?: AxiosError | null;
  isLoading?: boolean;
  refreshUser: () => Promise<void>;
  shouldHideQualifications?: boolean;
};

export function ProfileView(props: ProfileViewProps) {
  const { data, error, isLoading, refreshUser, shouldHideQualifications } = props;
  const { aboutMe, classroomPreferences, contactInfo, education, workExperience, ...rest } =
    data ||
    ({
      contactInfo: {},
      aboutMe: {},
      classroomPreferences: {},
      workExperience: {},
      education: {},
    } as ProfileUiData);

  /* Hooks */
  const isDesktop = useIsDesktop();
  const history = useHistory();
  const [_modalContent, _setModalContent] = useState<EditModalContent>();
  const queryClient = useQueryClient();

  // using ref as don't want to trigger a re-render and instead
  // rely on setQueryData to re-render when the data has been updated
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dataToSyncToCache = useRef<null | any>(null);

  /* Updates */
  async function handleSyncData() {
    // only update data in client cache when it exists
    if (dataToSyncToCache.current) {
      queryClient.setQueryData(["fetchProfile"], dataToSyncToCache.current);

      // Note: we need to ensure when the profile image is updated, the navigation too is updated to reflect the updated profile image
      // Updating the userData query via`queryClient.setQueryData(["userData"], (oldData: User) => { ... }`
      // was updating the cache but was not causing a re-render of the navigation resulting in the existing and not updated profile
      // image to be displayed. Therefore, we're using the `refreshUser` method that is available to re-fetch the user data
      // only when the profile image is actually updated
      if (contactInfo.profileImageUrl !== dataToSyncToCache.current.data.imageUrl) {
        await refreshUser();
      }

      dataToSyncToCache.current = null;
    }
  }

  const { mutateAsync: updateProfile, error: mutationError } = useMutation<
    { data: ProfileData },
    AxiosError,
    ProfilePUTPayload
  >({
    mutationFn: (payload: ProfilePUTPayload) => {
      return PUT("/api/sub/profile", payload);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: async (updatedProfile: any) => {
      // set data to sync to client cache
      dataToSyncToCache.current = updatedProfile;
      await handleSyncData();
      closeEditModal();
    },
  });

  function handleOnConfirm(payload: ProfilePUTPayload) {
    updateProfile(payload);
  }

  /* Modal */
  const { openModal: openEditModal, closeModal: closeEditModal } = useModal({
    component: (
      <>
        {_modalContent === "aboutMe" && (
          <ModalContentAboutMeEdit
            aboutMeText={aboutMe?.about}
            onDismiss={() => closeEditModal()}
            onConfirm={handleOnConfirm}
            title={msg("PROFILE_EDIT_ABOUT_ME_LABEL")}
            hasApiError={error || mutationError}
          />
        )}
        {_modalContent === "classroomPreferencesGradeLevel" && (
          <ModalContentClassroomPreferencesGradeLevelEdit
            gradeLevels={classroomPreferences?.classroomPreferences?.gradeLevels?.value ?? []}
            onConfirm={(gradeValues) =>
              handleOnConfirm({
                preference: {
                  id: classroomPreferences?.classroomPreferences?.gradeLevels?.id,
                  type: "grade-level",
                  value: gradeValues,
                },
              })
            }
            onDismiss={() => closeEditModal()}
            title={msg("PROFILE_EDIT_PREFERRED_GRADE_LEVELS_LABEL")}
            hasApiError={error || mutationError}
          />
        )}
        {_modalContent === "classroomPreferencesLanguages" && (
          <ModalContentClassroomPreferencesLanguagesEdit
            languages={classroomPreferences?.classroomPreferences?.languages?.value ?? []}
            onConfirm={(languages) =>
              handleOnConfirm({
                preference: {
                  id: classroomPreferences?.classroomPreferences?.languages?.id,
                  type: "language",
                  value: languages,
                },
              })
            }
            onDismiss={() => closeEditModal()}
            title={msg("PROFILE_EDIT_PREFERRED_LANGUAGES_LABEL")}
            hasApiError={error || mutationError}
          />
        )}
        {_modalContent === "classroomPreferencesSubjects" && (
          <ModalContentClassroomPreferencesSubjectsEdit
            subjects={classroomPreferences?.classroomPreferences?.subjects?.value ?? []}
            onConfirm={(subjects) =>
              handleOnConfirm({
                preference: {
                  id: classroomPreferences?.classroomPreferences?.subjects?.id,
                  type: "subject",
                  value: subjects,
                },
              })
            }
            onDismiss={() => closeEditModal()}
            title={msg("PROFILE_EDIT_PREFERRED_SUBJECTS_LABEL")}
            hasApiError={error || mutationError}
          />
        )}
        {_modalContent === "contactInfo" && (
          <ModalContentPhoneNumberEdit
            isPhoneNumberVerified={contactInfo?.isPhoneNumberVerified}
            phoneNumber={contactInfo?.phoneNumber}
            onDismiss={() => closeEditModal()}
          />
        )}
        {_modalContent === "profileImage" && (
          <ModalProfileImageEdit onDismiss={() => closeEditModal()} onConfirm={updateProfile} />
        )}
      </>
    ),
    modalOptions: { cssClass: "modal-sub-profile-edit" },
  });

  /**
   * Editability
   *
   * handle 'editable' state for each section here vs. in
   * the data layer to co-locate associated functions
   */

  contactInfo.editable = {
    isEditable: true,
    onEditAvatar: () => {
      _setModalContent("profileImage");
      openEditModal();
    },
    onEditPhoneNumber: () => {
      _setModalContent("contactInfo");
      openEditModal();
    },
  };

  if (aboutMe) {
    aboutMe.editable = {
      isEditable: true,
      onEditAboutMe: () => {
        _setModalContent("aboutMe");
        openEditModal();
      },
    };
  }
  if (classroomPreferences) {
    classroomPreferences.editable = {
      isEditable: true,
      onEditGradeLevel: () => {
        _setModalContent("classroomPreferencesGradeLevel");
        openEditModal();
      },
      onEditSubjects: () => {
        _setModalContent("classroomPreferencesSubjects");
        openEditModal();
      },
      onEditLanguages: () => {
        _setModalContent("classroomPreferencesLanguages");
        openEditModal();
      },
    };
  }

  if (education) {
    education.editable = {
      isEditable: true,
      onEditEducation: () => history.push(createEducationLandingUrl()),
    };
  }

  if (workExperience) {
    workExperience.editable = {
      isEditable: true,
      onEditWorkExperience: () => history.push(createWorkExperienceLandingUrl()),
    };
  }

  const shouldRenderFeedback = rest.feedback?.feedbacks && rest.feedback.feedbacks.length > 0;
  const shouldRenderQualifications =
    !shouldHideQualifications && rest.qualifications?.qualifications.length > 0;
  const shouldRenderSwingExperience = !!Object.keys(rest.swingExperience || {}).length;
  return (
    <ScorePage title={msg("PAGE_TITLE_PROFILE")} isLoading={isLoading}>
      {error ? (
        <AlertBoxError margin="0px" />
      ) : isDesktop ? (
        <ContentDualColumn
          contentA={
            <div className={styles.container}>
              <ContactInfo
                {...contactInfo}
                badge={
                  !contactInfo.isPhoneNumberVerified ? (
                    <Badge status="warning" text={msg("PHONE_VERIFICATION_BADGE_LABEL")} />
                  ) : undefined
                }
              />
              <AboutMe {...aboutMe} />
              {shouldRenderSwingExperience && <SwingExperience {...rest.swingExperience} />}
              <Education {...education} />
              <WorkExperience {...workExperience} />
              <ClassroomPreferences {...classroomPreferences} />
              {shouldRenderFeedback && <Feedback {...rest.feedback} />}
            </div>
          }
          contentB={
            <div className={styles.rightColumnContainer}>
              <SwingHero
                {...rest.swingHero}
                onClick={{
                  requestsOnClick: () => history.push(createRequestsUrl()),
                  skillBuilderOnClick: () => history.push(createSkillBuilderTasksURL("swing")),
                }}
              />
              {shouldRenderQualifications && <Qualifications {...rest.qualifications} />}
            </div>
          }
        />
      ) : (
        <ContentSingleColumn>
          <div className={styles.container}>
            <ContactInfo
              {...contactInfo}
              badge={
                !contactInfo.isPhoneNumberVerified ? (
                  <Badge status="warning" text={msg("PHONE_VERIFICATION_BADGE_LABEL")} />
                ) : undefined
              }
            />
            <SwingHero
              {...rest.swingHero}
              onClick={{
                requestsOnClick: () => history.push(createRequestsUrl()),
                skillBuilderOnClick: () => history.push(createSkillBuilderTasksURL("swing")),
              }}
            />
            <AboutMe {...aboutMe} />
            {shouldRenderQualifications && <Qualifications {...rest.qualifications} />}
            {shouldRenderSwingExperience && <SwingExperience {...rest.swingExperience} />}
            <Education {...education} />
            <WorkExperience {...workExperience} />
            <ClassroomPreferences {...classroomPreferences} />
            {shouldRenderFeedback && <Feedback {...rest.feedback} />}
          </div>
        </ContentSingleColumn>
      )}
    </ScorePage>
  );
}

type EditModalContent =
  | "aboutMe"
  | "classroomPreferencesGradeLevel"
  | "classroomPreferencesLanguages"
  | "classroomPreferencesSubjects"
  | "contactInfo"
  | "profileImage";

type ProfileUiData = {
  contactInfo: ContactInfoProps;
  aboutMe?: AboutMeProps;
  qualifications: QualificationsProps;
  swingExperience?: SwingExperienceProps;
  classroomPreferences?: ClassroomPreferencesProps;
  education?: EducationProps;
  workExperience?: WorkExperienceProps;
  feedback?: FeedbackProps;
  swingHero: SwingHeroProps;
};

export type UserInfoUi =
  | {
      email: string;
      name: string;
      imageUrl?: string;
      isPhoneNumberVerified?: boolean;
      isHourly?: boolean;
    }
  | Record<string, never>;

type TransformProfileDataProps = {
  profileData: ProfileData;
  userData: UserInfoUi;
};

export function transformProfileData(props: TransformProfileDataProps) {
  const { profileData, userData } = props;

  const _classroomPreferences: ClassroomPreferencesProps = { classroomPreferences: {} };

  profileData.preferences?.map((preference) => {
    switch (preference.type) {
      case "grade-level":
        return (_classroomPreferences.classroomPreferences!.gradeLevels = {
          id: preference.id,
          value: preference.value.toString().split(",") as GradeLevelValueProps,
        });
      case "subject":
        return (_classroomPreferences.classroomPreferences!.subjects = {
          id: preference.id,
          value: preference.value.toString().split(",") as SubjectValueProps,
        });
      case "language":
        return (_classroomPreferences.classroomPreferences!.languages = {
          id: preference.id,
          value: preference.value.toString().split(",") as LanguageValueProps,
        });
    }
  });

  return {
    contactInfo: {
      email: userData.email,
      name: userData.name,
      phoneNumber: profileData.phoneNumber,
      profileImageUrl: profileData.imageUrl,
      isPhoneNumberVerified: userData.isPhoneNumberVerified,
      isSwingHero: !!profileData.stats?.isSwingHero,
    },
    aboutMe: { about: profileData.about },
    qualifications: {
      qualifications: profileData.qualifications ?? [],
      isSwingHero: !!profileData.stats?.isSwingHero,
      skillBuilderCompletedTasks: profileData.skillbuilder,
    },
    swingExperience: profileData.stats,
    education: {
      education: profileData.education,
    },
    workExperience: { experiences: profileData.experience },
    classroomPreferences: _classroomPreferences,
    feedback: { feedbacks: profileData.feedback },
    swingHero: {
      subDaysWorked: profileData.stats?.daysWorked ?? 0,
      score: profileData.stats?.heroScore,
      isSwingHero: profileData.stats?.isSwingHero ?? false,
      isHourly: userData?.isHourly ?? false,
      isSwingHeroEligible: profileData.isSwingHeroEligible,
    },
  } satisfies ProfileUiData;
}

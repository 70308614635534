import { j as a, a as r } from "../../../../../emotion-react-jsx-runtime.browser.esm-CDWB6Ors.js";
import { I as p } from "../../../../../Icon-C7c5yX_u.js";
import "../../../../../AuthProvider-DparLqAm.js";
import d from "../../../../string-table.js";
import "@ionic/react";
import { IconBlock as u } from "../../../../molecules/IconBlock/IconBlock.js";
import "react";
import "../../../../../react-paginate-CpOsCkIq.js";
import "@capacitor/core";
import { Section as S } from "../Section/Section.js";
import '../../../../../css/ClassroomPreferences.css';const f = "_container_1d0ci_1", l = {
  container: f,
  "content-title": "_content-title_1d0ci_9",
  "content-no-info": "_content-no-info_1d0ci_13"
}, o = {
  datomic: {
    "k-2": "tk,pre-k,k,1,2",
    "3-5": "3,4,5",
    "6-8": "6,7,8",
    "9-12": "9,10,11,12"
  },
  valueTypes: {
    "pre-k": "k-2",
    tk: "k-2",
    k: "k-2",
    1: "k-2",
    2: "k-2",
    3: "3-5",
    4: "3-5",
    5: "3-5",
    6: "6-8",
    7: "6-8",
    8: "6-8",
    9: "9-12",
    10: "9-12",
    11: "9-12",
    12: "9-12"
  },
  display: {
    "pre-k": "Pre-K to 2nd",
    tk: "Pre-K to 2nd",
    k: "Pre-K to 2nd",
    1: "Pre-K to 2nd",
    2: "Pre-K to 2nd",
    3: "3rd to 5th",
    4: "3rd to 5th",
    5: "3rd to 5th",
    6: "6th to 8th",
    7: "6th to 8th",
    8: "6th to 8th",
    9: "9th to 12th",
    10: "9th to 12th",
    11: "9th to 12th",
    12: "9th to 12th"
  }
}, L = {
  "gen-ed": "General Education",
  elective: "Electives (Music, Art, etc.)",
  english: "English/Language Arts",
  "physical-education": "Health/Physical Education",
  "social-studies": "History/Social Studies",
  math: "Math",
  science: "Science",
  "special-education": "Special Education",
  "world-languages": "World Languages",
  other: "Other",
  "not-sure": "I'm not sure yet"
}, O = {
  english: "English",
  spanish: "Spanish",
  mandarin: "Chinese (Mandarin)",
  cantonese: "Chinese (Cantonese)",
  french: "French",
  tagalog: "Tagalog",
  vietnamese: "Vietnamese",
  arabic: "Arabic",
  korean: "Korean",
  japanese: "Japanese",
  german: "German",
  asl: "American Sign Language",
  other: "Other"
};
function E(t, e) {
  if (!t || t.length === 0) return;
  const n = Array.from(new Set(t.map((i) => e[i])));
  if (e === o.display)
    return n.sort((i, s) => _(i) - _(s));
  const c = Object.values(e).reduce((i, s, m) => (i[s] = m, i), {});
  return n.sort((i, s) => c[i] - c[s]);
}
function j(t, e) {
  return e === o.datomic ? t.map((n) => e[n]) : JSON.parse(t).map((n) => n);
}
function M(t) {
  var h, c, i;
  const {
    editable: e,
    classroomPreferences: n
  } = t;
  return /* @__PURE__ */ a(S, {
    title: d("SUB_PROFILE_CLASSROOM_PREFERENCES_HEADER"),
    children: /* @__PURE__ */ r("div", {
      className: l.container,
      children: [/* @__PURE__ */ a(u, {
        content: /* @__PURE__ */ r("div", {
          children: [/* @__PURE__ */ r("span", {
            className: l["content-title"],
            children: [d("SUB_PROFILE_CLASSROOM_PREFERENCES_LABEL_GRADE_LEVEL"), ":", " "]
          }), g(E((h = n == null ? void 0 : n.gradeLevels) == null ? void 0 : h.value, o.display))]
        }),
        icon: /* @__PURE__ */ a(p, {
          name: "Grad Cap"
        }),
        onClick: e != null && e.isEditable ? e.onEditGradeLevel : void 0
      }), /* @__PURE__ */ a(u, {
        content: /* @__PURE__ */ r("div", {
          children: [/* @__PURE__ */ r("span", {
            className: l["content-title"],
            children: [d("SUB_PROFILE_CLASSROOM_PREFERENCES_LABEL_SUBJECTS"), ":", " "]
          }), g(E((c = n == null ? void 0 : n.subjects) == null ? void 0 : c.value, L))]
        }),
        icon: /* @__PURE__ */ a(p, {
          name: "Book"
        }),
        onClick: e != null && e.isEditable ? e.onEditSubjects : void 0
      }), /* @__PURE__ */ a(u, {
        content: /* @__PURE__ */ r("div", {
          children: [/* @__PURE__ */ r("span", {
            className: l["content-title"],
            children: [d("SUB_PROFILE_CLASSROOM_PREFERENCES_LABEL_LANGUAGES"), ":", " "]
          }), g(E((i = n == null ? void 0 : n.languages) == null ? void 0 : i.value, O))]
        }),
        icon: /* @__PURE__ */ a(p, {
          name: "Chat Bubble Active"
        }),
        onClick: e != null && e.isEditable ? e.onEditLanguages : void 0
      })]
    })
  });
}
function g(t) {
  return t ? /* @__PURE__ */ a("span", {
    children: t.join(", ")
  }) : /* @__PURE__ */ a("span", {
    className: l["content-no-info"],
    children: d("SUB_PROFILE_NO_INFORMATION")
  });
}
function _(t) {
  return Object.keys(o.display).indexOf(Object.keys(o.display).find((n) => o.display[n] === t) ?? "");
}
export {
  M as ClassroomPreferences,
  E as convertPreferencesToDisplay,
  j as convertPreferencesToSave,
  _ as getGradeLevelIndex,
  o as gradeLevelMappings,
  O as languageMapper,
  L as subjectMapper
};

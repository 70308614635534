import styles from "./doDont.module.css";

type DoDontParams = {
  doText: string;
  dontText: string;
};

export function doDont(params: DoDontParams) {
  const { doText, dontText } = params;
  return `<ul class="${styles.container}">
    <li class="${styles.listItem}">
      <span class="material-symbols-rounded ${styles.iconNo}">close</span>
      <span class="${styles.text}">${dontText}</span>
    </li>
    <li class="${styles.listItem}">
      <span class="material-symbols-rounded ${styles.iconYes}">check</span>
      <span class="${styles.text}">${doText}</span>
    </li>
  </ul>`;
}

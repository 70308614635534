import type { Task } from "~skillBuilder/utils/types";

export const LWCOveriew: Task = {
  name: "Overview",
  uniqueName: "lwc-overview",
  icon: "Overview",
  durationTimeText: "1 min",
  isIntro: true,
  steps: [
    // Step 1
    {
      nextButtonText: "Start Lesson",
      questions: [
        // Question 1.1
        {
          attribute: "lwc/overview",
          label: "Learn how to lead your classroom with confidence",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>We’ll cover everything from your first moments in the school, to closing out the day successfully. We will also share advice on how you can be proactive, review tips for how to maintain a positive classroom environment, and explain how to be prepared if a lesson plan isn’t provided.
  </p>
</div>
            `,
          },
        },
      ],
    },
  ],
};

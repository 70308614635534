import { j as e, F as m } from "../../../../../emotion-react-jsx-runtime.browser.esm-CDWB6Ors.js";
import "@ionic/react";
import { h as u, j as p } from "../../../../../AuthProvider-DparLqAm.js";
import "react";
import "../../../../../react-paginate-CpOsCkIq.js";
import "@capacitor/core";
import { SwingHeroInEligible as a } from "./SwingHeroInEligible/SwingHeroInEligible.js";
function E(t) {
  const {
    subDaysWorked: o,
    score: s,
    isSwingHero: l,
    isHourly: r,
    onClick: i,
    isSwingHeroEligible: n
  } = t, g = o < 5 || s === void 0;
  return /* @__PURE__ */ e(m, {
    children: n === !1 ? /* @__PURE__ */ e(a, {
      isHourly: r,
      onClick: i == null ? void 0 : i.skillBuilderOnClick
    }) : g ? /* @__PURE__ */ e(u, {
      subDaysWorked: o,
      isHourly: r,
      onClick: i == null ? void 0 : i.requestsOnClick
    }) : /* @__PURE__ */ e(p, {
      score: s,
      isSwingHero: l,
      isHourly: r,
      onClick: i == null ? void 0 : i.requestsOnClick
    })
  });
}
export {
  E as SwingHero
};

import type { SkillBuilderConfiguration } from "~skillBuilder/utils/types";
import {
  CEAttention,
  CEBrainBreaks,
  CEClosing,
  CEExitTickets,
  CEFeedback,
  CEGradeLevels,
  CEOverview,
  CERapport,
  CESkillCheck,
  CEWarmup,
} from "./classroomEngagement";
import {
  CmBestPractices,
  CmCommonPitfalls,
  CmDealingWithDisruptions,
  CmFeedback,
  CmOverview,
  CmSkillCheck,
  CmStartingStrong,
} from "./classroomManagement";
import {
  LWCBeProactive,
  LWCFeedback,
  LWCFirstImpression,
  LWCLastImpression,
  LWCOveriew,
  LWCPositiveEnvironment,
  LWCSkillCheck,
  LWCWhatIf,
} from "./leadWithConfidence";
import {
  RChallenges,
  RCountOnTask,
  RFeedback,
  ROverview,
  RReliableTask,
  RSkillCheck,
  RSupport,
} from "./reliability";
import {
  SECChallenges,
  SECCommunication,
  SECFeedback,
  SECFlexibility,
  SECOverview,
  SECSkillCheck,
  SECStrategies,
  SECTerminology,
} from "./specialEducationClassrooms";

export const SwingConfiguration: SkillBuilderConfiguration = {
  version: "swing",
  groups: [
    // Group #1
    {
      name: "Classroom Management",
      tasks: [
        // Task #1
        CmOverview,
        // Task #2
        CmStartingStrong,
        // Task #3
        CmBestPractices,
        // Task #4
        CmDealingWithDisruptions,
        // Task #5
        CmCommonPitfalls,
        // Task #6
        CmSkillCheck,
      ],
      feedback: CmFeedback,
    },
    // Group #2
    {
      name: "Reliability",
      tasks: [
        // Task #1
        ROverview,
        // Task #2
        RCountOnTask,
        // Task #3
        RReliableTask,
        // Task #4
        RChallenges,
        // Task #5
        RSupport,
        // Task #6
        RSkillCheck,
      ],
      feedback: RFeedback,
    },
    // Group #3
    {
      name: "Lead with Confidence",
      tasks: [
        // Task #1
        LWCOveriew,
        // Task #2
        LWCFirstImpression,
        // Task #3
        LWCBeProactive,
        // Task #4
        LWCPositiveEnvironment,
        // Task #5
        LWCWhatIf,
        // Task #6
        LWCLastImpression,
        // Task #7
        LWCSkillCheck,
      ],
      feedback: LWCFeedback,
    },
    // Group #4
    {
      name: "Special Education Classrooms",
      tasks: [
        // Task #1
        SECOverview,
        // Task #2
        SECTerminology,
        // Task #3
        SECCommunication,
        // Task #4
        SECFlexibility,
        // Task #5
        SECStrategies,
        // Task #6
        SECChallenges,
        // Task #7
        SECSkillCheck,
      ],
      feedback: SECFeedback,
    },
    // Group #5
    {
      name: "Classroom Engagement",
      tasks: [
        // Task #1
        CEOverview,
        // Task #2
        CEAttention,
        // Task #3
        CERapport,
        // Task #4
        CEWarmup,
        // Task #5
        CEGradeLevels,
        // Task #6
        CEBrainBreaks,
        // Task #7
        CEExitTickets,
        // Task #8
        CEClosing,
        // Task #9
        CESkillCheck,
      ],
      feedback: CEFeedback,
    },
  ],
};

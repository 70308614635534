import type { Task } from "~skillBuilder/utils/types";
import { image, title } from "../../contentBlocks";

export const SECSkillCheck: Task = {
  name: "Skill Check",
  uniqueName: "sec-skill-check",
  icon: "BookmarkCheck",
  durationTimeText: "10 min",
  isQuiz: true,
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      prevButtonText: "Review",
      showPreviousButton: true,
      questions: [
        // Question 1.1
        {
          attribute: "special-education-classrooms.skill-check/intro",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "Skill Check", durationTimeText: "10 min" })}
<p>Show what you've learned! Complete this quiz to earn a qualification for your profile.</p>
<div style="text-align:center;max-width:250px;width:100%;margin-top:24px;margin-left:auto;margin-right:auto;"/>
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/v1731113489/skillbuilderAssets/optimized/woman-and-clipboard.png", alt: "Illustrated woman standing in front of a checked clipboard." })}
</div>            
`,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "special-education-classrooms.skill-check/iep",
          label: "What is an IEP?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label:
                  "An IEP is an individualized plan that includes a student's services, goals, and accommodations.",
                value: "valid",
                isCorrect: true,
              },
              {
                label: "An IEP is an aligned standard that all SPED classrooms have to adhere to.",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label: "A plan created by the teacher to address classroom rules.",
                value: "invalid-2",
                isCorrect: false,
              },
              {
                label: "A general education curriculum that all students are required to follow.",
                value: "invalid-3",
                isCorrect: false,
              },
            ],
          },
        },
      ],
    },
    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "special-education-classrooms.skill-check/skills",
          label: "What skills are necessary when working in a special education classroom?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label: "The ability to enforce strict discipline.",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label: "Expert proficiency in using all assistive technology.",
                value: "invalid-2",
                isCorrect: false,
              },
              {
                label: "Patience, adaptability, and effective communication skills.",
                value: "valid",
                isCorrect: true,
              },
              {
                label: "Specialized training and a certification in special education.",
                value: "invalid-3",
                isCorrect: false,
              },
            ],
          },
        },
      ],
    },
    // Step 4
    {
      questions: [
        // Question 4.1
        {
          attribute: "special-education-classrooms.skill-check/overstimulation",
          label: "How can you help a student who is overstimulated?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label: "Speak loudly to get their attention, and tell them to be quiet.",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label: "Redirect them to a calm and quiet space, and assist in reducing stimuli.",
                value: "valid",
                isCorrect: true,
              },
              {
                label: "Ignore the behavior and wait for the student to calm down on their own.",
                value: "invalid-2",
                isCorrect: false,
              },
              {
                label:
                  "Remove structure to allow the student to do whatever they want until they are calm.",
                value: "invalid-3",
                isCorrect: false,
              },
            ],
          },
        },
      ],
    },
    // Step 5
    {
      questions: [
        // Question 5.1
        {
          attribute: "special-education-classrooms.skill-check/plan",
          label: "What can I do if I am not given a student’s educational plan?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label: "Stay engaged and be observant with the student.",
                value: "valid",
                isCorrect: true,
              },
              {
                label: "Assume the student does not have one.",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label: "Focus on the entire class.",
                value: "invalid-2",
                isCorrect: false,
              },
              {
                label: "Proceed with the day as normal.",
                value: "invalid-3",
                isCorrect: false,
              },
            ],
          },
        },
      ],
    },
    // Step 6
    {
      questions: [
        // Question 6.1
        {
          attribute: "special-education-classrooms.skill-check/chunk",
          label: "What does it mean to chunk a task?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label:
                  "Get rid of difficult tasks by telling the student they do not have to complete the work.",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label:
                  "Remove parts of the assignment so that the student can focus on easier questions.",
                value: "invalid-2",
                isCorrect: false,
              },
              {
                label:
                  "Break down instructions into smaller, manageable steps to prevent steps from getting lost.",
                value: "valid",
                isCorrect: true,
              },
              {
                label: "Do the work with the student so they can ask you for the correct answers.",
                value: "invalid-3",
                isCorrect: false,
              },
            ],
          },
        },
      ],
    },
    // Step 7
    {
      questions: [
        // Question 7.1
        {
          attribute: "special-education-classrooms.skill-check/signs-overstimulation",
          label: "What can be a sign of overstimulation?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label: "Restlessness",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label: "Irritability",
                value: "invalid-2",
                isCorrect: false,
              },
              {
                label: "Difficulty focusing",
                value: "invalid-3",
                isCorrect: false,
              },
              {
                label: "Crying or yelling out",
                value: "invalid-4",
                isCorrect: false,
              },
              {
                label: "The constant movement of arms or legs",
                value: "invalid-5",
                isCorrect: false,
              },
              {
                label: "All of the above",
                value: "valid",
                isCorrect: true,
              },
            ],
          },
        },
      ],
    },
    // Step 8
    {
      questions: [
        // Question 8.1
        {
          attribute: "special-education-classrooms.skill-check/challenges",
          label: "What should always come first when navigating challenges?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label: "Alerting admin or cooperating teachers in the classroom.",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label: "Call for help to ensure you are following procedures.",
                value: "invalid-2",
                isCorrect: false,
              },
              {
                label: "Safety first!",
                value: "valid",
                isCorrect: true,
              },
              {
                label: "Fix the issue first.",
                value: "invalid-3",
                isCorrect: false,
              },
            ],
          },
        },
      ],
    },
    // Step 7
    {
      nextButtonText: "Great!",
      questions: [
        // Question 7.1
        {
          attribute: "special-education-classrooms.skill-check/outro",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div style="text-align: center;">
  ${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1731608485/skillbuilderAssets/optimized/skill_builder_complete.jpg", alt: "illustrated man holding trophy standing next to a woman" })}
  <p><strong>You've completed this skill!</strong></p>
  <p>Thank you for your commitment to developing your teaching skills. You'll now see <strong>Special Education Classrooms</strong> as a qualification on your profile.</p>
</div>
`,
          },
        },
      ],
    },
  ],
};

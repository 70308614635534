import styles from "./link.module.css";

type LinkParams = {
  text: string;
  url: string;
};

export function link(params: LinkParams) {
  const { text, url } = params;
  return `<div>
    <a class="${styles.link}" href="${url}" target="_blank" rel="noreferrer">
      <span class="material-symbols-rounded ${styles.icon}">open_in_new</span>
      <span class="${styles.text}">${text}</span>
    </a>
  </div>`;
}

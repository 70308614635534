import { IonDatetime, IonDatetimeButton, IonModal } from "@ionic/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { DateTime } from "luxon";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  Colors,
  DropDown,
  Experience,
  ExperienceTag,
  ExperienceTagKey,
  ExperienceType,
  ExperienceTypeKey,
} from "swing-components";

import {
  AlertBoxError,
  ButtonScoreWrapper,
  Checkbox,
  ContentSingleColumn,
  ModalSubProfileEdit,
  ModalSubProfileEditBody,
  ModalSubProfileEditFooter,
  ModalSubProfileEditHeader,
  RadioInput,
  TextAreaInput,
  Toggle,
  useModal,
} from "~components";
import {
  DELETE,
  GET,
  msg,
  ProfileData,
  ProfileDeletePayload,
  ProfilePUTPayload,
  PUT,
} from "~utils";
import { createWorkExperienceLandingUrl } from "../../Routes";
import { ScorePage } from "../../ScoreTemplates";

export function WorkExperienceNewEdit() {
  // If no params then skip API query call as new
  const params = useParams<{ workExperienceId: string }>();
  const {
    data: workExperience,
    error,
    isLoading,
  } = useQuery<{ data: ProfileData }, AxiosError>({
    queryKey: ["fetchProfile"],
    queryFn: () => GET("/api/sub/profile"),
    enabled: !!params.workExperienceId,
  });

  const selectedWorkExperience = workExperience?.data?.experience?.find(
    (work) => work.id === parseInt(params.workExperienceId ?? ""),
  );

  return (
    <WorkExperienceNewEditView
      workExperience={selectedWorkExperience}
      isLoading={isLoading}
      error={error}
    />
  );
}

// The Id and Type are required on the API, but not required to ADD an experience
type AddWorkExperience = Omit<Experience, "id" | "type"> & {
  id?: number;
  type?: ExperienceTypeKey;
};

type WorkExperienceNewEditViewProps = {
  workExperience?: AddWorkExperience;
  error?: AxiosError | null;
  isLoading?: boolean;
};

function WorkExperienceNewEditView(props: WorkExperienceNewEditViewProps) {
  const { workExperience, error, isLoading } = props;
  /* Hooks */
  const history = useHistory();
  const queryClient = useQueryClient();
  // If no ID then it is a new entry and should not have the current job toggle checked
  const [isCurrentJob, setIsCurrentJob] = useState<boolean>(
    workExperience?.id && !workExperience?.endDate ? true : false,
  );
  const _originalIsCurrentJob = workExperience?.id && !workExperience?.endDate ? true : false;
  const [experienceForm, setExperienceForm] = useState<AddWorkExperience>(
    workExperience ?? {
      startDate: DateTime.now().toISO(),
      endDate: DateTime.now().toISO(),
      organization: "",
      type: undefined,
      tags: [],
      role: "",
    },
  );
  const [_modalContent, _setModalContent] = useState<EditModalContent>();
  const PAGE_TITLE = workExperience
    ? msg("PAGE_TITLE_WORK_EXPERIENCE_LANDING")
    : msg("PAGE_TITLE_WORK_EXPERIENCE_EDIT");

  /* Updates */
  const handleUpdateSuccess = (updatedProfile: { data: ProfileData }) => {
    queryClient.setQueryData(["fetchProfile"], updatedProfile);
    history.push(createWorkExperienceLandingUrl());
  };

  const { mutate: updateProfileExperience, error: updateMutationError } = useMutation<
    { data: ProfileData },
    AxiosError,
    ProfilePUTPayload
  >({
    mutationFn: (payload: ProfilePUTPayload) => {
      return PUT("/api/sub/profile", payload);
    },
    onSuccess: handleUpdateSuccess,
  });

  const { mutate: deleteExperience, error: deleteMutationError } = useMutation<
    { data: ProfileData },
    AxiosError,
    ProfileDeletePayload
  >({
    mutationFn: (payload: ProfileDeletePayload) => {
      return DELETE("/api/sub/profile", payload);
    },
    onSuccess: handleUpdateSuccess,
  });

  // Sync experience with props so the component is initialized on refresh
  useEffect(() => {
    if (workExperience?.id) {
      setExperienceForm(workExperience);
    }
  }, [workExperience]);

  // TODO: figure out what is causing the race condition that is sometimes rendering 2 modals, 1 with no content
  // Note: doesn't seem to be StrictMode. Temp fix using `useRef` and `onDidDismiss`
  const isModelOpen = useRef(false);
  const { openModal: openEditModal, closeModal: closeEditModal } = useModal({
    component: (
      <>
        {_modalContent === "workType" && (
          <ModalContentWorkType
            workType={experienceForm.type}
            onDismiss={() => {
              closeEditModal();
            }}
            onSave={(value) => setExperienceForm({ ...experienceForm, type: value })}
            title={msg("PROFILE_WORK_EXPERIENCE_TYPE_LABEL")}
          />
        )}
        {_modalContent === "workGradeLevel" && (
          <ModalContentWorkTag
            workGradeLevel={experienceForm.tags}
            onSave={(values) => setExperienceForm({ ...experienceForm, tags: values })}
            onDismiss={() => {
              closeEditModal();
            }}
            title={msg("PROFILE_WORK_EXPERIENCE_GRADE_LABEL")}
          />
        )}
      </>
    ),
    modalOptions: {
      cssClass: "modal-sub-profile-edit",
      onDidDismiss: () => (isModelOpen.current = false),
    },
  });

  const isFormInvalid =
    (experienceForm === workExperience && isCurrentJob === _originalIsCurrentJob) ||
    experienceForm.organization.trim().length === 0 ||
    experienceForm.role.trim().length === 0 ||
    experienceForm.type === undefined ||
    experienceForm.tags.length === 0;

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isFormInvalid) return;

    if (isCurrentJob) {
      experienceForm.endDate = undefined;
    }

    // To handle no default for ExperienceType, the form type has that field optional
    // where the api has it required.
    updateProfileExperience({
      experience: {
        ...experienceForm,
        ...(workExperience?.id && { id: experienceForm.id }),
      } as Experience,
    });
  };

  const handleDelete = () => {
    if (!experienceForm?.id) return;
    deleteExperience({ experience: { id: experienceForm.id } });
  };

  return (
    <ScorePage title={PAGE_TITLE} hasBack isLoading={isLoading}>
      <ContentSingleColumn>
        {(error || updateMutationError || deleteMutationError) && (
          <div style={{ margin: "0 16px" }}>
            <AlertBoxError />
          </div>
        )}
        {!error ? (
          <form onSubmit={handleSubmit} style={{ margin: "0 16px" }}>
            <div style={{ background: `${Colors.white200}`, padding: "16px" }}>
              {/* TODO: See if we can replace this w/ <TextAreaInput/> in swing-components */}
              <TextAreaInput
                onChange={(value) => setExperienceForm({ ...experienceForm, organization: value })}
                isRequired
                label={msg("PROFILE_WORK_EXPERIENCE_ORGANIZATION_LABEL")}
                value={experienceForm.organization}
                placeholder={msg("PROFILE_WORK_EXPERIENCE_ORGANIZATION_PLACEHOLDER")}
              />
              <div style={{ paddingTop: "16px" }}>
                {/* TODO: See if we can replace this w/ <TextAreaInput/> in swing-components */}
                <TextAreaInput
                  onChange={(value) => setExperienceForm({ ...experienceForm, role: value })}
                  isRequired
                  label={msg("PROFILE_WORK_EXPERIENCE_TITLE_LABEL")}
                  value={experienceForm.role}
                  placeholder={msg("PROFILE_WORK_EXPERIENCE_TITLE_PLEACEHOLDER")}
                />
              </div>
              <DropDown
                stacked
                id="experience-type"
                label={msg("PROFILE_WORK_EXPERIENCE_TYPE_LABEL")}
                value={convertTypeToDisplay(experienceForm?.type)}
                placeholder={msg("LABEL_SELECT")}
                isRequired
                onClick={() => {
                  _setModalContent("workType");
                  if (!isModelOpen.current) {
                    isModelOpen.current = true;
                    openEditModal();
                  }
                }}
              />
              <DropDown
                stacked
                id="experience-tags"
                label={msg("PROFILE_WORK_EXPERIENCE_GRADE_LABEL")}
                value={convertTagsToDisplay(experienceForm.tags).join(", ")}
                placeholder={msg("LABEL_SELECT")}
                isRequired
                onClick={() => {
                  _setModalContent("workGradeLevel");
                  if (!isModelOpen.current) {
                    isModelOpen.current = true;
                    openEditModal();
                  }
                }}
              />
              {/* Not part of the Payload for the form, drives disabling of EndDate field */}
              <Toggle
                label={msg("PROFILE_WORK_EXPERIENCE_CURRENT_JOB")}
                isSelected={isCurrentJob}
                onToggleChange={(value: boolean) => setIsCurrentJob(value)}
              />
              <DatePicker
                id="work-start-date"
                label={msg("LABEL_START")}
                date={experienceForm.startDate}
                pickerDisplayType="month-year"
                onDateChange={(value) => setExperienceForm({ ...experienceForm, startDate: value })}
              />
              <DatePicker
                id="work-end-date"
                label={msg("LABEL_END")}
                date={experienceForm.endDate}
                pickerDisplayType="month-year"
                onDateChange={(value) => setExperienceForm({ ...experienceForm, endDate: value })}
                isDisabled={isCurrentJob}
              />
            </div>
            <ButtonScoreWrapper
              buttonPrimary={{
                expand: "block",
                label: msg("LABEL_SAVE"),
                disabled: isFormInvalid,
                type: "submit",
              }}
              {...(workExperience && {
                buttonSecondary: {
                  expand: "block",
                  label: "Delete",
                  onClick: handleDelete,
                  disabled: false,
                },
              })}
              backgroundColor="white200"
            />
          </form>
        ) : null}
      </ContentSingleColumn>
    </ScorePage>
  );
}

type EditModalContent = "workGradeLevel" | "workType";

type DatePickerProps = {
  id: string;
  label: string;
  date?: string; //Ionic datepicker wants ISO, provide default until Luxon is added to this project
  pickerDisplayType: "time" | "month-year" | "date";
  onDateChange: (date: string) => void;
  isDisabled?: boolean;
};

function DatePicker(props: DatePickerProps) {
  const {
    id,
    label,
    date = DateTime.now().toISO(),
    pickerDisplayType,
    onDateChange,
    isDisabled = false,
  } = props;
  return (
    <div
      style={{ padding: "16px 0px", width: "100%", borderBottom: `1px solid ${Colors.slate200}` }}
    >
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <span style={{ fontWeight: "var(--swing-font-semibold)" }}>{label}</span>
        <IonDatetimeButton datetime={id} disabled={isDisabled} />
      </div>
      {/* TODO add conditional error handling in the future */}
      {/* TODO when refactoring away from Ionic, add to the consolidated modal hook above */}
      <IonModal keepContentsMounted backdropDismiss={false}>
        <IonDatetime
          showDefaultButtons
          id={id}
          presentation={pickerDisplayType}
          value={date}
          onIonChange={(newDate: CustomEvent) => {
            onDateChange(newDate.detail.value);
          }}
          disabled={isDisabled}
        />
      </IonModal>
    </div>
  );
}

type ModalContentWorkTypeProps = {
  title: string;
  workType: ExperienceTypeKey | undefined;
  onSave: (workType: ExperienceTypeKey) => void;
  onDismiss: () => void;
};

function ModalContentWorkType(props: ModalContentWorkTypeProps) {
  const { workType, onSave, title, onDismiss } = props;

  const [_workType, _setWorkType] = useState<ExperienceTypeKey | undefined>(workType ?? undefined);

  function handleOnChange(value: ExperienceTypeKey) {
    _setWorkType(value);
  }

  function handleOnSave() {
    if (_workType) {
      onSave(_workType);
      onDismiss();
    }
  }

  return (
    <ModalSubProfileEdit>
      <ModalSubProfileEditHeader onDismiss={onDismiss} title={title} />
      <ModalSubProfileEditBody>
        <div>
          {Object.entries(ExperienceType).map(([key, value]) => {
            return (
              <div style={{ padding: "16px 24px" }} key={key}>
                <RadioInput
                  isSelected={_workType === key}
                  onChange={() => handleOnChange(key as ExperienceTypeKey)}
                  value={key}
                  label={value}
                />
              </div>
            );
          })}
        </div>
      </ModalSubProfileEditBody>
      <ModalSubProfileEditFooter
        buttonPrimary={{
          isDisabled: !_workType,
          text: msg("LABEL_SAVE"),
          onClick: () => handleOnSave(),
        }}
      />
    </ModalSubProfileEdit>
  );
}

function convertTypeToDisplay(type?: ExperienceTypeKey) {
  return type ? ExperienceType[type] : "";
}

type ModalContentWorkTagsProps = {
  title: string;
  workGradeLevel: ExperienceTagKey[] | undefined;
  onSave: (workTags: ExperienceTagKey[]) => void;
  onDismiss: () => void;
};

function ModalContentWorkTag(props: ModalContentWorkTagsProps) {
  const { workGradeLevel, onSave, onDismiss, title } = props;

  const [_workGradeLevel, _setWorkGradeLevel] = useState<ExperienceTagKey[] | undefined>(
    workGradeLevel ?? undefined,
  );

  function handleOnChange(checked: boolean, value: ExperienceTagKey) {
    if (checked) {
      _setWorkGradeLevel((selected) => [...(selected as ExperienceTagKey[]), value]);
    } else {
      _setWorkGradeLevel(_workGradeLevel?.filter((item) => item !== value));
    }
  }

  function handleOnSave() {
    if (_workGradeLevel) {
      onSave(_workGradeLevel);
      onDismiss();
    }
  }

  return (
    <ModalSubProfileEdit>
      <ModalSubProfileEditHeader onDismiss={onDismiss} title={title} />
      <ModalSubProfileEditBody>
        <div>
          {Object.entries(ExperienceTag).map(([key, value]) => {
            const typeKey = key as ExperienceTagKey;
            const isChecked = _workGradeLevel?.includes(typeKey);
            return (
              <Checkbox
                key={key}
                onChange={(e) => handleOnChange(e, key as ExperienceTagKey)}
                isChecked={isChecked}
                label={value}
              />
            );
          })}
        </div>
      </ModalSubProfileEditBody>
      <ModalSubProfileEditFooter
        buttonPrimary={{
          isDisabled: _workGradeLevel?.length === 0,
          text: msg("LABEL_SAVE"),
          onClick: () => handleOnSave(),
        }}
      />
    </ModalSubProfileEdit>
  );
}

function convertTagsToDisplay(tags: ExperienceTagKey[]) {
  const tagDisplayValues: string[] = [];
  tags.forEach((tag) => {
    tagDisplayValues.push(ExperienceTag[tag]);
  });
  return tagDisplayValues;
}

import type { Task } from "~skillBuilder/utils/types";

export const CEOverview: Task = {
  name: "Overview",
  uniqueName: "ce-overview",
  icon: "Overview",
  durationTimeText: "1 min",
  isIntro: true,
  steps: [
    // Step 1
    {
      nextButtonText: "Start Lesson",
      questions: [
        // Question 1.1
        {
          attribute: "classroom-engagement/overview",
          label: "Why classroom engagement is important",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
As educators, the goal is to foster a positive learning environment where students feel engaged, focused, and motivated. However, some days, maintaining student attention can be a challenge–regardless of the setting.

The good news? Engagement doesn’t require reinventing the lesson plan. In fact, our school partners encourage substitutes to follow the lesson plan closely. 

Think of engagement as **enhancing** the lesson with energy, creativity, and meaningful connections. By incorporating small but effective strategies, you can keep students motivated, maintain classroom flow, and create a dynamic learning experience. In this Skill Builder, you’ll learn many classroom engagement strategies recommended by our school partners and educators.
`,
          },
        },
      ],
    },
  ],
};

import { Task } from "~skillBuilder/utils/types";
import { image, title } from "../../contentBlocks";

export const CmDealingWithDisruptions: Task = {
  name: "Dealing with Disruptions",
  uniqueName: "cm-dealing-with-disruptions",
  icon: "Lesson",
  durationTimeText: "2 min",
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      questions: [
        // Question 1.1
        {
          attribute: "classroom-management.disruptions/intro",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "Dealing with Disruptions", durationTimeText: "2 min" })}
<p>Learn how to navigate disruptions and distractions smoothly.</p>
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/v1731113490/skillbuilderAssets/optimized/teacher-and-students.png", alt: "illustrated teacher and children sat at their desk" })}
            `,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "classroom-management.disruptions/non-negotiables",
          label: "Non-Negotiables",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <ul style="display: flex; flex-direction: column; gap: 24px; margin: 0px 0 0 16px; padding: 0;">
    <li><strong>Choose two to five non-negotiable behaviors.</strong> These are behaviors that are needed to keep the class safe and productive. Make sure you communicate these non-negotiable behaviors with students both verbally and in writing.</li>
    <li>
      <strong>Common non-negotiables:</strong>
      <ul style="display: flex; flex-direction: column; gap: 24px; margin: 24px 0 0 16px; padding: 0;">
        <li><strong>One mic:</strong> students should not talk when teacher is addressing the class</li>
        <li><strong>Inside voice:</strong> during group work, students should only talk loud enough for the person next to them to hear</li>
        <li><strong>Stay in seat:</strong> students should ask permission to get up</li>
        <li><strong>Respect each other:</strong> students should treat each other with respect</li>
      </ul>
    </li>
  </ul>
</div>
            `,
          },
        },
      ],
    },

    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "classroom-management.disruptions/address-behavior",
          label: "Addressing student behaviors",
          // description: "",
          answerType: {
            value: "valid",
            type: "CONTENT",
            markdown: `
<div>
  <ul style="display: flex; flex-direction: column; gap: 24px; margin: 0 0 0 16px; padding: 0 0 0 8px;">
    <li>Be firm, yet kind when addressing undesirable student behaviors.</li>
    <li>Approach the student and address the behavior discreetly.</li>
    <li>Position yourself so you are on the student's level and not hovering above them.</li>
  </ul>
</div>
            `,
          },
        },
      ],
    },
    // Step 4
    {
      questions: [
        // Question 4.1
        {
          attribute: "classroom-management.disruptions/tone",
          label: "How to communicate with students",
          // description: "",
          answerType: {
            value: "valid",
            type: "CONTENT",
            markdown: `
<div>
  <ul style="display: flex; flex-direction: column; gap: 24px; margin: 0 0 0 16px; padding: 0;">
    <li>Maintain a supportive, kind, and firm tone that is only loud enough for that student to hear.</li>
    <li>Name the undesirable behavior.</li>
    <li>Remind the student of the expectation they are not meeting.</li>
    <li>Give them a choice: meet expectations or receive the next consequence.</li>
  </ul>
</div>
            `,
          },
        },
      ],
    },
    // Step 5
    {
      questions: [
        // Question 5.1
        {
          attribute: "classroom-management.disruptions/dont-argue",
          label: "After resetting expectations",
          // description: "",
          answerType: {
            value: "valid",
            type: "CONTENT",
            markdown: `
<div>
  <ul style="display: flex; flex-direction: column; gap: 24px; margin: 0 0 0 16px; padding: 0;">
    <li>Walk away and do not engage in an argument, negotiation, or back and forth with the student.</li>
    <li>Write detailed notes for the teacher that state the student, behavior, your actions, and how the student reacted.</li>
    <li>Look for opportunities to acknowledge struggling students for appropriate behavior.</li>
  </ul>
</div>
            `,
          },
        },
      ],
    },
    // Step 6
    {
      questions: [
        // Question 6.1
        {
          attribute: "classroom-management.disruptions/consequence-chain",
          label: "Follow the consequence chain",
          // description: "",
          answerType: {
            value: "valid",
            type: "CONTENT",
            markdown: `
<div>
  <p style="margin-top: 0px;">Follow the school's or teacher's consequence chain for not meeting behavior expectations. If none is provided, develop your own. Refer back to your classroom expectations often.</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 7
    {
      nextButtonText: "Next Lesson",
      questions: [
        // Question 7.1
        {
          attribute: "classroom-management.disruptions/ask-for-help",
          label: "Don't be afraid to ask for help",
          // description: "",
          answerType: {
            value: "valid",
            type: "CONTENT",
            markdown: `
<div>
  <p style="margin-top: 0px;">If you truly feel you need help with the classroom or a student, the best thing to do is reach out to the front office or your admin contact to escalate an issue. In the end, everyone will be grateful that you asked for help.</p>
</div>
            `,
          },
        },
      ],
    },
  ],
};

import type { Task } from "~skillBuilder/utils/types";
import { image, tip, title } from "../../contentBlocks";

export const CEBrainBreaks: Task = {
  name: "Brain Breaks",
  uniqueName: "ce-brain-breaks",
  icon: "Lesson",
  durationTimeText: "10 min",
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      questions: [
        // Question 1.1
        {
          attribute: "classroom-engagement.brain-breaks/overview",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "Brain Breaks", durationTimeText: "10 min" })}
<p>Educators use brain breaks as a powerful tool to boost focus and re-energize students at any grade level. They work best after approximately 30 minutes of focused work or as a transition between tasks. Our school partners and their teachers recommend that they are brief—just 2-3 minutes—to provide a refreshing mental reset without derailing the lesson.</p>
<div align="center">
<div style="max-width:200px;width:100%;">
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1743476769/skillbuilderAssets/optimized/CE-brainbreaks.jpg", alt: "Illustration of teacher standing and streching with students." })}
</div>
`,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "classroom-engagement.brain-breaks/strategically",
          label: "Use brain breaks strategically",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Substitutes who have established strong classroom management can leverage brain breaks to maintain student engagement. However, if the class is already struggling with structure, introducing a break may lead to further disruptions. Gauge the classroom dynamic before implementing them to ensure they enhance, rather than hinder, the learning environment.
`,
          },
        },
      ],
    },
    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "classroom-engagement.brain-breaks/reset",
          label: "Mental reset strategies",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
A quick mental reset can help students refocus and transition smoothly into the next task. Try these simple yet effective strategies:
- **Deep Breathing**: Guide students through a breathing exercise: inhale for four counts, hold for four, and exhale for four. Repeat a few times to promote relaxation and focus.
- **Imagine Calm**: Have students rest their heads on their desks and visualize a place where they feel most at peace. Silently count down from 15, then gently prompt them to lift their heads and transition into the next activity with a refreshed mindset.
`,
          },
        },
      ],
    },
    // Step 4
    {
      questions: [
        // Question 4.1
        {
          attribute: "classroom-engagement.brain-breaks/movement",
          label: "Movement-based brain breaks",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Incorporating movement helps re-energize students and improve focus. Try these engaging strategies:

- **Stand & Stretch**: Have students stand up, stretch their arms overhead, roll their shoulders, and take deep breaths to release tension and reset their focus.
- **Snap-Clap Challenge**: Pick a student to start a snap-and-clap pattern. The next student adds a new move, and the pattern continues around the room. See how long the class can keep the sequence going without making a mistake!
- **Power Pose Reset**: Have students stand up and hold a confident “power pose” (hands on hips, arms crossed, superhero stance, etc.) for 30 seconds. This quick reset can boost focus and energy.
- **Walk & Talk**: If space allows, pair students up and let them take a short, structured walk around the classroom while discussing a topic related to the lesson. This keeps them engaged while incorporating movement.
`,
          },
        },
      ],
    },
    // Step 5
    {
      questions: [
        // Question 5.1
        {
          attribute: "classroom-engagement.brain-breaks/peer",
          label: "Peer interaction",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Peer interaction activities are a great way to engage students and promote social learning.
- **Turn and Talk**: Have students pair up with someone nearby and share one thing with each other. Since this is a brain break, it can be anything unrelated to the lesson. 
Examples: 
<div>
      <ul style="display: flex; flex-direction: column; gap: 4px; margin: 0 0 0 32px; padding: 0 0 0 32px;">
        <li>Name one thing you’re excited about this week.</li>
        <li>What’s your go-to snack?</li>
        <li>Share your favorite hobby.</li>
        <li>If you could meet any famous person, who would it be and why?</li>
      </ul></div>

- **Rock-Paper-Scissors Tournament**: Have students stand up and compete in a tournament-style game of rock-paper-scissors. The winners advance, and this continues until only two students remain.

${tip({ text: "If there’s an odd number of students, join in to make it even! This creates a fun, lighthearted atmosphere and helps build community." })}

`,
          },
        },
      ],
    },
    // Step 6
    {
      nextButtonText: "Next Lesson",
      questions: [
        // Question 6.1
        {
          attribute: "classroom-engagement.brain-breaks/creative",
          label: "Creative outlet",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Incorporating creative outlets into the classroom can help refresh students' minds and spark their creativity.

- **30-Second-Draw**: Set a timer for 30 seconds and challenge students to quickly draw something that brings them joy, whether it's a doodle, symbol, or abstract shape. It’s a fun and fast way to spark creativity and refresh their minds! 

- **"Word Cloud" Challenge**: Ask students to quickly jot down 5 words that describe their current mood, a favorite hobby, or something related to the lesson. Afterward, have them create a quick "word cloud" on their paper, arranging the words in any way they like. This sparks creativity while allowing them to express themselves. 
`,
          },
        },
      ],
    },
  ],
};

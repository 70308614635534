import { ActionSheet } from "@capacitor/action-sheet";
import { AppLauncher } from "@capacitor/app-launcher";
import { Capacitor } from "@capacitor/core";
import { IonText } from "@ionic/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { DateTime } from "luxon";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getFeatureFlags } from "src/utils/feature-flags";
import { AlertBox, Colors, DataItemView, Icon, IconName, TextAreaInput } from "swing-components";

import {
  ButtonScore,
  CardHeader,
  IntervalWithTimeZone,
  ModalCancelPolicy,
  ModalDatesTimes,
  ModalPayBreakdown,
  ModalSchoolFeedbackSubmitted,
  Toggle,
  TooltipTriggerSubAvailability,
  useModal,
} from "~components";
import { createSkillBuilderTaskURL } from "~pages";
import { SwingConfiguration } from "~skillBuilder/utils/configurations/swing";
import type { SkillBuilderConfiguration } from "~skillBuilder/utils/types";
import {
  formatCityStateZip,
  formatPay,
  formatStringArray,
  generateGoogleMapsLink,
  handleMapLinkNavigation,
  msg,
  openLinkInApp,
  PUT,
  RequestData,
  SubAvailabilityPayload,
  useIsDesktop,
} from "~utils";
import styles from "./RequestDetailCard.module.css";

type RequestDetailCardProps = {
  request: RequestData;
  alertBox?: JSX.Element;
};

export function RequestDetailCard(props: RequestDetailCardProps) {
  const { request, alertBox } = props;
  // Note: we have some inconsistencies between mobile and desktop that
  // are more suitable to be solved in JS to avoid rendering the DOM Nodes
  // in addition to conditionally hooking into the sub-component props

  /********* Hooks ********/
  const isDesktop = useIsDesktop();
  const queryClient = useQueryClient();

  const [showFlexFillInput, setShowFlexFillInput] = useState(false);
  const [flexFillInputVal, setFlexFillInputVal] = useState("");

  /********* Mutation ********/
  const { mutate: updateSubAvailability, error: updateMutationError } = useMutation<
    { data: RequestData },
    AxiosError,
    SubAvailabilityPayload
  >({
    mutationFn: (payload: SubAvailabilityPayload) => {
      return PUT("/api/sub/request/{requestId}/availability", payload, {
        pathParams: { requestId: request.id },
      });
    },
    onSuccess: (updatedRequestData: { data: RequestData }) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchRequestDetails", updatedRequestData.data.id],
      });
    },
  });

  /********* Modals ********/
  const { openModal: openPayModal, closeModal: closePayModal } = useModal({
    component: (
      <ModalPayBreakdown
        payment={request.payment}
        isMultiWeek={request.isMultiWeek}
        isMultiDay={request.isMultiDay}
        onDismiss={() => closePayModal()}
      />
    ),
    modalOptions: {
      cssClass: `pay-breakdown-modal`,
    },
  });

  const { openModal: openCancelPolicyModal, closeModal: closeCancelPolicyModal } = useModal({
    component: <ModalCancelPolicy onDismiss={() => closeCancelPolicyModal()} />,
    modalOptions: {
      cssClass: `cancel-policy-modal`,
    },
  });

  const intervalsWithTimezone: IntervalWithTimeZone[] = request.intervals.map((interval) => {
    return {
      startDate: DateTime.fromISO(interval.startDate).setZone(request.school.timeZone),
      endDate: DateTime.fromISO(interval.endDate).setZone(request.school.timeZone),
    };
  });

  const schoolAddress = {
    street: request?.school?.address?.street ?? "",
    city: request?.school?.address?.city ?? "",
    state: request?.school?.address?.state ?? "",
    zip: request?.school?.address?.zip ?? "",
  };

  const googleMapsLink = generateGoogleMapsLink(schoolAddress);

  const { openModal: openDateTimesModal, closeModal: closeDateTimesModal } = useModal({
    component: (
      <ModalDatesTimes
        intervals={intervalsWithTimezone}
        message={msg("LABEL_TIMEZONE_DISCLAIMER", {
          schoolName: request.school.name,
          timeZone: request.school.timeZone,
        })}
        onDismiss={() => closeDateTimesModal()}
      />
    ),
    modalOptions: {
      cssClass: "dates-times-modal",
    },
  });

  const { openModal: openReviewedFeedbackModal, closeModal: closeReviewedFeedbackModal } = useModal(
    {
      component: (
        <ModalSchoolFeedbackSubmitted
          onDismiss={() => closeReviewedFeedbackModal()}
          feedback={request.schoolFeedback}
        />
      ),
      modalOptions: {
        cssClass: "modal-sub-profile-edit",
      },
    },
  );

  const isFlexFillEligible =
    getFeatureFlags().FLEXIBLE_MD_FILL && request.isMultiDay && request.status === "STATUS_OPEN";

  const hasSpecialEdSubject = request.subjects?.some(
    (subject) => subject === "SPED" || subject === "Special Education",
  );
  const specialEdTaskUrl = hasSpecialEdSubject && getSpecialEdTaskUrl();

  /********* Render ********/
  return (
    <div style={{ height: "fit-content", backgroundColor: Colors.white200 }}>
      <CardHeader
        requestId={request.id}
        schoolName={request.school.name}
        schoolId={request.school.id}
        status={request.status}
      />
      {alertBox && <div style={{ padding: "16px 16px 0" }}>{alertBox}</div>}
      <div className={styles.divider}></div>
      <div style={{ padding: "0px 16px 16px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
          {/* MOBILE */}
          {!isDesktop ? (
            <>
              <div className={styles["date-row-wrapper"]}>
                <div
                  className={styles["date-time-mobile"]}
                  onClick={request.isMultiDay ? openDateTimesModal : undefined}
                >
                  <div style={{ display: "grid" }}>
                    <IonText className={styles["date-time-mobile-label"]}>
                      {msg("LABEL_START")}:
                    </IonText>
                    <IonText>
                      {request.displayDate
                        ? request.isMultiDay
                          ? request.displayDate?.split(" - ")[0]
                          : request.displayDate
                        : msg("UNPROVIDED_DATA_MESSAGE")}
                    </IonText>
                  </div>
                  {request.isMultiDay ? (
                    <div className={styles["date-row"]}>
                      <div style={{ display: "grid" }}>
                        <IonText className={styles["date-time-mobile-label"]}>
                          {msg("LABEL_END")}:
                        </IonText>
                        <IonText>
                          {request.displayDate
                            ? request.displayDate?.split(" - ")[1]
                            : msg("UNPROVIDED_DATA_MESSAGE")}
                        </IonText>
                      </div>
                      <div>
                        <Icon name="Chevron Right" />
                      </div>
                    </div>
                  ) : (
                    <div style={{ display: "grid" }}>
                      <IonText className={styles["date-time-mobile-label"]}>
                        {msg("LABEL_TIME")}:
                      </IonText>
                      <IonText>
                        {request.displayTime ? request.displayTime : msg("UNPROVIDED_DATA_MESSAGE")}
                      </IonText>
                    </div>
                  )}
                </div>
              </div>
              {/* Flex Fill Mobile */}
              {isFlexFillEligible &&
                (request.didSubSubmitAvailability ? (
                  <AlertBox color="info" showIcon>
                    <p style={{ margin: 0 }}>{msg("FLEX_FILL_SHARED_AVAILABILITY_ALERT_MSG")}</p>
                  </AlertBox>
                ) : (
                  !request.didSubSubmitAvailability && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.10)",
                      }}
                    >
                      <div style={{ fontSize: 16, flexGrow: 1, minWidth: "max-content" }}>
                        <TooltipTriggerSubAvailability id={`tooltip-${request.id}`} />
                      </div>
                      <div style={{ flexShrink: 0 }}>
                        <Toggle
                          isSelected={showFlexFillInput}
                          onToggleChange={() => setShowFlexFillInput(!showFlexFillInput)}
                        />
                      </div>
                    </div>
                  )
                ))}
              {showFlexFillInput && !request.didSubSubmitAvailability && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                  }}
                >
                  <TextAreaInput
                    label={msg("FLEX_FILL_LABEL")}
                    placeholder={msg("FLEX_FILL_PLACEHOLDER")}
                    value={flexFillInputVal}
                    onChange={(val) => setFlexFillInputVal(val)}
                    errorMessage={updateMutationError ? msg("FLEX_FILL_ERROR_MSG") : undefined}
                  />
                  <div style={{ alignSelf: "flex-end" }}>
                    <ButtonScore
                      label="Submit"
                      fill="clear"
                      disabled={!flexFillInputVal.trim()}
                      onClick={() => updateSubAvailability({ subAvailability: flexFillInputVal })}
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            // DESKTOP
            <>
              <DataItemView label={msg("LABEL_DATE")} icon="Calendar Overlap">
                <div>
                  <IonText>{request.displayDate}</IonText>
                </div>
                {request.isMultiDay ? (
                  <IonText
                    style={{ color: Colors.blue500, cursor: "pointer" }}
                    onClick={openDateTimesModal}
                  >
                    {msg("DATES_TIMES_LABEL")}
                  </IonText>
                ) : (
                  <IonText>{request.displayTime}</IonText>
                )}
              </DataItemView>
              {/* Flex Fill Desktop */}
              {isFlexFillEligible && (
                <DataItemView
                  label={<TooltipTriggerSubAvailability id={`tooltip-${request.id}`} />}
                >
                  {/* The toggle has built-in padding and the marginTop is to mitigate it */}
                  <div style={{ marginTop: -12 }}>
                    <Toggle
                      disabled={request.didSubSubmitAvailability}
                      isSelected={request.didSubSubmitAvailability ? true : showFlexFillInput}
                      onToggleChange={() => setShowFlexFillInput(!showFlexFillInput)}
                    />
                  </div>
                  {request.didSubSubmitAvailability && (
                    <AlertBox color="info" showIcon>
                      <p style={{ margin: 0 }}>{msg("FLEX_FILL_SHARED_AVAILABILITY_ALERT_MSG")}</p>
                    </AlertBox>
                  )}
                  {showFlexFillInput && !request.didSubSubmitAvailability && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                      }}
                    >
                      <TextAreaInput
                        label={msg("FLEX_FILL_LABEL")}
                        placeholder={msg("FLEX_FILL_PLACEHOLDER")}
                        value={flexFillInputVal}
                        onChange={(val) => setFlexFillInputVal(val)}
                        errorMessage={updateMutationError ? msg("FLEX_FILL_ERROR_MSG") : undefined}
                      />
                      <div style={{ alignSelf: "flex-end" }}>
                        <ButtonScore
                          label="Submit"
                          fill="clear"
                          disabled={!flexFillInputVal.trim()}
                          onClick={() =>
                            updateSubAvailability({ subAvailability: flexFillInputVal })
                          }
                        />
                      </div>
                    </div>
                  )}
                </DataItemView>
              )}
            </>
          )}
          {!isDesktop &&
            (getFeatureFlags().FLEXIBLE_MD_FILL
              ? !request.isMultiDay || request.status !== "STATUS_OPEN"
              : true) && <div className={styles["divider-mobile"]} />}
          {request.payment && (
            <DataItemView label={isDesktop ? msg("LABEL_PAY") : ""} icon="Monetization">
              <div className={styles["pay-row"]} onClick={!isDesktop ? openPayModal : undefined}>
                <IonText>
                  {request.payment?.payValue
                    ? formatPay(request.payment.isHourly, request.payment.payValue)
                    : msg("UNPROVIDED_DATA_MESSAGE")}
                </IonText>
                {!isDesktop && <Icon name="Chevron Right" />}
              </div>
              {isDesktop && (
                <IonText
                  style={{ color: Colors.blue500, cursor: "pointer" }}
                  onClick={openPayModal}
                >
                  {msg("PAY_BREAKDOWN_LABEL")}
                </IonText>
              )}
            </DataItemView>
          )}
          {request.payment && !isDesktop && <div className={styles["divider-mobile"]}></div>}
          {request.hasFeedback && (
            <>
              <DataItemView
                label={isDesktop ? msg("SCHOOL_FEEDBACK_REVIEWED_LABEL") : ""}
                icon="Check Circle"
                iconColor={Colors.green500}
              >
                {isDesktop ? (
                  <IonText
                    style={{ color: Colors.blue500, cursor: "pointer" }}
                    onClick={openReviewedFeedbackModal}
                  >
                    {msg("SCHOOL_FEEDBACK_VIEW_REVIEW_LABEL")}
                  </IonText>
                ) : (
                  <div className={styles["pay-row"]} onClick={openReviewedFeedbackModal}>
                    <span>{msg("SCHOOL_FEEDBACK_REVIEWED_LABEL")}</span>
                    <Icon name="Chevron Right" />{" "}
                  </div>
                )}
              </DataItemView>
              {!isDesktop && <div className={styles["divider-mobile"]}></div>}
            </>
          )}
          <DataItemView label={isDesktop ? msg("LABEL_ADDRESS") : ""} icon="Location">
            {request.school.address ? (
              <a
                style={{ display: "flex", flexDirection: "column" }}
                target="_blank"
                rel="noreferrer"
                href={googleMapsLink}
                onClick={(e) =>
                  handleMapLinkNavigation(e, {
                    getPlatform: Capacitor.getPlatform,
                    appLauncher: AppLauncher,
                    actionSheet: ActionSheet,
                    openLinkInApp: openLinkInApp,
                    query: encodeURIComponent(
                      `${schoolAddress.street} ${schoolAddress.city}, ${schoolAddress.state}, ${schoolAddress.zip}`,
                    ),
                    link: googleMapsLink,
                  })
                }
              >
                {request.school.address.street && (
                  <IonText className={styles["truncate-one-line"]}>
                    {request.school.address.street}
                  </IonText>
                )}
                <IonText className={styles["truncate-one-line"]}>
                  {formatCityStateZip(request.school.address)}
                </IonText>
              </a>
            ) : (
              <IonText>{msg("UNPROVIDED_DATA_MESSAGE")}</IonText>
            )}
          </DataItemView>
          <DataItemView label={msg("LABEL_CONTACT")} icon="Phone">
            {request.contact ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <IonText>
                  {request.contact.name} -{" "}
                  <a href={`tel:${request.contact.phoneNumber}`}>{request.contact.phoneNumber}</a>
                </IonText>
                <IonText>
                  <a href={`mailto:${request.contact.email}`}>{request.contact.email}</a>
                </IonText>
              </div>
            ) : (
              <IonText>{msg("UNPROVIDED_DATA_MESSAGE")}</IonText>
            )}
          </DataItemView>
          <DataItemView label={msg("LABEL_GRADE")} icon="Grad Cap">
            <IonText>
              {request.grades ? formatStringArray(request.grades) : msg("UNPROVIDED_DATA_MESSAGE")}
            </IonText>
          </DataItemView>
          <DataItemView label={msg("LABEL_SUBJECT")} icon="Book">
            <IonText>
              {request.subjects ? (
                <>
                  {formatStringArray(request.subjects)}
                  {specialEdTaskUrl && (
                    <p style={{ margin: 0 }}>
                      {msg("REQUEST_CARD_DETAIL_SPED_SKILL_BUILDER_PREP")}{" "}
                      <Link to={specialEdTaskUrl}>
                        {msg("REQUEST_CARD_DETAIL_SPED_SKILL_BUILDER_PREP_HYPERLINK")}
                      </Link>
                      .
                    </p>
                  )}
                </>
              ) : (
                msg("UNPROVIDED_DATA_MESSAGE")
              )}
            </IonText>
          </DataItemView>
          <SimpleDataDisplay label={msg("LABEL_TEACHER")} icon="Teacher" data={request.teacher} />
          <DataItemView label={msg("LABEL_LESSON_PLAN")} icon="Clipboard">
            <IonText>
              {request.isLessonPlanProvided ? (
                msg("LESSON_PLAN_PROVIDED")
              ) : (
                <p
                  style={{ margin: 0 }}
                  dangerouslySetInnerHTML={{
                    __html: msg("LESSON_PLAN_NOT_PROVIDED_SUB_PLAN_AI"),
                  }}
                />
              )}
            </IonText>
          </DataItemView>
          <SimpleDataDisplay label={msg("LABEL_NOTES")} icon="Note" data={request.memo} />
          <CancelPolicyButton onClick={openCancelPolicyModal} />
        </div>
      </div>
    </div>
  );
}

/********* Helper Components ********/
type SimpleDisplayDataProps = {
  label: string;
  icon: IconName;
  data: string | number | undefined;
};

function SimpleDataDisplay(props: SimpleDisplayDataProps) {
  const { label, icon, data } = props;

  return (
    <DataItemView label={label} icon={icon}>
      <IonText>{data ? `${data}` : msg("UNPROVIDED_DATA_MESSAGE")}</IonText>
    </DataItemView>
  );
}
type CancelPolicyButtonProps = { onClick: () => void };

export function CancelPolicyButton(props: CancelPolicyButtonProps) {
  const { onClick } = props;

  return (
    <div
      onClick={onClick}
      style={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        backgroundColor: Colors.white300,
        border: "solid",
        borderColor: Colors.slate300,
        borderRadius: 4,
        borderWidth: 1,
        padding: 8,
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Icon name="Block" />
        <div style={{ display: "flex", flexDirection: "column", marginLeft: 8 }}>
          <IonText className={styles["label"]}>{msg("CANCEL_POLICY_LABEL")}</IonText>
          <IonText>{msg("CANCEL_POLICY_BUTTON_SUBHEADING")}</IonText>
        </div>
      </div>
      <Icon name="Chevron Right" />
    </div>
  );
}
/********* Helper Functions ********/
export function getSpecialEdTaskUrl(config: SkillBuilderConfiguration = SwingConfiguration) {
  const specialEdGroup = config.groups.find(
    (group) => group.name === "Special Education Classrooms",
  );

  if (!specialEdGroup) return;

  const firstTaskIndex = config.groups
    .flatMap((group) => group.tasks)
    .findIndex((task) => task === specialEdGroup.tasks[0]);

  return firstTaskIndex !== -1
    ? `${createSkillBuilderTaskURL(config.version, firstTaskIndex)}`
    : undefined;
}

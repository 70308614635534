import type { Task } from "~skillBuilder/utils/types";
import { image, title } from "../../contentBlocks";

export const CmSkillCheck: Task = {
  name: "Skill Check",
  uniqueName: "cm-skill-check",
  icon: "BookmarkCheck",
  durationTimeText: "2 min",
  isQuiz: true,
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      prevButtonText: "Review",
      showPreviousButton: true,
      questions: [
        // Question 1.1
        {
          attribute: "classroom-management.skill-check/intro",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "Skill Check", durationTimeText: "2 min" })}
<p>Show what you've learned! Complete this quiz to earn a qualification for your profile.</p>
<div style="text-align:center;max-width:250px;width:100%;margin-top:24px;margin-left:auto;margin-right:auto;"/>
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/v1731113489/skillbuilderAssets/optimized/woman-and-clipboard.png", alt: "illustrated woman standing in front of a checked clipboard" })}
</div>             `,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "classroom-management.skill-check/not-start-day",
          label: "What is one way you should NOT start off a new day at a school?",
          answerType: {
            type: "RADIO",
            options: [
              { label: "Introduce yourself", value: "invalid-1", isCorrect: false },
              { label: "Set expectations", value: "invalid-2", isCorrect: false },
              { label: "Hug each student", value: "valid", isCorrect: true },
              { label: "Start a do-now activity", value: "invalid-3", isCorrect: false },
            ],
          },
        },
      ],
    },
    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "classroom-management.skill-check/do-now-activity",
          label: `What is a "Do Now" activity?`,
          answerType: {
            type: "RADIO",
            options: [
              {
                label: "An activity that students work on at the end of class Radio description",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label: "An activity that students work on as soon as they come into the room",
                value: "valid",
                isCorrect: true,
              },
              {
                label: "An activity that substitutes work on during class",
                value: "invalid-2",
                isCorrect: false,
              },
              {
                label: "An activity that teachers assign as homework",
                value: "invalid-3",
                isCorrect: false,
              },
            ],
          },
        },
      ],
    },
    // Step 4
    {
      questions: [
        // Question 4.1
        {
          attribute: "classroom-management.skill-check/first-disruptive-student",
          label: "What is the best way to handle a student being disruptive (the first time)?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label: "Take away their recess",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label: "Immediately send them to the office",
                value: "invalid-2",
                isCorrect: false,
              },
              {
                label: "Yell at them",
                value: "invalid-3",
                isCorrect: false,
              },
              {
                label:
                  "Speak individually with the student and call out the behavior, reminding them of expectations and then walking away",
                value: "valid",
                isCorrect: true,
              },
            ],
          },
        },
      ],
    },
    // Step 5
    {
      questions: [
        // Question 5.1
        {
          attribute: "classroom-management.skill-check/why-management",
          label: "Why is classroom management important for substitute teachers?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label: "It helps teachers plan their lessons",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label: "It ensures students experience as little learning distractions as possible",
                value: "valid",
                isCorrect: true,
              },
              {
                label: "It makes the teacher's job easier",
                value: "invalid-2",
                isCorrect: false,
              },
              {
                label: "It allows substitutes to leave early",
                value: "invalid-3",
                isCorrect: false,
              },
            ],
          },
        },
      ],
    },
    // Step 6
    {
      nextButtonText: "Great!",
      questions: [
        // Question 6.1
        {
          attribute: "classroom-management.skill-check/outro",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div style="text-align: center;">
  ${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1731608485/skillbuilderAssets/optimized/skill_builder_complete.jpg", alt: "illustrated man holding trophy standing next to a woman" })}
  <p><strong>You've completed this skill!</strong></p>
  <p>Thank you for your commitment to developing your teaching skills. You'll now see <strong>Classroom Management</strong> as a qualification on your profile.</p>
</div>
`,
          },
        },
      ],
    },
  ],
};

import { j as t, a as n, F as I } from "../../../emotion-react-jsx-runtime.browser.esm-CDWB6Ors.js";
import { I as B, g as C } from "../../../Icon-C7c5yX_u.js";
import { IonButton as h, IonText as L, IonBackdrop as k } from "@ionic/react";
import { useState as s, useLayoutEffect as M } from "react";
import { Colors as D } from "../../foundations/Colors/Colors.js";
import { u as P, E as V, e as W, k as Y } from "../../../AuthProvider-DparLqAm.js";
import l from "../../string-table.js";
import "../../../react-paginate-CpOsCkIq.js";
import { LoadingInterstitial as j } from "../LoadingInterstitial/LoadingInterstitial.js";
import '../../../css/Login.css';const F = "_form_wmum0_1", z = "_divider_wmum0_5", H = "_emailInput_wmum0_27", U = "_loadingContainer_wmum0_31", q = "_loginButton_wmum0_48", J = "_buttonText_wmum0_56", K = "_googleIcon_wmum0_61", e = {
  form: F,
  divider: z,
  emailInput: H,
  loadingContainer: U,
  loginButton: q,
  buttonText: J,
  googleIcon: K
};
function so(i) {
  const {
    loginWithPasswordPath: r,
    history: N,
    onLoginEmailSubmit: b,
    buildProfile: d,
    apiError: m,
    isWeb: w,
    build: O,
    version: v
  } = i, {
    signIn: _,
    isAuthenticating: G
  } = P(), [c, g] = s(""), [x, a] = s(), [A, f] = s(!1), [E, p] = s(m);
  M(() => {
    p(m);
  }, [m]);
  const T = (o) => {
    g(o), X(o) ? f(!0) : f(!1);
  }, y = async () => {
    const o = await b(c).catch((u) => a(u));
    if (o)
      switch (o) {
        case "COGNITO":
          a(void 0), N.push({
            pathname: r,
            // Explicitly not using `useLocation` as we want to live value vs
            // cached value.
            search: window.location.search,
            state: {
              email: c
            }
          });
          break;
        case "GOOGLE":
          a(void 0), _({
            provider: o.toLowerCase()
          }).catch((u) => {
            a(u);
          });
          break;
      }
  };
  async function S(o) {
    o.preventDefault(), p(void 0), await y();
  }
  async function R(o) {
    o.preventDefault(), a(void 0), g(""), await _({
      provider: "google"
    });
  }
  return /* @__PURE__ */ t(Y, {
    isWeb: w,
    title: l("LOGIN_EMAIL_ENTRY_LOGIN_MESSAGE"),
    build: O,
    version: v,
    children: G ? /* @__PURE__ */ n(I, {
      children: [/* @__PURE__ */ t(k, {
        visible: !0
      }), /* @__PURE__ */ t("div", {
        className: e.loadingContainer,
        children: /* @__PURE__ */ t(j, {})
      })]
    }) : /* @__PURE__ */ n(I, {
      children: [/* @__PURE__ */ n("form", {
        className: e.form,
        onSubmit: async (o) => await S(o),
        children: [/* @__PURE__ */ t("div", {
          className: e.emailInput,
          children: /* @__PURE__ */ t(V, {
            email: c,
            onEmailInput: (o) => T(o),
            readOnly: !1,
            error: x,
            provider: "cognito",
            buildProfile: d
          })
        }), /* @__PURE__ */ n(h, {
          type: "submit",
          size: "default",
          expand: "block",
          className: e.loginButton,
          disabled: !A,
          children: [/* @__PURE__ */ t(L, {
            className: e.buttonText,
            children: l("LOGIN_EMAIL_ENTRY_LOGIN_LABEL_ENTER_PASSWORD")
          }), /* @__PURE__ */ t(B, {
            name: "Arrow Right",
            color: D.white200
          })]
        })]
      }), /* @__PURE__ */ t(Q, {
        label: l("LOGIN_EMAIL_ENTRY_LABEL_OR")
      }), /* @__PURE__ */ n("form", {
        className: e.form,
        onSubmit: async (o) => await R(o),
        children: [/* @__PURE__ */ n(h, {
          type: "submit",
          size: "default",
          fill: "outline",
          expand: "block",
          className: e.loginButton,
          children: [/* @__PURE__ */ t("img", {
            src: C,
            className: e.googleIcon,
            alt: "Login with Google"
          }), /* @__PURE__ */ t(L, {
            className: e.buttonText,
            children: l("LOGIN_EMAIL_ENTRY_LOGIN_LABEL_CONTINUE_WITH_GOOGLE")
          })]
        }), E && /* @__PURE__ */ t(W, {
          buildProfile: d,
          error: E
        })]
      })]
    })
  });
}
function Q(i) {
  const {
    label: r
  } = i;
  return /* @__PURE__ */ t("div", {
    className: e.divider,
    children: r
  });
}
function X(i) {
  return new RegExp(/.+@.+\..{2,}/).test(i);
}
export {
  so as Login,
  X as isValidEmail
};

import type { Task } from "~skillBuilder/utils/types";
import { image, title } from "../../contentBlocks";

export const CEGradeLevels: Task = {
  name: "Adapting to Different Grade Levels",
  uniqueName: "ce-grade-levels",
  icon: "Lesson",
  durationTimeText: "15 min",
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      questions: [
        // Question 1.1
        {
          attribute: "classroom-engagement.grade-levels/overview",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "Adapting to Different Grade Levels", durationTimeText: "15 min" })}
<p>Learn classroom engagement strategies for different grade levels.</p>
<div align="center">
<div style="max-width:300px;width:100%;">
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1743476768/skillbuilderAssets/optimized/CE-grades.jpg", alt: "Illustration of a science teacher doing an experiement with students." })}
</div>
`,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "classroom-engagement.grade-levels/vary",
          label: "Strategies vary by grade level",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
As a substitute, you'll navigate a wide range of classrooms, each with its own unique dynamics. Strategies that work wonders in an elementary setting may fall flat in a high school classroom, and vice versa.

Adapting your approach to fit the needs of different grade levels leads to better engagement, fewer disruptions, and a learning environment where students of any age feel comfortable, supported, and ready to succeed. We’ll cover strategies for how to adapt to different grade levels based on advice from schools and educators.
`,
          },
        },
      ],
    },
    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "classroom-engagement.grade-levels/needs",
          label: "Understanding student needs by age group",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Different age groups have unique needs that require tailored approaches.

🍎 **Elementary:** Young learners have shorter attention spans and thrive with interactive, hands-on activities. Keeping lessons engaging and incorporating movement will help maintain their focus.

✏️ **Middle School:** These students are more likely to test boundaries and experience peer conflicts. Structured transitions and minimizing downtime can help maintain order and keep them on track.

📚 **High School:** Older students often struggle with distractions, especially from technology, and may be prone to procrastination. Strategies that promote real-world connections and active participation can keep them engaged and accountable.
`,
          },
        },
      ],
    },
    // Step 4
    {
      questions: [
        // Question 4.1
        {
          attribute: "classroom-engagement.grade-levels/engagement",
          label: "Promoting engagement at every grade level",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
To foster student engagement, strategies must be tailored to the developmental needs of each grade level.

🍎 **Elementary:** Young learners respond best to positive reinforcement. Encouraging their efforts with praise, rewards, or interactive activities keeps them motivated and engaged.
- Songs, movement-based activities, and storytelling
- Young children learn best through play, interaction, and sensory experiences. Engaging them with hands-on activities helps reinforce learning in a fun and memorable way.

✏️ **Middle School:** A balance of structure and independence is key. Offering choices in their learning process helps them feel empowered while maintaining classroom order.
- Interactive discussions, small group work, lesson-based games, and technology integration
- Middle schoolers thrive on social interaction and independence. These strategies keep them engaged while catering to their developmental needs.

📚 **High School:** Older students engage more when they see real-world relevance in their lessons. Making connections to practical applications and future opportunities enhances their interest and participation.
- Debate-style discussions, student-led learning, real-world applications, and digital tools
- High school students are motivated by independence, critical thinking, and real-world connections. Providing opportunities for deeper discussion and practical application makes learning more meaningful.
`,
          },
        },
      ],
    },
    // Step 5
    {
      questions: [
        // Question 5.1
        {
          attribute: "classroom-engagement.grade-levels/approach",
          label: "Adapting your approach",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
When working with different age groups, it's important to adjust your teaching style to meet students' needs.

🍎 **Elementary:** Younger students require more scaffolding and hands-on guidance throughout the lesson. Providing clear instructions, modeling tasks, and offering step-by-step support will help keep them engaged and on track.

✏️ **Middle School:** As students transition between needing guidance and seeking independence, a balanced approach works best. Observing their responses will help you adjust your level of support to fit their needs.

📚 **High School:** Older students benefit from a more independent learning approach. While they may occasionally need assistance, encouraging autonomy and critical thinking fosters engagement and responsibility for their learning.
`,
          },
        },
      ],
    },
    // Step 6
    {
      nextButtonText: "Next Lesson",
      questions: [
        // Question 6.1
        {
          attribute: "classroom-engagement.grade-levels/transitions",
          label: "Smooth transitions",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Smooth transitions are essential for maintaining classroom flow and minimizing disruptions.

🍎 **Elementary:** Sensory-based transitions help younger students shift focus seamlessly. Try playing a short song, using a countdown, or engaging them with a fun call-and-response.
- Teacher: “1, 2, 3, eyes on me!”
- Students: “1, 2, eyes on you!”

✏️ **Middle School:** Movement-based transitions work best for this age group. Quick energizers like “Simon Says” or a simple stretch break help re-engage students and combat sluggishness between tasks.

📚 **High School:** Timed transitions provide structure while allowing for independence. Setting a countdown timer keeps students accountable, helping them manage their time effectively while maintaining focus.

These transition strategies keep students engaged, focused, and prepared for the next task, contributing to an efficient learning environment.
`,
          },
        },
      ],
    },
  ],
};

import type { Task } from "~skillBuilder/utils/types";
import { image, tip, title } from "../../contentBlocks";

export const CmBestPractices: Task = {
  name: "Best Practices",
  uniqueName: "cm-best-practices",
  icon: "Lesson",
  durationTimeText: "2 min",
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      questions: [
        // Question 1.1
        {
          attribute: "classroom-management.best-practices/intro",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "Best Practices", durationTimeText: "2 min" })}
<p>Understand the best practices to effectively manage a classroom.</p>
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/v1731113489/skillbuilderAssets/optimized/man-thumbs-up.png", alt: "illustrated man sat at desk with his thumb up" })}
            `,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "classroom-management.best-practices/find-support",
          label: "Be prepared and present",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <ul style="display: flex; flex-direction: column; gap: 24px; margin: 0 0 0 16px; padding: 0;">
    <li><strong>Know where to find support.</strong> Make sure you know who to contact if you need assistance in the classroom.</li>
    <li><strong>Be an active presence.</strong> Move around the classroom while students work, interact with them, and scan to ensure everyone stays on task.</li>
    <li style="list-style-type: none;">${tip({ text: "Learn and use students’ names when giving directions." })}</li>
  </ul>
</div>
            `,
          },
        },
      ],
    },

    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "classroom-management.best-practices/reinforce-behavior",
          label: "Communicating with students",
          // description: "",
          answerType: {
            value: "valid",
            type: "CONTENT",
            markdown: `
<div>
  <ul style="display: flex; flex-direction: column; gap: 24px; margin: 0 0 0 16px; padding: 0;">
    <li><strong>Reinforce positive behaviors.</strong> Verbally acknowledge the class and individual students for following directions. This gives you an opportunity to repeat the expectations and draws attention towards on task behaviors.</li>
    <li><strong>Provide clear directions.</strong>  When possible, share simple and clear directions both verbally and in writing.</li>
  </ul>
</div>
            `,
          },
        },
      ],
    },
    // Step 4
    {
      questions: [
        // Question 4.1
        {
          attribute: "classroom-management.best-practices/engage-class",
          label: "Keep the class engaged",
          // description: "",
          answerType: {
            value: "valid",
            type: "CONTENT",
            markdown: `
<div>
  <ul style="display: flex; flex-direction: column; gap: 24px; margin: 0 0 0 16px; padding: 0;">
    <li>Give students opportunities to participate in discussions.</li>
    <li>
      Here are some common ways to get student participation:
      <ul style="display: flex; flex-direction: column; gap: 24px; margin: 24px 0 0 16px; padding: 0;">
        <li>Ask questions and call on individual students.</li>
        <li>Have students discuss in small groups.</li>
        <li>Allow students to share their opinions non-verbally by raising their hands or using some other hand signal.</li>
      </ul>
    </li>
  </ul>
</div>
            `,
          },
        },
      ],
    },
    // Step 5
    {
      nextButtonText: "Next Lesson",
      questions: [
        // Question 5.1
        {
          attribute: "classroom-management.best-practices/dismissal-time",
          label: "Follow school policies and procedures",
          // description: "",
          answerType: {
            value: "valid",
            type: "CONTENT",
            markdown: `
<div>
  <ul style="display: flex; flex-direction: column; gap: 24px; margin: 0 0 0 16px; padding: 0;">
    <li><strong>Know the dismissal procedure.</strong> You want to ensure all students are where they need to be at dismissal time and what expectations the school has of you regarding dismissal procedures and duties.</li>
    <li><strong>Be prepared.</strong> Most teachers will leave a lesson plan for you, but sometimes emergencies happen and one isn't available. Be ready to find activities to do with students if materials are not provided. You can look resources up online or ask students what they've been learning recently.</li>
  </ul>
</div>
            `,
          },
        },
      ],
    },
  ],
};

import { a as b, j as i } from "../../../emotion-react-jsx-runtime.browser.esm-CDWB6Ors.js";
import { Colors as o } from "../../foundations/Colors/Colors.js";
import { I as m } from "../../../Icon-C7c5yX_u.js";
import "../../../AuthProvider-DparLqAm.js";
import "@ionic/react";
import "react";
import "../../../react-paginate-CpOsCkIq.js";
import "@capacitor/core";
const a = {
  container: ({
    isActive: r,
    isDisabled: t,
    activeBgColor: n,
    iconColor: e
  }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 6,
    background: r ? o[n] : "transparent",
    padding: "16px 8px",
    cursor: t ? "not-allowed" : "pointer",
    border: `2px solid ${t ? o.slate300 : r ? e === "black" ? o.blue500 : o[e] : o.black}`,
    borderRadius: 8,
    width: "100%"
  }),
  label: ({
    isDisabled: r
  }) => ({
    margin: 0,
    fontSize: 14,
    lineHeight: "24px",
    textAlign: "center",
    color: r ? o.slate300 : o.black
  })
};
function I(r) {
  const {
    icon: t,
    isDisabled: n,
    isSelected: e,
    label: l,
    onChange: c = () => {
    },
    value: s,
    activeBgColor: p = "white300"
  } = r;
  function d() {
    return n ? e ? o.black : o.slate300 : e && t.color === "black" ? o.blue500 : o[t.color];
  }
  return /* @__PURE__ */ b("button", {
    "data-testid": `choice-button-${l}`,
    style: a.container({
      isActive: e,
      isDisabled: n,
      activeBgColor: p,
      iconColor: t.color
    }),
    "aria-checked": e,
    tabIndex: 0,
    onClick: () => {
      !n && !e && c(s);
    },
    children: [/* @__PURE__ */ i(m, {
      name: t.name,
      color: d()
    }), /* @__PURE__ */ i("p", {
      style: a.label({
        isDisabled: n
      }),
      children: l
    })]
  });
}
export {
  I as ChoiceButton
};

import type { Task } from "~skillBuilder/utils/types";
import { image, tip, title } from "../../contentBlocks";

export const CEWarmup: Task = {
  name: "Bell Ringers and Warm-Ups",
  uniqueName: "ce-warmup",
  icon: "Lesson",
  durationTimeText: "15 min",
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      questions: [
        // Question 1.1
        {
          attribute: "classroom-engagement.warmup/overview",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "Bell Ringers and Warm-Ups", durationTimeText: "15 min" })}
<p>Hook them from the start! A strong start sets the stage for a successful class. Engaging bell ringers and warm-ups capture students’ attention, spark curiosity, and activate prior knowledge—helping them transition into learning mode while staying aligned with the teacher’s lesson plan.</p>
<div align="center">
<div style="max-width:200px;width:100%;">
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1743476770/skillbuilderAssets/optimized/CE-warmup.jpg", alt: "Illustration of woman standing in front of a many raised hands." })}
</div>
`,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "classroom-engagement.warmup/lesson-plan",
          label: "Review the lesson plan first",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
The strategies we will cover are popular among educators because starting strong is a great way to increase student engagement. However, always follow a school’s provided lesson plan, if provided.

${tip({ text: "Take a moment to review the lesson plan to ensure all activities fit seamlessly into the day’s objectives." })}
`,
          },
        },
      ],
    },
    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "classroom-engagement.warmup/writing",
          label: "Writing tools",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Kick off the class with quick-writes and reflections! These activities encourage students to engage with prior knowledge, think critically, and set the stage for meaningful discussions.

**Engaging Strategies**
- **One-Minute Write**: Challenge students to quickly capture their thoughts on a lesson-related topic in just one minute! This fast-paced activity encourages focus, critical thinking, and immediate engagement.
- **3-2-1 Reflection**: Have students reflect by writing 3 things they learned yesterday, 2 questions they have, and 1 interesting takeaway. Take it a step further by inviting students to share their questions aloud, sparking a mini-discussion that builds curiosity and deepens understanding.

${tip({ text: "Set clear expectations by writing directions on the board, so students know exactly what to do as soon as they arrive. This helps students settle in quickly, fosters independence, and minimizes downtime." })}

`,
          },
        },
      ],
    },
    // Step 4
    {
      questions: [
        // Question 4.1
        {
          attribute: "classroom-engagement.warmup/collaboration",
          label: "Encourage collaboration",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Kickstart engagement by allowing students to collaborate before diving into the lesson. Working together generates a lot of energy around learning!

**Engaging Strategies**
- **Think-Pair-Share**: Pose a lesson-related question, have students reflect individually, discuss with a partner, and then share with the class.
- **Make a Choice**: Present two related options and have students pick a stance and justify their answer.

**Example:** In a 6th-grade language arts class reading a novel, ask: *"Would you get more details from a movie adaptation, or does the novel provide richer details? Why?"*

`,
          },
        },
      ],
    },
    // Step 5
    {
      questions: [
        // Question 5.1
        {
          attribute: "classroom-engagement.warmup/brain-teaser",
          label: "Brain teasers",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Get students’ brains warmed up with a fun challenge!

**Engaging Strategies**
- **One Word Clue**: Give students a vocabulary word or concept and have them describe it using only one word—classmates then guess the original word.
- **Riddle Me This**: Use a subject-related riddle to get students thinking. 
- **Group Puzzle Solve**: Provide a logic puzzle or brain teaser that students must work together to solve within a time limit.

${tip({ text: "Turn it into a friendly competition—sometimes, simple bragging rights are all the motivation students need!" })}

`,
          },
        },
      ],
    },
    // Step 6
    {
      questions: [
        // Question 6.1
        {
          attribute: "classroom-engagement.warmup/tech",
          label: "Enhancing lessons with technology",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
⭐️ *Great for long-term requests*

Incorporating **admin-approved** images and educational videos into your lesson adds a dynamic, multimedia element that **engages students and deepens their understanding**. Visuals help students make meaningful connections to the topic, encouraging critical thinking and discussion.

**Engaging Strategies**
- **What’s Going On?**: Display an image or a short educational video and have students predict, infer, or analyze how it relates to the lesson. This promotes curiosity and discussion.
- **Caption This!**: Show an image connected to the topic and challenge students to create a caption that summarizes or explains the concept in a creative way.

${tip({ text: "Use these strategies as warm-ups or discussion starters to spark engagement before diving into the lesson." })}

`,
          },
        },
      ],
    },
    // Step 7
    {
      questions: [
        // Question 7.1
        {
          attribute: "classroom-engagement.warmup/movement",
          label: "Boost focus with movement",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
⭐️ *Great for long-term requests*

Incorporating movement into the classroom is a simple yet effective way to **enhance focus, increase alertness, and improve cognitive function**—all while making learning more engaging! Getting students up and moving **immediately captures their attention** and sets a positive tone for the lesson.

**Engaging Strategies**
- **Stand Up, Sit Down**: Pose a question with two possible answers. Students stand for one choice and sit for the other, keeping them physically engaged while thinking critically.
- **Four Corners**: Label each corner of the classroom with multiple-choice answers. Students move to the corner that represents their answer, encouraging participation and discussion.

${tip({ text: "These strategies work great as icebreakers, review activities, or quick brain breaks to re-energize the class!" })}
`,
          },
        },
      ],
    },
    // Step 8
    {
      questions: [
        // Question 8.1
        {
          attribute: "classroom-engagement.warmup/quick-review",
          label: "Kickstart learning with a quick review",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
⭐️ *Great for long-term requests*

Engage students as they enter the classroom by testing their recall of previous lessons. This reinforces learning, builds confidence, and helps them make connections to new material.

**Engaging Strategies**
- **Quick Quiz**: A short, low-stakes quiz to review key concepts from the previous lesson.
- **Word Map**: Write a key term on the board and have students brainstorm related words or concepts, creating a visual web of connections.
- **Headline News**: Challenge students to summarize what they learned last class in a one-sentence “breaking news” headline.

${tip({ text: "These activities are a great way to assess understanding while keeping students engaged from the moment they walk in!" })}
`,
          },
        },
      ],
    },
    // Step 9
    {
      nextButtonText: "Next Lesson",
      questions: [
        // Question 9.1
        {
          attribute: "classroom-engagement.warmup/creative",
          label: "Encourage creative thinking",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Unlock student creativity while reinforcing key concepts in a fun and engaging way.

**Engaging Strategies**
- **Sketch It!**: Have students quickly sketch a visual representation of a vocabulary word or concept. Then, challenge their classmates to guess what it represents!

${tip({ text: "This activity is a great way to engage visual learners and spark discussion while making learning more interactive." })}
`,
          },
        },
      ],
    },
  ],
};

import { IonPage, IonRouterOutlet } from "@ionic/react";
import { Redirect, Route, Switch } from "react-router";
import { getFeatureFlags } from "src/utils/feature-flags";

import { useAuth } from "~components";
import {
  AUTH_URLS,
  createMoreUrl,
  createRequestsUrl,
  EducationLanding,
  EducationNewEdit,
  Help,
  LocationSettings,
  More,
  MyDocuments,
  MyEarnings,
  MyRequests,
  Notifications,
  Payment,
  PaymentSetup,
  Profile,
  Referral,
  RequestDetails,
  Requests,
  SchoolProfile,
  SkillBuilderTasksPage,
  UNAUTHENTICATED_ROUTES,
  WorkExperienceLanding,
  WorkExperienceNewEdit,
} from "~pages";
import { ValuesOf } from "~utils";
import { SkillBuilderTaskPage } from "../more/skillBuilder/[version]/[task]/[step]";
import { useRegisterPushNotifications } from "./hooks/useRegisterPushNotifications";

type PostOnboardingProps = {
  /**
   * Used to expose path only for testing routes
   * TODO: @KoltonG - Wondering if one day we can get rid of this :P
   */
  testPath?: ValuesOf<typeof AUTH_URLS>;
};

export function PostOnboarding(props: PostOnboardingProps) {
  const { testPath } = props;

  /***** Hooks *****/
  const { userInfo } = useAuth();
  useRegisterPushNotifications();

  const redirectRouteParams = getRedirectParams();

  return (
    <IonPage>
      <IonRouterOutlet animated={false}>
        {/*
          using children approach based on 5.1 + version of react router
          https://reactrouter.com/en/main/upgrading/v5#upgrade-to-react-router-v51
        */}
        <Switch>
          <Route exact path={AUTH_URLS.requests}>
            <Requests />
          </Route>
          <Route exact path={AUTH_URLS.request}>
            <RequestDetails isTest={!!testPath} />
          </Route>
          <Route exact path={AUTH_URLS.myRequests}>
            <MyRequests isTest={!!testPath} />
          </Route>
          <Route exact path={AUTH_URLS.myRequestsDetails}>
            <RequestDetails isTest={!!testPath} />
          </Route>
          <Route exact path={AUTH_URLS.profile}>
            <Profile />
          </Route>
          <Route exact path={AUTH_URLS.more}>
            <More />
          </Route>
          <Route exact path={AUTH_URLS.notifications}>
            <Notifications />
          </Route>
          {/* not accessible for w2 subs or district only subs */}
          <Route exact path={AUTH_URLS.payment}>
            {userInfo.isHourly || userInfo.restrictions?.includes("RESTRICTION_CANNOT_VIEW_PAY") ? (
              <Redirect exact path={AUTH_URLS.more} to={createMoreUrl()} />
            ) : (
              <Payment />
            )}
          </Route>
          {/* not accessible for w2 subs or district only subs */}
          <Route exact path={AUTH_URLS.paymentSetup}>
            {userInfo.isHourly || userInfo.restrictions?.includes("RESTRICTION_CANNOT_VIEW_PAY") ? (
              <Redirect exact path={AUTH_URLS.more} to={createMoreUrl()} />
            ) : (
              <PaymentSetup />
            )}
          </Route>
          {/* not accessible for w2 subs or district only subs */}
          <Route exact path={AUTH_URLS.myEarnings}>
            {userInfo.isHourly || userInfo.restrictions?.includes("RESTRICTION_CANNOT_VIEW_PAY") ? (
              <Redirect exact path={AUTH_URLS.more} to={createMoreUrl()} />
            ) : (
              <MyEarnings />
            )}
          </Route>
          <Route exact path={AUTH_URLS.help}>
            <Help />
          </Route>
          {/* not accessible for district only subs */}
          <Route exact path={AUTH_URLS.locationSettings}>
            {userInfo.restrictions?.includes("RESTRICTION_CANNOT_VIEW_LOCATIONS") ? (
              <Redirect exact path={AUTH_URLS.more} to={createMoreUrl()} />
            ) : (
              <LocationSettings />
            )}
          </Route>
          {/* not accessible for district only subs */}
          <Route exact path={AUTH_URLS.referralProgram}>
            {userInfo.restrictions?.includes("RESTRICTION_CANNOT_VIEW_REFERRAL") ? (
              <Redirect exact path={AUTH_URLS.more} to={createMoreUrl()} />
            ) : (
              <Referral />
            )}
          </Route>
          {/* TODO: remove feature flag once feature is ready to be released */}
          {/* not accessible for district only subs */}
          <Route exact path={AUTH_URLS.myDocuments}>
            {!getFeatureFlags().ISOLATING_LEGACY_WEB_PAGES ||
            userInfo.restrictions?.includes("RESTRICTION_CANNOT_VIEW_DOCUMENTS") ? (
              <Redirect exact path={AUTH_URLS.more} to={createMoreUrl()} />
            ) : (
              <MyDocuments />
            )}
          </Route>
          <Route
            exact
            path={AUTH_URLS.skillBuilderTasks}
            render={(routeProps) => <SkillBuilderTasksPage {...routeProps} />}
          />
          <Route
            exact
            path={AUTH_URLS.skillBuilderTask}
            render={(routeProps) => <SkillBuilderTaskPage {...routeProps} />}
          />
          <Route exact path={AUTH_URLS.educationLanding}>
            <EducationLanding />
          </Route>
          <Route exact path={AUTH_URLS.workExperienceLanding}>
            <WorkExperienceLanding />
          </Route>
          <Route exact path={AUTH_URLS.educationNew}>
            <EducationNewEdit />
          </Route>
          <Route exact path={AUTH_URLS.educationEdit}>
            <EducationNewEdit />
          </Route>
          <Route exact path={AUTH_URLS.workExperienceNew}>
            <WorkExperienceNewEdit />
          </Route>
          <Route exact path={AUTH_URLS.workExperienceEdit}>
            <WorkExperienceNewEdit />
          </Route>
          <Route exact path={AUTH_URLS.openingsSchoolProfile}>
            <SchoolProfile />
          </Route>
          <Route exact path={AUTH_URLS.myRequestsSchoolProfile}>
            <SchoolProfile />
          </Route>
          {redirectRouteParams && !testPath && (
            <Redirect
              exact
              path={UNAUTHENTICATED_ROUTES.loginWithPassword}
              to={redirectRouteParams}
            />
          )}
          {redirectRouteParams && !testPath && (
            <Redirect exact path={AUTH_URLS.root} to={redirectRouteParams} />
          )}

          {!testPath && <Redirect exact path={AUTH_URLS.root} to={createRequestsUrl()} />}
          {!testPath && (
            <Route
              render={() => {
                window.location.href = AUTH_URLS.root;
                return undefined;
              }}
            />
          )}
        </Switch>
      </IonRouterOutlet>
    </IonPage>
  );
}

export function getRedirectParams() {
  const url = new URL(window.location.href);
  const searchParams = url.searchParams;
  const maybeRoute = searchParams.get("redirectTo");
  return maybeRoute;
}

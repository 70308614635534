import type { Task } from "~skillBuilder/utils/types";
import { image, title } from "../../contentBlocks";

export const CERapport: Task = {
  name: "Building Instant Rapport",
  uniqueName: "ce-rapport",
  icon: "Lesson",
  durationTimeText: "10 min",
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      questions: [
        // Question 1.1
        {
          attribute: "classroom-engagement.rapport/overview",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "Building Instant Rapport", durationTimeText: "10 min" })}
<p>Building trust and establishing a positive connection will help reduce resistance, boost student participation, and foster a respectful learning environment where everyone feels valued.<br><br>
These strategies, recommended by schools, are key to creating that immediate connection that many substitutes strive to establish.
</p>
<div align="center">
<div style="max-width:250px;width:100%;">
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1743476742/skillbuilderAssets/raw/CE-rapport.jpg", alt: "Illustration of teacher standing in front of a school building with children." })}
</div>
`,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "classroom-engagement.rapport/start",
          label: "Start the day right",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
When you arrive:
- Review the lesson plan
- Read through any teacher notes
- Introduce yourself to nearby staff
- Greet students warmly as they enter

A simple smile and greeting can set a welcoming tone for the day!
`,
          },
        },
      ],
    },
    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "classroom-engagement.rapport/names",
          label: "Learn their names and use them",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Our names are so powerful! Calling students by their names builds rapport and encourages engagement. Keep an attendance sheet handy or have students create name tents to help you learn names quickly.

Addressing students personally fosters connection and respect—it shows you see them as individuals, even if they don’t always express their appreciation.
`,
          },
        },
      ],
    },
    // Step 4
    {
      questions: [
        // Question 4.1
        {
          attribute: "classroom-engagement.rapport/energy",
          label: "Showcase energy and enthusiasm",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Students can instantly tell if you’re interested in being there. Your attitude sets the tone for the day! When you bring positive energy, students are more likely to stay engaged and follow your lead.

A little enthusiasm goes a long way in shaping the classroom dynamic—for both the students and yourself.
`,
          },
        },
      ],
    },
    // Step 5
    {
      questions: [
        // Question 5.1
        {
          attribute: "classroom-engagement.rapport/effort",
          label: "Validate student effort",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Make a habit of recognizing student participation, effort, and positive behavior throughout the day. 

Use simple acknowledgments like:
<div style=padding: 16px>
💬 “Great thinking!”<br>
💬 “I appreciate your effort on this.”<br>
💬 “Thanks for sharing your idea!”<br></div>

These small moments make students feel valued, seen, and respected—even if they just met you.
`,
          },
        },
      ],
    },
    // Step 6
    {
      nextButtonText: "Next Lesson",
      questions: [
        // Question 6.1
        {
          attribute: "classroom-engagement.rapport/pma",
          label: "Positive mental attitude",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Students pick up on your emotions and attitude. If they sense you’re disengaged or frustrated, they’ll likely reflect that energy.

Even on challenging days, maintaining a steady, positive presence can shift the classroom dynamic. Confidence and enthusiasm—whether you feel it or not—can improve both your experience and the students’. When you lead with positivity, students are more likely to respond with engagement and cooperation.
`,
          },
        },
      ],
    },
  ],
};

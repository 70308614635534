import type { Task } from "~skillBuilder/utils/types";
import { doDont, image, tip, title } from "../../contentBlocks";

export const SECStrategies: Task = {
  name: "Strategies That Will Wow",
  uniqueName: "sec-strategies",
  icon: "Lesson",
  durationTimeText: "10 min",
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      questions: [
        // Question 1.1
        {
          attribute: "special-education-classrooms.strategies/overview",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "Strategies That Will Wow", durationTimeText: "10 min" })}
<p>Special education classrooms may require extra “oomph” and adaptability throughout the day. Having these strategies ready to go at a moment’s notice will benefit you, the students, and the educators you’re working with!</p>
<div align="center">
<div style="max-width:250px;width:100%;">
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1740759768/skillbuilderAssets/optimized/strategies.jpg", alt: "Illustration of teacher standing in front of a chalkboard teaching two students." })}
</div>
`,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "special-education-classrooms.strategies/visuals",
          label: "Visual supports",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Familiarize yourself with visual schedules, behavior charts, and other resources around the room. These tools help create structure and consistency for students—and make your day smoother.`,
          },
        },
      ],
    },
    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "special-education-classrooms.strategies/chunking",
          label: "Chunking tasks",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Breaking instructions into smaller, manageable steps helps prevent confusion and keeps students engaged.
${tip({ text: "When working one-on-one, sit with the student and read the instructions at their level for better understanding. Make sure you always maintain an appropriate physical distance from the student." })}
`,
          },
        },
      ],
    },

    // Step 4
    {
      questions: [
        // Question 4.1
        {
          attribute: "special-education-classrooms.strategies/cheerleader",
          label: "Be a cheerleader",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Special education classrooms thrive on celebrating milestones—big and small.

If a student stays engaged in a math activity for five minutes when they usually struggle to focus, that’s a win. For another, initiating eye contact or using a communication device independently is a breakthrough.

These moments might not seem huge on paper, but they represent significant progress. Your encouragement matters!
`,
          },
        },
      ],
    },
    // Step 5
    {
      questions: [
        // Question 5.1
        {
          attribute: "special-education-classrooms.strategies/do-dont",
          label: "Responding with a presence of mind",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
When unwanted behaviors occur, frustration is a natural instinct. However, your goal should be to respond calmly, not react emotionally. Keep these key Do’s and Don’ts in mind:
  ${doDont({ doText: "Do take a deep breath and remain calm when facing challenging behaviors. A neutral tone and body language can help prevent escalation.", dontText: "Don’t place your hands on a student to stop an unwanted behavior." })}<br>

  ${doDont({ doText: "Do model the behavior you want to see. Speak in a calm, low voice and maintain a supportive tone.", dontText: "Don’t raise your voice." })}<br>
Understand that behavior often has an underlying cause—whether it’s sensory overload, frustration, or another need. Work with other professionals at the school to address the root issue.
`,
          },
        },
      ],
    },
    // Step 6
    {
      questions: [
        // Question 6.1
        {
          attribute: "special-education-classrooms.strategies/support",
          label: "Know who to go to for support",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
If you have questions leading up to the request, reach out to your day-of contact. You can find their information on the request details page.

On the day of the request, before the student’s arrive, ask: **Who should I go to for support?**

Typically, your first points of contact are:
- The lead educator in the room
- The paraprofessional or instructional aide
- An administrator or designated staff member
- The day-of contact listed on the request details page

Knowing this upfront will give you confidence when handling unexpected situations.
`,
          },
        },
      ],
    },
    // Step 7
    {
      nextButtonText: "Next Lesson",
      questions: [
        // Question 7.1
        {
          attribute: "special-education-classrooms.strategies/overstimulation",
          label: "Know the signs of overstimulation",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Many Special education classrooms have a quiet or calming space for students who need breaks due to sensory overload or emotional distress.

Recognizing signs of overstimulation—such as **restlessness, irritability, difficulty focusing, crying out, or excessive movement**—allows you to act quickly and help students regulate their emotions.
  `,
          },
        },
      ],
    },
  ],
};

import { useState } from "react";

import {
  CheckboxScore,
  FeedbackReasons,
  ModalSubProfileEdit,
  ModalSubProfileEditBody,
  ModalSubProfileEditFooter,
  ModalSubProfileEditHeader,
  SchoolFeedbackForm,
  TextAreaInput,
  UpdatedReasons,
} from "~components";
import { msg } from "~utils";

type ModalContentSchoolFeedbackReasonsProps = {
  onNext: (updatedReasons: UpdatedReasons) => void;
  onDismiss: () => void;
  title: string;
  rating: SchoolFeedbackForm["experience"];
};

export function ModalContentSchoolFeedbackReasons(props: ModalContentSchoolFeedbackReasonsProps) {
  const { onNext, onDismiss, title, rating } = props;
  const [_updatedReasons, _setUpdatedReasons] = useState<UpdatedReasons>({
    reasons: [],
    otherReason: "",
  });

  const selectedOptions = Object.keys(FeedbackSelections) as FeedbackReasons[];

  function handleOnChange(option: FeedbackReasons, isChecked: boolean) {
    _setUpdatedReasons((prevFeedback) => {
      const updatedReasons = isChecked
        ? [...(prevFeedback.reasons ?? []), option]
        : prevFeedback.reasons?.filter((reason) => reason !== option);

      // If "Other" is unchecked, remove otherReason
      const updatedOtherReason =
        option === "OTHER" && !isChecked ? undefined : prevFeedback.otherReason;

      return {
        ...prevFeedback,
        reasons: updatedReasons,
        otherReason: updatedOtherReason,
      };
    });
  }

  function handleOtherReason(reason: string) {
    _setUpdatedReasons((prevFeedback) => ({
      ...prevFeedback,
      otherReason: reason,
    }));
  }

  function handleNext() {
    const updatedReasons: UpdatedReasons = {
      reasons: _updatedReasons.reasons ?? [],
      ...(!!_updatedReasons.otherReason?.trim() && {
        otherReason: _updatedReasons.otherReason.trim(),
      }),
    };
    onNext(updatedReasons);
  }

  const isNextDisabled =
    !_updatedReasons.reasons?.length ||
    (_updatedReasons.reasons?.includes("OTHER") && !_updatedReasons.otherReason?.trim());

  const SUB_HEADER_QUESTION =
    rating === "POSITIVE"
      ? msg("SCHOOL_FEEDBACK_REASONS_POSITIVE_SUB_HEADER")
      : msg("SCHOOL_FEEDBACK_REASONS_NEUTRAL_NEGATIVE_SUB_HEADER");

  return (
    <ModalSubProfileEdit>
      <ModalSubProfileEditHeader onDismiss={onDismiss} title={title} />
      <ModalSubProfileEditBody>
        <p style={{ fontWeight: 600 }}>{SUB_HEADER_QUESTION}</p>
        <p>{msg("LABEL_SELECT_ALL_THAT_APPLY")}</p>
        <div style={{ marginTop: 8, display: "flex", flexDirection: "column", gap: 8 }}>
          {selectedOptions.map((option, idx) => (
            <div
              key={`${option}-${idx}`}
              style={{
                borderBottom: option === "OTHER" ? "none" : "1px solid var(--swing-slate200)",
              }}
            >
              {/* TODO: See if we can replace this w/ <Checkbox/> in swing-components */}
              <CheckboxScore
                label={FeedbackSelections[option]}
                isChecked={_updatedReasons.reasons?.includes(option) || false}
                onChange={(isChecked) => handleOnChange(option, isChecked)}
                descriptionText={
                  option === "OTHER" && _updatedReasons.reasons?.includes("OTHER")
                    ? msg("SCHOOL_FEEDBACK_REASONS_OTHER_DESCRIPTION")
                    : undefined
                }
              />
              {option === "OTHER" && _updatedReasons.reasons?.includes("OTHER") && (
                // paddingLeft is a calculation of the padding around the checkbox
                <div style={{ paddingLeft: 58, paddingRight: 24 }}>
                  {/* TODO: See if we can replace this w/ <TextAreaInput/> in swing-components */}
                  <TextAreaInput
                    value={_updatedReasons.otherReason}
                    onChange={(reason) => handleOtherReason(reason)}
                    placeholder={
                      rating === "POSITIVE"
                        ? msg("SCHOOL_FEEDBACK_REASONS_POSITIVE_PLACEHOLDER")
                        : msg("SCHOOL_FEEDBACK_REASONS_NEGATIVE_PLACEHOLDER")
                    }
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </ModalSubProfileEditBody>
      <ModalSubProfileEditFooter
        buttonPrimary={{
          isDisabled: isNextDisabled,
          text: msg("LABEL_NEXT"),
          onClick: handleNext,
        }}
      />
    </ModalSubProfileEdit>
  );
}

export const FeedbackSelections: Record<FeedbackReasons, string> = {
  PARKING: msg("SCHOOL_FEEDBACK_REASONS_PARKING"),
  CHECK_IN: msg("SCHOOL_FEEDBACK_REASONS_CHECK_IN"),
  LESSON_PLAN: msg("SCHOOL_FEEDBACK_REASONS_LESSON_PLAN"),
  ROUTINES_PROCEDURE: msg("SCHOOL_FEEDBACK_REASONS_ROUTINES_PROCEDURE"),
  STAFF_SUPPORT: msg("SCHOOL_FEEDBACK_REASONS_STAFF_SUPPORT"),
  CLASSROOM_MANAGEMENT: msg("SCHOOL_FEEDBACK_REASONS_CLASSROOM_MANAGEMENT"),
  OTHER: msg("LABEL_OTHER"),
};

import { a as i, j as n, F as f } from "../../../emotion-react-jsx-runtime.browser.esm-CDWB6Ors.js";
import { Colors as l } from "../../foundations/Colors/Colors.js";
import { I as g } from "../../../Icon-C7c5yX_u.js";
import "../../../AuthProvider-DparLqAm.js";
import "@ionic/react";
import { useState as u } from "react";
import "../../../react-paginate-CpOsCkIq.js";
import "@capacitor/core";
function w(p) {
  const {
    title: a,
    chip: r,
    children: d,
    isOpen: t,
    onToggle: o
  } = p, [c, m] = u(t ?? !1), s = t !== void 0, e = s ? t : c, h = () => {
    s ? o == null || o() : m((x) => !x);
  };
  return /* @__PURE__ */ i(f, {
    children: [/* @__PURE__ */ n("div", {
      style: {
        padding: "16px 0",
        backgroundColor: l.white200,
        borderBottom: `1px solid ${l.slate300}`
      },
      children: /* @__PURE__ */ i("div", {
        style: {
          padding: "8px 16px",
          display: "flex",
          justifyContent: "space-between"
        },
        children: [/* @__PURE__ */ i("div", {
          style: {
            display: "flex",
            gap: 16,
            alignItems: "center"
          },
          children: [/* @__PURE__ */ n("span", {
            style: {
              fontWeight: 500,
              lineHeight: "24px",
              letterSpacing: "0.15px",
              fontSize: 20
            },
            children: a
          }), r && r]
        }), /* @__PURE__ */ n("button", {
          style: {
            background: "none"
          },
          onClick: h,
          "aria-expanded": e,
          "aria-label": e ? "Collapse section" : "Expand section",
          children: /* @__PURE__ */ n(g, {
            name: e ? "Chevron Down" : "Chevron Up"
          })
        })]
      })
    }), e && d]
  });
}
export {
  w as Accordion
};

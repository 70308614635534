import type { Task } from "~skillBuilder/utils/types";
import { image, link, tip, title } from "../../contentBlocks";

export const SECTerminology: Task = {
  name: "Unlocking Terminology",
  uniqueName: "sec-terminology",
  icon: "Lesson",
  durationTimeText: "10 min",
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      questions: [
        // Question 1.1
        {
          attribute: "special-education-classrooms.terminology/overview",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "Unlocking Terminology", durationTimeText: "10 min" })}
<p>Whether it is your first time in a special education class or your twentieth; these terms are an essential part of the daily conversation!</p>
<div align="center">
<div style="max-width:200px;width:100%;">
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1740759768/skillbuilderAssets/optimized/terminology.jpg", alt: "Illustration of three teachers sitting on top of purple books." })}
</div>
`,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "special-education-classrooms.terminology/before",
          label: "Know before you go!",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
The descriptions supporting special education classrooms, 1:1 para/paraprofessional, dedicated aide, or instructional aide are used interchangeably by school partners. Additionally, roving requests can also include special education classrooms.
            ${tip({ text: "Reach out to the admin on the request before accepting to learn more about what a request might entail." })}
            ${link({ text: "More tips", url: "https://swingeducation.com/sub-success-resource-center/tips-for-substitute-teaching-in-special-education-classes/" })}
                `,
          },
        },
      ],
    },
    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "special-education-classrooms.terminology/terms1",
          label: "What does that mean again?",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
**IEP**: Individualized Education Plan: Legally binding document that outlines a students’ educational plan. This includes their services, goals, and accommodations.

**BIP**: Behavior Intervention Plan: A plan in place to support, improve, and change challenging behaviors that interfere with a student’s learning or social interactions. You can also see these plans under the name: Behavior Management Plan (BMP) or Behavior Management Invention (BMI).

**LRE**: Least Restrictive Environment: This gives students with disabilities an inclusive learning experience in their education setting.
`,
          },
        },
      ],
    },
    // Step 4
    {
      questions: [
        // Question 4.1
        {
          attribute: "special-education-classrooms.terminology/terms2",
          label: "More terms to know",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
**FAPE**: Free Appropriate Public Education: The right of students with disabilities to receive an education at no cost.

**IDEA**: Individuals with Disabilities Education Act: The law that ensures students with disabilities receive a free appropriate public education (FAPE) in the least restrictive environment (LRE).

**IEP Team**: Members of the school that interact with the student, made up of the student (at a certain age), the parents or guardians, a special education teacher, a general education teacher, a school admin, and any additional service providers for the student.

**Accommodations**: Changes or adjustments made for the student to allow them to effectively thrive in the classroom.
`,
          },
        },
      ],
    },
    // Step 5
    {
      nextButtonText: "Next Lesson",
      questions: [
        // Question 5.1
        {
          attribute: "special-education-classrooms.terminology/toolkit",
          label: "Add these terms to your teacher toolkit",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Taking the time to understand and know these terms will lay the foundation for creating an inclusive, supportive, and legally compliant learning environment where the students you support will flourish.
`,
          },
        },
      ],
    },
  ],
};

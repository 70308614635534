import { j as e, a as r, F as a } from "../../../emotion-react-jsx-runtime.browser.esm-CDWB6Ors.js";
import f from "../../string-table.js";
import { Colors as t } from "../../foundations/Colors/Colors.js";
import { I as p } from "../../../Icon-C7c5yX_u.js";
import { Asterisk as c } from "../../atoms/Asterisk/Asterisk.js";
import "../../../AuthProvider-DparLqAm.js";
import "@ionic/react";
import "react";
import "../../../react-paginate-CpOsCkIq.js";
import "@capacitor/core";
function L(m) {
  const {
    id: n,
    label: h,
    value: o,
    stacked: b,
    placeholder: u,
    onClick: i,
    isDisabled: l = !1,
    isRequired: s = !1
  } = m, d = () => /* @__PURE__ */ e("span", {
    id: `${n}-dropdown`,
    style: {
      fontWeight: "var(--swing-font-semibold"
    },
    children: h
  });
  return /* @__PURE__ */ e("div", {
    id: n,
    onClick: i,
    style: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      padding: "16px 0",
      borderBottom: `1px solid ${t.slate200}`,
      cursor: "pointer"
    },
    children: b && o ? /* @__PURE__ */ r(a, {
      children: [/* @__PURE__ */ r("div", {
        children: [/* @__PURE__ */ r("div", {
          children: [/* @__PURE__ */ e(d, {}), s && /* @__PURE__ */ e(c, {})]
        }), /* @__PURE__ */ e("div", {
          children: o
        })]
      }), /* @__PURE__ */ e("button", {
        type: "button",
        disabled: l,
        style: {
          backgroundColor: "transparent"
        },
        children: /* @__PURE__ */ e(p, {
          name: "Chevron Down",
          color: t.blue500
        })
      })]
    }) : /* @__PURE__ */ r(a, {
      children: [/* @__PURE__ */ r("div", {
        children: [/* @__PURE__ */ e(d, {}), s && /* @__PURE__ */ e(c, {})]
      }), /* @__PURE__ */ r("div", {
        style: {
          display: "flex",
          gap: "4px",
          alignItems: "center"
        },
        children: [/* @__PURE__ */ e("span", {
          children: o || u || f("LABEL_SELECT")
        }), /* @__PURE__ */ e("button", {
          type: "button",
          disabled: l,
          onClick: i,
          style: {
            backgroundColor: "transparent"
          },
          children: /* @__PURE__ */ e(p, {
            name: "Chevron Down",
            color: t.blue500
          })
        })]
      })]
    })
  });
}
export {
  L as DropDown
};

import type { Task } from "~skillBuilder/utils/types";
import { image, tip, title } from "../../contentBlocks";

export const LWCPositiveEnvironment: Task = {
  name: "Positive Environment",
  uniqueName: "lwc-positive-environment",
  icon: "Lesson",
  durationTimeText: "10 min",
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      questions: [
        // Question 1.1
        {
          attribute: "lwc.positive-enviro/intro",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "Positive Environment", durationTimeText: "10 min" })}
<p>Learn how to create positive classroom environment to benefit both you and students.</p>
<div align="center">
<div style="max-width:250px;width:100%;">
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1734090536/skillbuilderAssets/optimized/positive-enviro_intro.jpg", alt: "A teacher standing with three students holding books." })}
</div>
</div>
            `,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "lwc.positive-enviro/importance",
          label: "The importance of creating a positive environment",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>It is important for a substitute teacher to establish a positive classroom presence because it helps create a conducive learning environment, promotes respect, and sets clear expectations for behavior. A positive presence encourages students to be more engaged, cooperative, and respectful, making classroom management smoother. When students sense confidence, kindness, and professionalism, they are more likely to follow instructions and be receptive to your authority.<br><br>
Additionally, a positive classroom presence helps build rapport with students, fostering an atmosphere of trust and mutual respect, which can enhance the overall effectiveness of the lesson and prevent disruptions.</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "lwc.positive-enviro/expectations",
          label: "Set expectations",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>As a substitute teacher, setting expectations for the class is crucial for maintaining order, ensuring a productive learning environment, and promoting respect. Clearly defined expectations help students understand what is acceptable behavior, what they need to do, and what consequences to expect for misbehavior. This clarity fosters a sense of security and structure, which is especially important when students may be more inclined to test boundaries in the absence of their regular teacher.<br><br>
By establishing expectations early on, you set the tone for the day, prevent disruptions, and encourage students to take responsibility for their actions. It also allows you to manage the class effectively, ensuring a positive and focused learning atmosphere for everyone.</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 4
    {
      questions: [
        // Question 4.1
        {
          attribute: "lwc.positive-enviro/divider-activities",
          answerPlacement: "TOP",
          label:
            "Let’s review some creative ways to introduce yourself, break the ice, and build rapport",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div align="center">
<div style="max-width:300px;width:100%;">
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1734091593/skillbuilderAssets/optimized/lwc.positive_enviro_divider-activities.jpg", alt: "A teacher standing with six children." })}
</div>
</div>
            `,
          },
        },
      ],
    },
    // Step 5
    {
      questions: [
        // Question 5.1
        {
          attribute: "lwc.positive-enviro/activity-1",
          label: "1. Personal About Me Slide or Poster",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Create a short, visually engaging slide or poster about yourself with fun facts, hobbies, and interests. This can help students feel more comfortable and give them an opportunity to ask questions about you.<br><br>
<strong>2. Two Truths and a Lie</strong><br><br>Play a quick round of “Two Truths and a Lie,” where you share two true facts about yourself and one false one, and the students guess which is the lie. It’s a fun, interactive way for students to learn about you.</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 6
    {
      questions: [
        // Question 6.1
        {
          attribute: "lwc.positive-enviro/activity-2",
          label: "3. Find Someone Who... Icebreaker",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Give students a list of characteristics (e.g., "find someone who loves pizza," "find someone who has a pet dog"), and ask them to find classmates who match the descriptions. This helps students mingle and can create a sense of community in the classroom.<br><br>
<strong>4. Fun Name Game</strong><br><br>If students don’t know each other well, you can do a name game where everyone introduces themselves with an adjective that starts with the same letter as their first name (e.g., "Jolly Jane" or "Creative Chris"). It’s a light-hearted way to remember names.</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 7
    {
      questions: [
        // Question 7.1
        {
          attribute: "lwc.positive-enviro/activity-3",
          label: "5. Storytelling",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Share a short, relatable story about your experiences as a teacher or something funny that has happened to you in the classroom. This can humanize you and make students feel more connected.<br><br>
<strong>6. Interactive Activity</strong><br><br>Have students draw something about themselves, like their favorite hobby or dream vacation, and then share it with the class. This can spark conversation and help you learn more about your students.</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 8
    {
      questions: [
        // Question 8.1
        {
          attribute: "lwc.positive-enviro/activity-4",
          label: "7. Welcome Notes or Desk Cards",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Leave a small personal welcome note or card at each desk, introducing yourself in a few sentences and offering a warm greeting. This adds a personal touch and can make students feel more comfortable.<br><br>
<strong>8. Classroom Polls</strong><br><br>Create a simple poll with questions like "What’s your favorite subject?" or "Do you prefer pizza or burgers?" Students can participate by raising hands or writing answers on the board. This can be a fun way to engage them and learn a bit about their preferences.</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 9
    {
      questions: [
        // Question 9.1
        {
          attribute: "lwc.positive-enviro/choose-activity",
          label: "How to choose an activity",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Introduction activities can help you connect with students quickly, make the classroom environment feel more welcoming, and set a positive tone for the day. However, it’s important to keep these things in mind.<br></p>
        The activity you choose should be:
      <ul style="display: flex; flex-direction: column; gap: 4px; margin: 0 0 0 16px; padding: 0 0 0 16px;">
        <li>Age-appropriate</li>
        <li>Help you learn student’s names</li>
        <li>Flexible. You may need to adjust the activity depending on the time permitted.</li>
        <li>Appropriate and professional. If you are uncertain whether the activity is appropriate for the class, ask a school administrator or other teacher.</li>
      </ul></div>
            `,
          },
        },
      ],
    },
    // Step 10
    {
      questions: [
        // Question 10.1
        {
          attribute: "lwc.positive-enviro/divider-engaged",
          answerPlacement: "TOP",
          label: "Next, let’s learn why being engaged is your superpower",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div align="center">
<div style="max-width:300px;width:100%;">
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1734092364/skillbuilderAssets/optimized/positive-enviro_divider-engaged.jpg", alt: "A male teacher and four students doing a science experiment in a science classroom." })}
</div>
</div>
            `,
          },
        },
      ],
    },
    // Step 11
    {
      questions: [
        // Question 11.1
        {
          attribute: "lwc.positive-enviro/why-engage",
          label: "Engaging with the class",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>If you are physically able, moving around the classroom can be a helpful way to engage students. If you can’t physically move around, scanning the classroom and showing that you are monitoring the whole space is also effective.<br><br>
Always make sure that you respect students' personal space, and remain appropriate and professional.</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 12
    {
      questions: [
        // Question 12.1
        {
          attribute: "lwc.positive-enviro/engage-1",
          label: "Encourages student focus",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>By moving around the room or scanning, you maintain students' attention and keep them on task. Your presence and attention can deter distractions and ensure they stay engaged with the lesson.<br><br>
<strong>Builds authority and respect</strong><br><br>It’s important to show that you are engaged and paying attention to what’s happening in the class. It can lead to better behavior and compliance with expectations as students are less likely to engage in off-task activities when they know you're paying attention.</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 13
    {
      questions: [
        // Question 13.1
        {
          attribute: "lwc.positive-enviro/engage-2",
          label: "Provides support and assistance",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Circulating, or scanning helps you identify students who need individualized support. It creates opportunities to give timely feedback, answer questions, and assist students who may be struggling.</p>
          ${tip({ text: "You can let students know you’ll be checking for a specific answer or action. For example, “I’ll be coming around in 2 mins to check number 3.” Then with a marker or pen, give each student a quick check on their paper. Or “I’ll be looking to see when your book is opened to page 23.”" })}
</div>
            `,
          },
        },
      ],
    },
    // Step 14
    {
      questions: [
        // Question 14.1
        {
          attribute: "lwc.positive-enviro/engage-3",
          label: "Promotes a positive learning environment",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Being engaged shows students that you are invested in their learning and well-being. It helps foster a positive, welcoming atmosphere where students feel valued and supported.<br><br>
<strong>Prevents behavioral issues</strong><br><br>By being physically present, or aware of different areas in the classroom, you reduce opportunities for students to engage in disruptive behavior. Active supervision can help manage small issues before they escalate.</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 15
    {
      nextButtonText: "Next Lesson",
      questions: [
        // Question 15.1
        {
          attribute: "lwc.positive-enviro/engage-4",
          label: "Builds rapport",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Interacting with students allows you to build connections and create a more comfortable environment, making students feel more at ease and willing to participate.</p>
          ${tip({ text: "Check in with students who seem shy. They may have questions, but aren’t comfortable asking in front of the whole class." })}
</div>
            `,
          },
        },
      ],
    },
  ],
};

import type { Task } from "~skillBuilder/utils/types";

export const SECOverview: Task = {
  name: "Overview",
  uniqueName: "sec-overview",
  icon: "Overview",
  durationTimeText: "1 min",
  isIntro: true,
  steps: [
    // Step 1
    {
      nextButtonText: "Start Lesson",
      questions: [
        // Question 1.1
        {
          attribute: "special-education-classrooms/overview",
          label: "Thriving in Special Education settings",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Teaching in a special education setting is one of the most rewarding and impactful ways you can make a difference as a substitute teacher. You’re stepping into a role where connection, patience, and adaptability are your most important tools—not to mention your creativity and quick problem solving, which will definitely get a good workout, too.

When you walk into a special education classroom, you’re there to support students who learn and communicate in unique ways. Whether you’re in a self-contained classroom, a resource room, or providing support in a general education setting, your goal is to meet students where they are and help them take their next steps. Let’s dive into what that can look like with some school partner tips.</p>
</div>
`,
          },
        },
      ],
    },
  ],
};

import type { Task } from "~skillBuilder/utils/types";
import { image, title } from "../../contentBlocks";

export const RSkillCheck: Task = {
  name: "Skill Check",
  uniqueName: "r-skill-check",
  icon: "BookmarkCheck",
  durationTimeText: "5 min",
  isQuiz: true,
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      prevButtonText: "Review",
      showPreviousButton: true,
      questions: [
        // Question 1.1
        {
          attribute: "reliability.skill-check/intro",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "Skill Check", durationTimeText: "5 min" })}
<p>Show what you've learned! Complete this quiz to earn a qualification for your profile.</p>
<div style="text-align:center;max-width:250px;width:100%;margin-top:24px;margin-left:auto;margin-right:auto;"/>
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/v1731113489/skillbuilderAssets/optimized/woman-and-clipboard.png", alt: "illustrated woman standing in front of a checked clipboard" })}
</div>            
`,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "reliability.skill-check/sick",
          label: "If you start to feel sick, when is the best time to cancel an upcoming request?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label: "You don’t need to cancel requests if you can’t make them",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label: "You can wait until the morning of the request to cancel",
                value: "invalid-2",
                isCorrect: false,
              },
              {
                label:
                  "You should cancel as soon as you know you won’t be able to make your request",
                value: "valid",
                isCorrect: true,
              },
            ],
          },
        },
      ],
    },
    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "reliability.skill-check/on-time",
          label: "What is the best way to ensure you show up to your teaching request on time?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label: "Plan your route to school ahead of time",
                value: "valid",
                isCorrect: true,
              },
              {
                label: "Plan to show up to school right when the bell rings",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label: "Cancel 2 hours before the start of the school day",
                value: "invalid-2",
                isCorrect: false,
              },
            ],
          },
        },
      ],
    },
    // Step 4
    {
      questions: [
        // Question 4.1
        {
          attribute: "reliability.skill-check/late",
          label: "If you are running late, what should you do?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label: "There’s no need to contact the school if you are running late",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label:
                  "Contact the school to let them know and provide an estimated time of arrival",
                value: "valid",
                isCorrect: true,
              },
              {
                label: "Go home- it’s better to not show up than show up late",
                value: "invalid-2",
                isCorrect: false,
              },
            ],
          },
        },
      ],
    },
    // Step 5
    {
      questions: [
        // Question 5.1
        {
          attribute: "reliability.skill-check/contact-swing",
          label: "How can you get in contact with Swing?",
          answerType: {
            type: "RADIO",
            options: [
              {
                label: "Email",
                value: "invalid-1",
                isCorrect: false,
              },
              {
                label: "Call or text",
                value: "invalid-2",
                isCorrect: false,
              },
              {
                label: "Live Support Chat",
                value: "invalid-3",
                isCorrect: false,
              },
              {
                label: "All of the above",
                value: "valid",
                isCorrect: true,
              },
            ],
          },
        },
      ],
    },
    // Step 6
    {
      nextButtonText: "Great!",
      questions: [
        // Question 6.1
        {
          attribute: "reliability.skill-check/outro",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div style="text-align: center;">
  ${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1731608485/skillbuilderAssets/optimized/skill_builder_complete.jpg", alt: "illustrated man holding trophy standing next to a woman" })}
  <p><strong>You've completed this skill!</strong></p>
  <p>Thank you for your commitment to developing your teaching skills. You'll now see <strong>Reliability</strong> as a qualification on your profile.</p>
</div>
`,
          },
        },
      ],
    },
  ],
};

import type { Task } from "~skillBuilder/utils/types";

export const CmOverview: Task = {
  name: "Overview",
  uniqueName: "cm-overview",
  icon: "Overview",
  durationTimeText: "1 min",
  isIntro: true,
  steps: [
    // Step 1
    {
      nextButtonText: "Start Lesson",
      questions: [
        // Question 1.1
        {
          attribute: "classroom-management/overview",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>
    Strong classroom management skills are invaluable as a substitute teacher. The following materials will help you encourage a positive classroom atmosphere, reduce and de-escalate disruptions, and ensure you have a smooth and successful day.
  </p>
  <p style="margin: 0;">
    <strong>The importance of classroom management</strong></p>
  <p style="margin: 0;">
    We know it isn't easy coming into a new classroom, but solid classroom management will lead to a better experience for both you and students.
    <ul style="margin: 0;">
      <li>Students crave consistency, so it can be a big disruptor when their teacher is out</li>
      <li>It's important to try to continue the teacher's lessons and plans to ensure students experience as little learning disruption as possible</li>
      <li>Schools appreciate when a substitute can manage a classroom and carry out the teacher's lesson plans or tasks</li>
    </ul>
  </p>
</div>
`,
          },
        },
      ],
    },
  ],
};

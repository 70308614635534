import type { Task } from "~skillBuilder/utils/types";
import { image, tip, title } from "../../contentBlocks";

export const LWCBeProactive: Task = {
  name: "Be Proactive",
  uniqueName: "lwc-be-proactive",
  icon: "Lesson",
  durationTimeText: "5 min",
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      questions: [
        // Question 1.1
        {
          attribute: "lwc.be-proactive/intro",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "Be Proactive", durationTimeText: "5 min" })}
<p>Learn the importance of being proactive, and review some recommended questions to ask.</p>
<div align="center">
<div style="max-width:250px; width:100%;">
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1734022735/skillbuilderAssets/optimized/lwc.be-proactive_intro.jpg", alt: "A teacher standing in front of a chalkboard with A+B=C written on it." })}
</div>
</div>
            `,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "lwc.be-proactive/why",
          label: "Why does being proactive matter?",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Being proactive and asking clarifying questions about the role shows that you’re experienced and prepared! It will lead to a smoother day.</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "lwc.be-proactive/divider-questions",
          answerPlacement: "TOP",
          label:
            "Let’s review some recommended questions to ask admins or faculty if you can’t find the information in provided resources",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div align="center">
<div style="max-width:250px;width:100%;">
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1734023622/skillbuilderAssets/optimized/lwc.be-proactive_divider-questions.jpg", alt: "A woman holding a clipboard with a questioning look on her face." })}
</div>
</div>
            `,
          },
        },
      ],
    },
    // Step 4
    {
      questions: [
        // Question 4.1
        {
          attribute: "lwc.be-proactive/support",
          label: "What is the best way to reach out if support is needed?",
          //description:"",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>As a substitute teacher, knowing the best way to contact administrators for support is essential because it ensures a smooth, safe, and effective classroom experience. Administrators can assist with handling unexpected situations, student behavior issues, and questions about school procedures.<br><br>
Quick access to support helps you maintain a calm and controlled learning environment, reinforces your authority, and allows you to address any urgent concerns promptly. Being prepared with contact information also shows that you’re professional and proactive, which strengthens your rapport with both the students and the school staff.</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 5
    {
      questions: [
        // Question 5.1
        {
          attribute: "lwc.be-proactive/schedule",
          label: "Are there any changes or additions to the daily schedule?",
          //description:"",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Knowing about any changes to the normal daily schedule is crucial for keeping the day organized and running smoothly. <br><br>
Schedule changes—such as assemblies, early dismissals, or special events—can impact lesson timing, transitions, and classroom expectations. Being aware of these adjustments allows you to manage time effectively, avoid confusion, and ensure students are prepared for any shifts in routine.<br><br>
This knowledge also shows students that you are informed and in control, helping you maintain authority and create a positive learning environment.</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 6
    {
      questions: [
        // Question 6.1
        {
          attribute: "lwc.be-proactive/policies",
          label: "What are the school policies for behavior issues, cell phone use, etc?",
          //description:"",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Understanding the school’s policies on behavior, cell phones, and other conduct expectations is essential for maintaining consistency, authority, and a positive learning environment. By enforcing established guidelines, you provide students with a sense of stability and fairness, helping them stay focused and engaged.<br><br>
Understanding these policies also empowers you to address behavior issues confidently and appropriately, ensuring your responses align with the school’s standards. This not only supports classroom management but also demonstrates professionalism and respect for the school’s established norms.
        ${tip({ text: "You can kindly let students know that you are aware of the school policies and procedures. This lets students know you’ll be holding them to the same high expectations." })}
</div>
            `,
          },
        },
      ],
    },
    // Step 7
    {
      questions: [
        // Question 7.1
        {
          attribute: "lwc.be-proactive/accomodations",
          label: "Are there any specific student accommodations in the class?",
          //description:"",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Knowing specific student accommodations is crucial for ensuring all students have equitable access to learning and feel supported.<br></p>
        Some common accommodations:
      <ul style="display: flex; flex-direction: column; gap: 4px; margin: 0 0 0 16px; padding: 0 0 0 16px;">
        <li>Seating arrangements</li>
        <li>Modified assignments</li>
        <li>Additional time for tasks</li>
      </ul>
 <p>These accommodations are designed to meet individual students’ needs, often based on learning disabilities, medical conditions, or other challenges.<br><br>
Being aware of these accommodations helps you provide appropriate support, foster an inclusive environment, and avoid unintentional obstacles to students' success. Understanding these needs also reflects positively on your professionalism and ability to adapt, creating a safe and respectful space for every student in your care.</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 8
    {
      questions: [
        // Question 8.1
        {
          attribute: "lwc.be-proactive/emergency",
          label: "What are the procedures in case of an emergency?",
          //description:"",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Knowing what to do in an emergency is vital for ensuring the safety and well-being of students and yourself in case of an unexpected situation. <br></p>
        Some emergency situations you should be prepared for are:
      <ul style="display: flex; flex-direction: column; gap: 4px; margin: 0 0 0 16px; padding: 0 0 0 16px;">
        <li>Fire drills</li>
        <li>Evacuation</li>
        <li>Lockdowns</li>
        <li>Medical incidents</li>
        <li>Severe weather protocols</li>   
      </ul>
 <p>These emergencies require quick and effective responses. Familiarity with the school’s emergency protocols enables you to act swiftly and confidently, guiding students to safety and minimizing confusion or panic. This knowledge also establishes trust and authority, as students look to you for clear direction in times of crisis.<br><br>
Being prepared for emergencies underscores your responsibility and professionalism in providing a secure environment for everyone in the classroom.</p>
</div>
            `,
          },
        },
      ],
    },
    // Step 9
    {
      nextButtonText: "Next Lesson",
      questions: [
        // Question 9.1
        {
          attribute: "lwc.be-proactive/tasks",
          label: "Are there any other tasks or duties this teacher supports?",
          //description:"",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Knowing about any additional tasks or duties the teacher you are subbing for supports is important for maintaining the continuity of the classroom environment and ensuring that all responsibilities are covered.<br></p>
        These tasks might include:
      <ul style="display: flex; flex-direction: column; gap: 4px; margin: 0 0 0 16px; padding: 0 0 0 16px;">
        <li>Taking attendance</li>
        <li>Monitoring specific students' needs</li>
        <li>Monitoring specific zones at dismissal</li>
      </ul>
 <p>Being aware of these duties helps you stay organized and prepared, ensuring that nothing is overlooked. It also shows respect for the teacher’s role and responsibilities, which can help build trust with both students and the regular teacher. Completing these tasks as expected contributes to a smoother day and a positive experience for everyone.</p>
</div>
            `,
          },
        },
      ],
    },
  ],
};

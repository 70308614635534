import type { Task } from "~skillBuilder/utils/types";
import { image, tip, title } from "../../contentBlocks";

export const RChallenges: Task = {
  name: "Challenges",
  uniqueName: "r-challenges",
  icon: "Lesson",
  durationTimeText: "2 min",
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      questions: [
        // Question 1.1
        {
          attribute: "reliability.challenges/intro",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
${title({ text: "Challenges", durationTimeText: "2 min" })}
<p>Review common challenges that arise and learn what to do in those situations.</p>
<div style="text-align:center;max-width:250px;margin-top:24px;margin-left:auto;margin-right:auto;"/>
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1732046618/skillbuilderAssets/optimized/challenges-intro.jpg", alt: "Woman looking a little confused, holding a piece of paper and a pencil." })}
</div>
</div>
`,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "reliability.challenges/key",
          label: "Communication is key",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>We know that emergencies happen and sometimes there are situations beyond our control. In these situations, it is important to remember that communication is key for all; subs, schools, and Swing.</p>
</div>
`,
          },
        },
      ],
    },
    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "reliability.challenges/sick",
          label: "What do I do when I’m sick?",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Cancel as soon as you know you won’t be able to make it to the request.</p>
  <ul style="display: flex; flex-direction: column; gap: 24px; margin: 0 0 0 16px; padding: 0;">
      ${tip({ text: "Remember to cancel at least 24 hours before the start of the request to avoid a penalty." })}
  </ul>
</div>
`,
          },
        },
      ],
    },
    // Step 4
    {
      questions: [
        // Question 4.1
        {
          attribute: "reliability.challenges/traffic-parking",
          label: "I hit traffic or I am having a hard time finding parking",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Contact the school and let them know you are running late. Provide them with an estimated time of arrival.</p>
  <ul style="display: flex; flex-direction: column; gap: 24px; margin: 0 0 0 16px; padding: 0;">
      ${tip({ text: "When planning for your day, give yourself plenty of time to get there, especially when it’s your first time to a school." })}
       ${tip({ text: "Review the school profile for information regarding parking." })}
  </ul>
</div>
`,
          },
        },
      ],
    },
    // Step 5
    {
      nextButtonText: "Next Lesson",
      questions: [
        // Question 5.1
        {
          attribute: "reliability.challenges/leave-early",
          label: "What if I have an emergency and need to leave a request early?",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
<div>
  <p>Immediately let the admin on the request or the front office know, so they can find coverage for the class. You can use classroom call procedures specific to each school. For safety reasons, <strong>never leave students unattended.</strong></p>
</div>
`,
          },
        },
      ],
    },
  ],
};

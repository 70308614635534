import type { Task } from "~skillBuilder/utils/types";
import { doDont, image, tip, title } from "../../contentBlocks";

export const SECCommunication: Task = {
  name: "Communication is Key",
  uniqueName: "sec-communication",
  icon: "Lesson",
  durationTimeText: "5 min",
  steps: [
    // Step 1
    {
      nextButtonText: "Start",
      questions: [
        // Question 1.1
        {
          attribute: "special-education-classrooms.communication/overview",
          label: "",
          // description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
${title({ text: "Communication is Key", durationTimeText: "5 min" })}
<p>Relationship building is more than just a buzz word. It’s the foundation for a successful day in class! Building rapport starts with introducing yourself warmly, having a supportive presence, and integrating understanding and patience into your practice.</p>
<div align="center">
<div style="max-width:300px;width:100%;">
${image({ url: "https://res.cloudinary.com/swingeducation/image/upload/fl_preserve_transparency/v1740759767/skillbuilderAssets/optimized/communication.jpg", alt: "Illustration four students sitting at desks working and smiling." })}
</div>
`,
          },
        },
      ],
    },
    // Step 2
    {
      questions: [
        // Question 2.1
        {
          attribute: "special-education-classrooms.communication/observe",
          label: "Observe and connect",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
In a special education setting, students may communicate in various ways—verbally, non-verbally, through technology, or with assistive devices.

Recognizing and respecting their preferred communication method can make a big difference—not just in their learning experience, but in how smoothly your day goes too!
${tip({ text: "Take a few moments at the start of your day to observe how students express themselves." })}
                `,
          },
        },
      ],
    },
    // Step 3
    {
      questions: [
        // Question 3.1
        {
          attribute: "special-education-classrooms.communication/patience",
          label: "Bring your patience and positivity",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
Each student has their own strengths, challenges, and learning pace. You may need to repeat instructions, rephrase explanations, or try different approaches to help a student grasp a concept. The effort you put in will not only support their learning but also strengthen your teaching skills!

Stay patient—it’s worth it. And never underestimate the power of positivity! A simple smile and a “You’re doing great!” can go a long way in building trust and confidence.
${tip({ text: "Offer both written and verbal instructions to improve understanding." })}
`,
          },
        },
      ],
    },
    // Step 4
    {
      nextButtonText: "Next Lesson",
      questions: [
        // Question 4.1
        {
          attribute: "special-education-classrooms.communication/do-dont",
          label: "Know your audience",
          //description: "",
          answerType: {
            type: "CONTENT",
            value: "valid",
            markdown: `
  ${doDont({ doText: "Do communicate at the student’s age and developmental level unless otherwise directed.", dontText: "Don’t use demeaning language or baby talk." })}

  Creating a respectful, inclusive learning environment helps students feel valued and capable. Using demeaning language can unintentionally reinforce stereotypes and weaken a student’s confidence and engagement.
`,
          },
        },
      ],
    },
  ],
};
